import { CHECKOUT } from "constants/routes";
import { checkAccess } from "helpers/auth";
import CheckoutPage from "./container/CheckoutPage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: CHECKOUT,
    withSidebar: true,
    withAppBar: true,
    component: CheckoutPage,
    public: true,
  };
}
