import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import { ENROLLMENT, MARKET } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EnrollmentCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    enrollment: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes, enrollment, history } = this.props;

    const { expirationDate } = enrollment;

    let expired = false;

    if (expirationDate && moment().diff(moment(expirationDate), "days") >= 0) {
      expired = true;
    }

    return (
      <Paper className={classes.container}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography display="block" variant="body2">
              {enrollment.courseName}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {enrollment.pricingPlanName}
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.circularContainer}>
              <CircularProgress
                className={classes.back}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.fore}
                variant="static"
                value={enrollment.percentage}
              />
              <Typography
                display="block"
                color="primary"
                className={classes.value}
              >
                {enrollment.percentage}
                <span style={{ fontSize: 10 }}>%</span>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                backgroundImage: `url(${enrollment.courseBackground})`,
                height: 150,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                marginLeft: -10,
                width: "calc(100% + 20px)",
              }}
            />
          </Grid>
          <Grid item style={{ paddingTop: 10 }}>
            {!expired ? (
              <Grid container>
                {enrollment.percentage !== 0 ? (
                  <Grid item xs={12}>
                    <Typography display="block" variant="body2">
                      {enrollment.moduleName}
                    </Typography>
                    <Divider />
                    <Typography display="block" variant="body2">
                      {enrollment.lectureName}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography display="block" color="textSecondary">
                      You have not started the course yet.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              []
            )}
          </Grid>
          <Grid item style={{ paddingTop: 10 }}>
            <Grid container justify="space-between">
              {!expired && expirationDate ? (
                <Grid item>
                  <Chip label={`Expires ${moment(expirationDate).fromNow()}`} />
                </Grid>
              ) : (
                []
              )}
              {!expired ? (
                <Grid item>
                  <Chip
                    label={
                      enrollment.percentage === 0
                        ? "Start Course"
                        : "Continue Course"
                    }
                    color="primary"
                    onClick={() => {
                      history.push(`${ENROLLMENT}?id=${enrollment.id}`);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Typography display="block">
                    ⚠️ Your Access to the course has expired.
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    Expiration Date: {`${moment(expirationDate).format("lll")}`}
                  </Typography>
                  <br />
                  <Chip
                    label={"Renew Course"}
                    color="primary"
                    style={{ background: "#2196f3" }}
                    onClick={() => {
                      history.push(`${MARKET}?id=1&index=1`);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(EnrollmentCard);
