import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

const service = "bazaar";
const goods = `/${service}/goods`;

export function getGoods() {
  const url = `${goods}`;
  return {
    method: GET,
    url,
  };
}

export function fetchGood(resourceURL) {
  return {
    method: GET,
    url: resourceURL,
  };
}

export function getGoodById(id) {
  const url = `${goods}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getGoodMedia(id) {
  const url = `${goods}/${id}/media`;
  return {
    method: GET,
    url,
  };
}

export function uploadGoodMedium(id, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${goods}/${id}/media`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getGoodSales(id) {
  const url = `${goods}/${id}/sales`;
  return {
    method: GET,
    url,
  };
}

export function updateGood(id, body) {
  const url = `${goods}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGood(id) {
  const url = `${goods}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGood(body) {
  const url = `${goods}`;
  return {
    method: POST,
    url,
    body,
  };
}
