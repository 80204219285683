import { networkAction } from "helpers/network/networkAction";

import {
  GET_PRICING_PLANS,
  GET_PRICING_PLAN_BY_ID,
  DELETE_PRICING_PLAN,
  UPDATE_PRICING_PLAN,
  CREATE_PRICING_PLAN,
} from "constants/pricingPlans";

import * as Api from "api";

export const getPricingPlans = () => async (dispatch) =>
  networkAction(dispatch, GET_PRICING_PLANS, Api.getPricingPlans, []);

export const getPricingPlanById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PRICING_PLAN_BY_ID, Api.getPricingPlanById, [id]);

export const deletePricingPlan = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PRICING_PLAN, Api.deletePricingPlan, [id]);

export const createPricingPlan = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PRICING_PLAN, Api.createPricingPlan, [body]);

export const updatePricingPlan = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PRICING_PLAN, Api.updatePricingPlan, [
    id,
    body,
  ]);
