// material-ui
import { withStyles } from "@material-ui/styles";
// config
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Link extends Component {
  static propTypes = {
    classes: PropTypes.object,
    href: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { classes, href, children } = this.props;

    // hook for /public/*
    const service = "interdoc";
    const UPLOAD_REGEXP = /\/uploads\/(.+)*/;
    let realHref = href;
    if (UPLOAD_REGEXP.test(href)) {
      realHref = `${REACT_APP_API}${REACT_APP_API_PREFIX}/${service}${realHref}`;
    }

    return (
      <a
        href={realHref}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {children}
      </a>
    );
  }
}

export default withStyles(styles)(Link);
