import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

// material-ui
import { withStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";

import Day from "./Day";

// styles
import styles from "./styles";

const overflow = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

class Calendar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getDailyAvailabilities: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const tzName = "America/Los_Angeles";
    this.state = {
      now: moment.tz(tzName),
      monthCursor: moment.tz(tzName),
    };
  }

  nextMonth() {
    this.setState((prevState) => ({
      monthCursor: prevState.monthCursor.add(1, "month"),
    }));
  }

  prevMonth() {
    this.setState((prevState) => ({
      monthCursor: prevState.monthCursor.subtract(1, "month"),
    }));
  }

  render() {
    const {
      classes,
      isAvailable,
      onSelect,
      selected,
      getDailyAvailabilities,
    } = this.props;
    const { monthCursor, now } = this.state;

    const BEGIN_MONTH_BLANK = [];
    const MONTH_DAYS = [];

    const daysInMonth = monthCursor.daysInMonth();
    const firstDayOfMonth = monthCursor.clone().startOf("month");
    let dayCursor = firstDayOfMonth;

    for (
      let index = 0;
      index < overflow[firstDayOfMonth.format("dddd")];
      index++
    ) {
      BEGIN_MONTH_BLANK.push(
        <div className={classes.flex}>
          <div className={classes.box} />
        </div>
      );
    }

    for (let index = 0; index < daysInMonth; index++) {
      const a = dayCursor.clone();
      // console.log(`${dayCursor.format('DD/MM')} isBefore ${now.format('DD/MM')} = ${dayCursor.isBefore(now)}`);
      if (dayCursor.format("dddd") === "Sunday") {
        MONTH_DAYS.push(<div style={{ flexBasis: "100%" }} />);
      }
      MONTH_DAYS.push(
        <Day
          key={dayCursor.format("lll")}
          onClick={() => onSelect(a)}
          selected={selected && selected.isSame(dayCursor, "day")}
          getDailyAvailabilities={getDailyAvailabilities}
          dayCursor={dayCursor}
        />
      );
      dayCursor = dayCursor.clone().add(1, "day");
    }

    return (
      <div style={{ width: "calc(100% - 20px)", position: "relative" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            color: "#ff9800",
          }}
        >
          <div className={classes.flex} style={{ flex: 1 }}>
            <Typography display="block" variant="title">
              {monthCursor.format("MMMM YYYY")}
            </Typography>
          </div>
          <div>
            <IconButton
              color="inherit"
              disabled={monthCursor.format("MM/YYYY") === now.format("MM/YYYY")}
              onClick={this.prevMonth.bind(this)}
            >
              <NavigateBefore />
            </IconButton>
            <IconButton color="inherit" onClick={this.nextMonth.bind(this)}>
              <NavigateNext />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            alignItems: "stretch",
          }}
        >
          <div style={{ flexBasis: "100%" }} />
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              SUN
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              MON
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              TUE
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              WED
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              THU
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              FRI
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              SAT
            </Typography>
          </div>
          <div style={{ flexBasis: "100%" }} />
          {BEGIN_MONTH_BLANK}
          {MONTH_DAYS}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
