// helpers
// actions
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperStudentPage from "../component/WrapperStudentPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getUserById: (...args) => dispatch(Actions.getUserById(...args)),
  getSurveys: (...args) => dispatch(Actions.getSurveys(...args)),
});
class StudentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getUserById: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      id: urlParams.id,
      loading: true,
      surveys: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getUserById, getSurveys } = this.props;
    const { id } = this.state;
    const r = await getUserById(id);
    if (r.success) {
      const user = r.payload;
      const r1 = await getSurveys([
        { name: "surveys.userUUID", comparison: "eq", value: user.uuid },
      ]);
      this.setState({
        loading: false,
        user,
        surveys: r1.payload,
      });
    }
  }

  render() {
    const { location, history } = this.props;

    const { urlParams, loading, user, surveys } = this.state;

    return (
      <WrapperStudentPage
        history={history}
        location={location}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        loading={loading}
        surveys={surveys}
        user={user}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPage);
