import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const pricingPlans = `/${service}/pricingPlans`;

export function getPricingPlans() {
  const url = `${pricingPlans}`;
  return {
    method: GET,
    url,
  };
}

export function getPricingPlanById(id) {
  const url = `${pricingPlans}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePricingPlan(id, body) {
  const url = `${pricingPlans}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePricingPlan(id) {
  const url = `${pricingPlans}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPricingPlan(body) {
  const url = `${pricingPlans}`;
  return {
    method: POST,
    url,
    body,
  };
}
