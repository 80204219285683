import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
// components
import EnrollmentsList from "components/EnrollmentsList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperEnrollmentPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    enrollments: PropTypes.array,
    createEnrollment: PropTypes.func,
    deleteEnrollment: PropTypes.func,
    updateEnrollment: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEnrollment: undefined,
      formEnrollmentOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { enrollments, history } = this.props;

    switch (index) {
      case 0:
        return (
          <EnrollmentsList
            history={history}
            enrollments={enrollments}
            onSelect={(selectedEnrollment) =>
              this.setState({
                selectedEnrollment,
                formEnrollmentOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Enrollments"
        loadingMessage={"Fetching all Courses."}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEnrollmentPage);
