export default (theme) => ({
  container: {
    padding: 5,
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor:
      theme.palette.type === "dark"
        ? "#424242 !important"
        : "#eceff1 !important",
  },
  back: {
    color: "rgba(127,127,127,0.5)",
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  sticky: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.palette.type === "dark" ? "#212121 !important" : "white !important",
    padding: 10,
    borderRadius: 0,
    zIndex: 99,
  },
  selectedLecture: {
    background: "#ff9800 !important",
    color: "white !important",
  },
  selectedModule: {
    // background: '#ff9800 !important',
    // color: 'white !important',
  },
  selectedPrimary: {
    color: "white",
  },
  selectedSecondary: {
    // color: 'white',
    // opacity: 0.6,
  },
  // primary: {
  //   color: 'black !important',
  // },
  darkMode: {
    // color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
});
