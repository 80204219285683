import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// custom
import FormProfile from "components/FormProfile";

// custom
import PDF from "components/PDF";

// config
import { REACT_APP_API, QUESTION_BACKGROUND_LOGO } from "config";

// styles
import styles from "./styles";

class PreviewProfile extends Component {
  static propTypes = {
    classes: PropTypes.object,
    assessment: PropTypes.object,
    profiles: PropTypes.object,
    getProfiles: PropTypes.func,
    updateProfile: PropTypes.func,
    createProfile: PropTypes.func,
    deleteProfile: PropTypes.func,
    updateAssessment: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  state = {
    value: 0,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    const { MessageCenter } = this.context;

    MessageCenter.open("right");
    if (assessment && assessment.id) {
      this.state = {
        ...assessment,
        formProfileOpen: false,
      };
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.assessment && nextProps.assessment.id) {
      this.setState({
        ...nextProps.assessment,
      });
    }
  }

  async save(name, numeric) {
    const { updateAssessment, refresh, assessment } = this.props;

    if (assessment[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateAssessment(assessment.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      assessment,
      profiles,
      updateProfile,
      createProfile,
      deleteProfile,
      getProfiles,
      refresh,
    } = this.props;

    const { value, profileID, formProfileOpen, selectedProfile } = this.state;

    const { MessageCenter } = this.context;

    return (
      <div className={classes.container}>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{
            flexGrow: 1,
            display: "flex",
            color: "white",
            overflow: "hidden",
            height: "100%",
          }}
          direction="column"
        >
          <Grid xs={12} item style={{ width: "100%", position: "relative" }}>
            <PDF
              key={MessageCenter.isOpen("right")}
              src={`${REACT_APP_API}/harmony/assessments/${assessment.id}/profile?value=${value}`}
            />
          </Grid>
          <img
            src={QUESTION_BACKGROUND_LOGO}
            alt="logo"
            style={{
              position: "absolute",
              bottom: 20,
              left: 20,
              opacity: 0.08,
            }}
          />
        </Grid>
        <Portal container={MessageCenter.getPortalContainer("right")}>
          <Grid container className={classes.drawer}>
            <Grid item>
              <Typography display="block" variant="h6">
                Answers
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Select the answer the user would use for every question :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2} direction="column">
                <Grid item style={{ textAlign: "center", zIndex: 99 }}>
                  <Button
                    color={value === 0 ? "primary" : "inherit"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ value: 0 })}
                  >
                    Very untrue for me or never
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    color={value === 1 ? "primary" : "inherit"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ value: 1 })}
                  >
                    Somewhat untrue for me or rarely
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    color={value === 2 ? "primary" : "inherit"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ value: 2 })}
                  >
                    Unsure or sometimes
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    color={value === 3 ? "primary" : "inherit"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ value: 3 })}
                  >
                    Somewhat true for me or often
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    color={value === 4 ? "primary" : "inherit"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ value: 4 })}
                  >
                    Very true for me or always
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-profile">PDF Profile</InputLabel>
                    <Select
                      inputProps={{
                        name: "profile",
                        id: "group-profile",
                      }}
                      value={profileID || ""}
                      onChange={(e) => {
                        this.state.profileID = e.target.value;
                        this.save("profileID");
                      }}
                    >
                      {profiles.map((a) => (
                        <MenuItem key={`profile_${a.id}`} value={a.id}>
                          <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item style={{ width: 220 }}>
                              <Typography display="block" noWrap>
                                {a.name || a.tagLine}
                              </Typography>
                            </Grid>
                            <Grid item style={{ width: 25 }}>
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.setState({
                                    selectedProfile: a,
                                    formProfileOpen: true,
                                  });
                                }}
                                style={{ padding: 4 }}
                              >
                                <Edit style={{ fontSize: 18 }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Chip
                    label={"+ New PDF Profile"}
                    onClick={() =>
                      this.setState({
                        formProfileOpen: true,
                      })
                    }
                    style={{
                      marginTop: 8,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Portal>
        <FormProfile
          open={formProfileOpen}
          close={() =>
            this.setState({
              formProfileOpen: false,
              selectedProfile: undefined,
            })
          }
          updateProfile={updateProfile}
          createProfile={createProfile}
          deleteProfile={deleteProfile}
          profile={selectedProfile}
          refresh={() => {
            refresh();
            getProfiles();
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PreviewProfile);
