import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperTeamPage from "../component/WrapperTeamPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
  getTeams: () => dispatch(Actions.getTeams()),
  getTeamById: (...args) => dispatch(Actions.getTeamById(...args)),
  createTeam: (...args) => dispatch(Actions.createTeam(...args)),
  deleteTeam: (...args) => dispatch(Actions.deleteTeam(...args)),
  updateTeam: (...args) => dispatch(Actions.updateTeam(...args)),
});
class TeamPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    teams: PropTypes.array,
    getTeams: PropTypes.func,
    getTeamById: PropTypes.func,
    createTeam: PropTypes.func,
    deleteTeam: PropTypes.func,
    updateTeam: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, teams } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: teams.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getTeams } = this.props;
    await Promise.all([getTeams()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      teams,
      getTeamById,
      createTeam,
      deleteTeam,
      updateTeam,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperTeamPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        teams={teams}
        getTeamById={getTeamById}
        createTeam={createTeam}
        deleteTeam={deleteTeam}
        updateTeam={updateTeam}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
