import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// material-ui
import { withStyles } from "@material-ui/styles";
// env
import { getLogo } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Card extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    card: PropTypes.object,
    dardMode: PropTypes.bool,
    deleteCard: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    refresh: PropTypes.func,
    setCardAsFavorite: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  confirmDeletion() {
    const { NotificationCenter } = this.context;
    const { card } = this.props;

    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: `Card ending in ${card.lastFour} will be deleted.`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.deleteCard.bind(this),
        },
      }
    );
  }

  async deleteCard() {
    const { card, deleteCard, refresh } = this.props;
    await deleteCard(card.id);
    refresh();
  }

  async makeDefault() {
    const { card, setCardAsFavorite, refresh } = this.props;
    await setCardAsFavorite(card.id);
    refresh();
  }

  render() {
    const {
      classes,
      card,
      dardMode,
      refresh,
      deleteCard,
      setCardAsFavorite,
      onSelect,
      selected,
      theme,
    } = this.props;

    return (
      <ExpansionPanel
        className={classes.root}
        style={{
          borderLeft: selected
            ? `solid 3px ${theme.palette.primary.color[600]}`
            : "none",
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" spacing={2}>
            {onSelect ? (
              <Grid item>
                <Radio
                  color="primary"
                  checked={selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSelect(card);
                  }}
                />
              </Grid>
            ) : (
              []
            )}
            <Grid item>
              <img
                src={getLogo(card, dardMode)}
                style={{ height: 15 }}
                alt="card type"
              />
            </Grid>
            <Grid item>
              <Typography display="block" variant="body2">
                {card.brand} ending in {card.lastFour}{" "}
                <b>{card.default ? "(Default)" : ""}</b>
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Name on Card
              </Typography>
              <Typography display="block" variant="body2">
                {card.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Brand
              </Typography>
              <Typography display="block" variant="body2">
                {card.brand}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Zipcode / Country
              </Typography>
              <Typography display="block" variant="body2">
                {card.zip} / {card.country}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Expiration
              </Typography>
              <Typography display="block" variant="body2">
                {card.expMonth}/{card.expYear}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography display="block" variant="caption" color="textSecondary">
                Identifier
              </Typography>
              <Typography display="block" variant="body2">
                {card.id}
              </Typography>
            </Grid> */}
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        {refresh && (setCardAsFavorite || deleteCard) ? (
          <ExpansionPanelActions>
            {!card.default && refresh && setCardAsFavorite ? (
              <Button size="small" onClick={this.makeDefault.bind(this)}>
                Make Default
              </Button>
            ) : (
              []
            )}
            {refresh && deleteCard ? (
              <Button size="small" onClick={this.confirmDeletion.bind(this)}>
                Remove
              </Button>
            ) : (
              <div />
            )}
          </ExpansionPanelActions>
        ) : (
          <div />
        )}
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Card);
