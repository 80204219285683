import Drawer from "@material-ui/core/Drawer";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

// styles
const styles = (theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  messageDrawer: {
    position: "relative",
    border: "none",
    background: theme.palette.background.paper,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.33)",
  },
  full: {
    height: "100%",
    width: "100%",
  },
});

class MessageDrawer extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    background: PropTypes.string,
  };

  static childContextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = this.initState();
  }

  getChildContext() {
    return {
      MessageCenter: {
        open: this.open.bind(this),
        toggle: this.toggle.bind(this),
        isOpen: this.isOpen.bind(this),
        close: this.close.bind(this),
        getPortalContainer: this.getPortalContainer.bind(this),
      },
    };
  }

  getPortalContainer(anchor) {
    return this[`${anchor}Container`];
  }

  initState() {
    return {
      top: false,
      bottom: false,
      left: false,
      right: false,
      elevation: 0,
    };
  }

  open(anchor) {
    this.setState({ [anchor]: true });
  }

  toggle(anchor) {
    this.setState((prevState) => ({ [anchor]: !prevState[anchor] }));
  }

  isOpen(anchor) {
    return this.state[anchor];
  }

  close(anchor) {
    if (anchor !== undefined) {
      this.setState({ [anchor]: false });
    } else {
      this.setState(this.initState());
    }
  }

  render() {
    const { classes, children, background } = this.props;
    const { top, bottom, left, right } = this.state;

    return (
      <div className={classes.row}>
        <Drawer
          anchor="left"
          transitionDuration={{
            enter: 500,
          }}
          open={left}
          variant="persistent"
          onClose={() => this.setState({ left: false })}
          classes={{
            paper: classes.messageDrawer,
          }}
        >
          <div
            ref={(ref) => {
              this.leftContainer = ref;
            }}
            className={classes.full}
          />
        </Drawer>
        <div
          className={classes.column}
          style={{
            background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${background}) no-repeat`,
            backgroundSize: "cover !important",
            backgroundPosition: "center !important",
          }}
        >
          <Drawer
            anchor="top"
            transitionDuration={{
              enter: 500,
            }}
            open={top}
            variant="persistent"
            onClose={() => this.setState({ top: false })}
            classes={{
              paper: classes.messageDrawer,
            }}
          >
            <div
              ref={(ref) => {
                this.topContainer = ref;
              }}
              className={classes.full}
            />
          </Drawer>
          {children}
          <Drawer
            anchor="bottom"
            open={bottom}
            variant="persistent"
            onClose={() => this.setState({ bottom: false })}
            classes={{
              paper: classes.messageDrawer,
            }}
            transitionDuration={{
              enter: 500,
            }}
          >
            <div
              ref={(ref) => {
                this.bottomContainer = ref;
              }}
              className={classes.full}
            />
          </Drawer>
        </div>
        <Drawer
          anchor="right"
          transitionDuration={{
            enter: 500,
          }}
          open={right}
          variant="permanent"
          onClose={() => this.setState({ right: false })}
          classes={{
            paper: classes.messageDrawer,
          }}
        >
          <div
            ref={(ref) => {
              this.rightContainer = ref;
            }}
            className={classes.full}
          />
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(MessageDrawer);
