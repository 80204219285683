import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// material-ui
import Typography from "@material-ui/core/Typography";
import Event from "@material-ui/icons/Event";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Person from "@material-ui/icons/Person";
import Public from "@material-ui/icons/Public";
import School from "@material-ui/icons/School";
import Clock from "@material-ui/icons/WatchLater";
import { withStyles } from "@material-ui/styles";
import Calendar from "components/CalendarRefactor";
// components
import Page from "components/Page";
import Timezone from "components/Timezone";
import TimezoneSelect from "components/TimezoneSelect";
// constants
import { EVENTS } from "constants/routes";
import { extendMoment } from "moment-range";
import mom from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Rocket from "./rocket.svg";
// styles
import styles from "./styles";

const moment = extendMoment(mom);

class WrapperSchedulePage extends Component {
  static propTypes = {
    urlParams: PropTypes.object,
    history: PropTypes.object,
    student: PropTypes.object,
    teacher: PropTypes.object,
    createEvent: PropTypes.func,
    getEventTypesPublicAvailabilities: PropTypes.func,
    classes: PropTypes.object,
    loading: PropTypes.object,
    eventType: PropTypes.object,
    availabilities: PropTypes.array,
    timezones: PropTypes.array,
    getDailyAvailabilities: PropTypes.func,
    coach: PropTypes.object,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
  };

  state = {
    selectedDay: undefined,
    publicAvailabilities: undefined,
    creatingEvent: false,
    success: false,
    format: "12",
    memo: "",
    tzName: moment.tz.guess(),
  };

  onFormatChange(format) {
    const { selectedDay } = this.state;
    this.setState(
      { format },
      selectedDay ? () => this.onDaySelect(selectedDay) : undefined
    );
  }

  onDaySelect(s) {
    const {
      getEventTypesPublicAvailabilities,
      coach,
      eventType,
      teacher,
    } = this.props;
    const { format, tzName } = this.state;
    const selectedDay = s;
    this.setState({ timeLoading: true });
    this.setState({ selectedDay }, async () => {
      const resp = await getEventTypesPublicAvailabilities(
        eventType.id,
        `?coachID=${coach.id}&date=${s.format(
          "YYYY-MM-DD"
        )}&format=${format}&tzName=${tzName}${
          teacher ? `&teacherID=${teacher.id}` : ""
        }`
      );
      if (resp.success) {
        this.setState({
          publicAvailabilities: resp.payload,
          timeLoading: false,
        });
      }
    });
  }

  isDayAvailable(day) {
    const { availabilities } = this.props;

    for (const key in availabilities) {
      if (availabilities.hasOwnProperty(key)) {
        const a = availabilities[key];
        const range = moment.range(moment(a.dateFromUTC), moment(a.dateToUTC));
        if (
          range.contains(day) &&
          a[day.format("dddd").toLowerCase()] &&
          a.available
        ) {
          return true;
        }
      }
    }
    return false;
  }

  async schedule() {
    const { createEvent, coach, eventType, student, teacher } = this.props;

    const { selectedTime, memo, tzName } = this.state;

    const body = {
      coachID: coach.id,
      teacherID: teacher && teacher.id,
      eventTypeID: eventType.id,
      studentID: student.id,
      dateFromUTC: selectedTime.dateFromUTC,
      tzName,
      memo,
    };
    this.setState({ creatingEvent: true });
    const resp = await createEvent(body);
    if (resp.success) {
      this.setState({ creatingEvent: false, success: true });
    }
  }

  render() {
    const {
      classes,
      loading,
      eventType,
      availabilities,
      coach,
      history,
      timezones,
      teacher,
      getDailyAvailabilities,
      refreshKey,
      refresh,
    } = this.props;

    const {
      selectedDay,
      selectedTime,
      memo,
      success,
      creatingEvent,
      timeLoading,
      format,
      publicAvailabilities,
      tzName,
    } = this.state;

    if (
      !loading &&
      (eventType === undefined ||
        coach === undefined ||
        availabilities === undefined ||
        availabilities.length === 0)
    ) {
      return (
        <Page helmet="Schedule" loadingMessage={"Loading..."}>
          <Paper className={classes.paper}>
            <Grid container justify={"flex-start"}>
              <Grid
                item
                style={{ width: "100%", maxWidth: 280, textAlign: "center" }}
              >
                <div className={classes.padding}>
                  <Typography display="block" variant="body2">
                    🤦‍♂️Something went wrong
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Page>
      );
    }

    return (
      <Page helmet="Schedule" loading={loading} loadingMessage={"Loading..."}>
        <Paper className={classes.paper}>
          <Grid
            container
            justify={"flex-start"}
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%", maxWidth: 280 }}>
              <div className={classes.padding}>
                <div
                  style={{
                    position: "relative",
                    width: 80,
                    height: teacher ? 75 : undefined,
                  }}
                >
                  <Avatar
                    src={coach && coach.avatar}
                    style={{
                      height: 60,
                      width: 60,
                      border: "solid 2px white",
                    }}
                  />
                  {teacher ? (
                    <Avatar
                      src={teacher && teacher.avatar}
                      style={{
                        height: 30,
                        width: 30,
                        border: "solid 2px white",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    />
                  ) : (
                    []
                  )}
                </div>
                <Typography display="block" variant="title">
                  {eventType && eventType.name}
                </Typography>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Clock color="disabled" />
                  </Grid>
                  <Grid item>
                    <Typography display="block" color="textSecondary">
                      {`${eventType && eventType.duration} Minutes`}
                    </Typography>
                  </Grid>
                </Grid>
                {selectedTime ? (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Event color="disabled" />
                    </Grid>
                    <Grid item style={{ maxWidth: 200 }}>
                      <Typography display="block" color="primary">
                        {`${moment(selectedTime.dateFromUTC).format(
                          "HH:mma"
                        )} - ${moment(selectedTime.dateToUTC).format(
                          "HH:mma"
                        )}, ${moment(selectedTime.dateFromUTC).format(
                          "dddd, MMMM D, YYYY"
                        )}`}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {coach ? (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Person color="disabled" />
                    </Grid>
                    <Grid item style={{ maxWidth: 200 }}>
                      <Typography display="block" color="textSecondary">
                        {`Coach: ${coach.fullName}`}
                      </Typography>
                      <Typography display="block" variant="caption">
                        <Timezone
                          tzName={coach && coach.tzName}
                          timezones={timezones}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {teacher ? (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <School color="disabled" />
                    </Grid>
                    <Grid item style={{ maxWidth: 200 }}>
                      <Typography display="block" color="textSecondary">
                        {`Teacher: ${teacher.fullName}`}
                      </Typography>
                      <Typography display="block" variant="caption">
                        <Timezone
                          tzName={teacher && teacher.tzName}
                          timezones={timezones}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Public color="disabled" />
                  </Grid>
                  <Grid item style={{ maxWidth: 200 }}>
                    <Typography display="block" color="textSecondary">
                      Your Timezone
                    </Typography>
                    <TimezoneSelect
                      tzName={tzName}
                      timezones={timezones}
                      onChange={(t) => {
                        this.setState({ tzName: t }, () => {
                          this.onDaySelect(selectedDay);
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <span
                      className={classes.format}
                      style={{
                        background:
                          format === "12" ? "rgba(155,155,155,0.2)" : undefined,
                      }}
                      onClick={() => this.onFormatChange("12")}
                    >
                      12h Format
                    </span>
                    <span
                      className={classes.format}
                      style={{
                        background:
                          format === "24" ? "rgba(155,155,155,0.2)" : undefined,
                      }}
                      onClick={() => this.onFormatChange("24")}
                    >
                      24h Format
                    </span>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {selectedTime === undefined && !creatingEvent && !success ? (
              <Grid item xs={12} md={6}>
                <div className={classes.padding}>
                  <Typography display="block" variant="title">
                    Select a Date & Time
                  </Typography>
                  <Calendar
                    onSelect={this.onDaySelect.bind(this)}
                    selected={selectedDay}
                    key={`${tzName}${format}`}
                    getDailyAvailabilities={(d) =>
                      getDailyAvailabilities(
                        coach.id,
                        d,
                        format,
                        teacher && teacher.id,
                        tzName
                      )
                    }
                  />
                </div>
              </Grid>
            ) : (
              []
            )}
            {selectedDay &&
            selectedTime === undefined &&
            !creatingEvent &&
            !success ? (
              <Grid
                item
                style={{ maxWidth: 250, width: "100%", height: "100%" }}
              >
                <div className={classes.padding}>
                  {timeLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid container>
                      <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Typography display="block" variant="body2">
                          {selectedDay.format("dddd, MMMM D")}
                        </Typography>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          {`Times are local to your current timezone (${tzName})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.frame}>
                        {publicAvailabilities &&
                          publicAvailabilities.map((pa) => (
                            <Grid item xs={12} key={pa.dateFromUTC}>
                              <Button
                                onClick={() =>
                                  this.setState({ selectedTime: pa })
                                }
                                variant="outlined"
                                disabled={!pa.available}
                                style={{
                                  width: "100%",
                                  marginBottom: 8,
                                  color: "#607d8b",
                                  opacity: !pa.available ? 0.3 : 1,
                                }}
                              >
                                {pa.time}
                              </Button>
                            </Grid>
                          ))}
                        {publicAvailabilities &&
                        publicAvailabilities.length === 0 ? (
                          <Typography display="block" variant="body2">
                            Sorry, there is no availablities for this day.
                          </Typography>
                        ) : (
                          []
                        )}
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            ) : (
              []
            )}
            {creatingEvent ? (
              <Grid
                container
                style={{ maxWidth: 720, width: "100%", textAlign: "center" }}
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <CircularProgress />
                  <Typography display="block" color="textSecondary">
                    Scheduling ...
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            {success ? (
              <Grid
                container
                style={{ maxWidth: 720, width: "100%", textAlign: "center" }}
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <img src={Rocket} alt="rocket" style={{ height: 60 }} />
                  <Typography display="block" variant="title">
                    You are all set!
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    You will receive a confirmation email shortly.
                  </Typography>
                  <Button
                    variant="raised"
                    style={{ marginTop: 20, textTransform: "none" }}
                    onClick={() => history.push(EVENTS)}
                  >
                    Schedule Another Session
                  </Button>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            {selectedTime && !creatingEvent && !success ? (
              <Grid item style={{ flexGrow: 1 }}>
                <div className={classes.padding}>
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <IconButton
                        color="inherit"
                        style={{ background: "rgba(155,155,155,0.1)" }}
                        onClick={() =>
                          this.setState({ selectedTime: undefined })
                        }
                      >
                        <NavigateBefore />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography display="block" variant="title">
                        Enter Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        label="Details"
                        placeholder="Please share anything that will help prepare for our meeting."
                        value={memo}
                        onChange={(e) =>
                          this.setState({ memo: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item style={{ maxWidth: 690 }}>
                      <Typography display="block" variant="body2">
                        Google Meet will be use for this session. This solution
                        is 100% web based and does not required any additional
                        software other than your web browser. However you will
                        need a Google Account. It is free and you can sign up{" "}
                        <a
                          style={{ color: "#ff9800" }}
                          href="https://accounts.google.com/signup/v2/webcreateaccount?flowName=GlifWebSignIn&flowEntry=SignUp"
                        >
                          here
                        </a>
                        .
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        stye={{ textTransform: "none", marginTop: 16 }}
                        onClick={this.schedule.bind(this)}
                      >
                        Schedule Event
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </Paper>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSchedulePage);
