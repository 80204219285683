import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Duo from "@material-ui/icons/Duo";
import EventBusyIcon from "@material-ui/icons/EventBusyTwoTone";
import EventIcon from "@material-ui/icons/EventTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
// styles
import styles from "./styles";

class Event extends Component {
  static propTypes = {
    classes: PropTypes.object,
    event: PropTypes.object,
    updateEvent: PropTypes.func,
    history: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { event } = this.props;
    this.state = {
      ...event,
      loading: false,
    };
  }

  handleDateChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;
    const { tzName } = this.state;

    this.setState({
      [name]: moment.tz(value, tzName).utc().format(),
    });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  addToCalendar() {
    const { event } = this.props;
    const ics = require("ics");

    const e = {
      start: moment(event.dateFromUTC).format("YYYY-M-D-H-m").split("-"),
      end: moment(event.dateToUTC).format("YYYY-M-D-H-m").split("-"),
      title: `Private Session with ${event.student.fullName} (${event.student.email})`,
      description: ``,
      url: window.location.href,
      categories: ["Winning on Stage"],
    };

    ics.createEvent(e, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      fileDownload(
        value,
        `Private Session with ${event.student.fullName} (${event.student.email}).ics`
      );
    });
  }

  async save(name, numeric) {
    const { refresh, event, updateEvent } = this.props;

    if (event[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateEvent(event.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  requestEdit() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        body: (
          <Grid container justify="center">
            <Grid item xs={12}>
              <img
                alt="reschedule"
                src="https://storage.googleapis.com/college101-images/reschedule.png"
                style={{ height: 100 }}
              />
            </Grid>
            <Grid item>
              <Typography display="block" variant="h6">
                Want to Change the Event? Let's Chat!
              </Typography>
              <Typography display="block" variant="body2" color="textSecondary">
                Chat with our team to edit a scheduled event.
              </Typography>
            </Grid>
          </Grid>
        ),
        timestamp: new Date().getTime(),
      },
      {
        cancel: {
          label: "Dismiss",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "Start a Live Chat",
          level: "warning",
          callback: () => {
            window.$crisp.push(["do", "chat:open"]);
          },
        },
      }
    );
  }

  render() {
    const { classes, event } = this.props;
    const { tzName, memo, billableDuration } = this.state;
    const dateFrom = moment(event.dateFromUTC);
    const dateTo = moment(event.dateToUTC);

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar src={event.coach.avatar} />
                </Grid>
                {event.teacher ? (
                  <Grid item>
                    <Avatar src={event.teacher.avatar} />
                  </Grid>
                ) : (
                  []
                )}
                <Grid item>
                  <div className={classes.time}>
                    {`${dateFrom.format("hh:mm a")} - ${dateTo.format(
                      "hh:mm a"
                    )}`}
                  </div>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {dateFrom.fromNow()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    <b>{event.student && event.student.fullName}</b>
                  </Typography>
                  <Typography display="block" variant="caption">
                    {event.eventType && event.eventType.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    Coach: <b>{event.coach && event.coach.fullName}</b>
                  </Typography>
                </Grid>
                {event.teacher ? (
                  <Grid item>
                    <Typography display="block" variant="body2">
                      Teacher: <b>{event.teacher && event.teacher.fullName}</b>
                    </Typography>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {billableDuration ? `Lasted ${billableDuration} minutes` : ""}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item style={{ paddingTop: 20 }}>
              <Button
                variant="outlined"
                style={{
                  color: "#4caf50",
                  borderColor: "#4caf50",
                }}
              >
                <Duo /> Join Meeting
              </Button>
              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{ paddingTop: 8 }}
                onClick={this.addToCalendar.bind(this)}
              >
                <Grid item>
                  <EventIcon style={{ fontSize: 16 }} />
                </Grid>
                <Grid item>
                  <Typography
                    display="block"
                    style={{ cursor: "pointer" }}
                    color="textSecondary"
                  >
                    Add to Calendar
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                spacing={1}
                onClick={this.requestEdit.bind(this)}
                style={{ paddingTop: 8 }}
              >
                <Grid item>
                  <EventBusyIcon style={{ fontSize: 16 }} />
                </Grid>
                <Grid item>
                  <Typography
                    display="block"
                    style={{ cursor: "pointer" }}
                    color="textSecondary"
                  >
                    Edit/Cancel Event
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 20, flexGrow: 1 }}>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Your Timezone
              </Typography>
              <Typography display="block" variant="body2">
                {tzName}
              </Typography>
              {memo ? (
                <React.Fragment>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Memo
                  </Typography>
                  <Typography display="block" variant="body2">
                    {memo}
                  </Typography>
                </React.Fragment>
              ) : (
                []
              )}
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Coach Name
              </Typography>
              <Typography display="block" variant="body2">
                {event.coach.fullName}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Coach Email
              </Typography>
              <Typography display="block" variant="body2">
                {event.coach.email}
              </Typography>
              {event.teacher
                ? [
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      Teacher Name
                    </Typography>,
                    <Typography display="block" variant="body2">
                      {event.teacher.fullName}
                    </Typography>,
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      Teacher Email
                    </Typography>,
                    <Typography display="block" variant="body2">
                      {event.teacher.email}
                    </Typography>,
                  ]
                : []}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Event);
