import { serviceRoles } from "config/access";
import { TEAM } from "constants/routes";
import { checkAccess } from "helpers/auth";
import TeamPage from "./container/TeamPage";

export default function getSettingsRoute(userAuth) {
  const { account, harmony } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [harmony.name]: [harmony.roles.admin],
    [harmony.gateway]: [harmony.roles.gateway],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: TEAM,
    withSidebar: true,
    withAppBar: true,
    component: TeamPage,
  };
}
