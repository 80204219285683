import Avatar from "@material-ui/core/Avatar";
import People from "@material-ui/icons/People";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TeamsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    teams: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedTeam) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedTeam);
    }
  }

  render() {
    const { teams } = this.props;

    return (
      <Table
        data={teams}
        meta={[
          {
            path: "id",
            title: "",
            width: 35,
            render: () => (
              <Avatar>
                <People />
              </Avatar>
            ),
          },
          {
            path: "name",
            title: "Name",
          },
          {
            path: "description",
            title: "Description",
          },
          {
            path: "dateFrom",
            title: "From",
            transform: (t) => moment(t).format("MM/DD/YYYY"),
          },
          {
            path: "dateTo",
            title: "To",
            transform: (t) => moment(t).format("MM/DD/YYYY"),
          },
        ]}
        title={"Teams"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(TeamsList);
