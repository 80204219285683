// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCreditPage from "../component/WrapperCreditPage";

const mapStateToProps = (state) => ({
  credits: state.credits,
  myStudent: state.myStudent,
});

const mapDispatchToProps = (dispatch) => ({
  getCredits: () => dispatch(Actions.getCredits()),
  getCreditById: (...args) => dispatch(Actions.getCreditById(...args)),
  createCredit: (...args) => dispatch(Actions.createCredit(...args)),
  deleteCredit: (...args) => dispatch(Actions.deleteCredit(...args)),
  updateCredit: (...args) => dispatch(Actions.updateCredit(...args)),
  getEventTypes: (...args) => dispatch(Actions.getEventTypes(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
  getMyStudent: (...args) => dispatch(Actions.getMyStudent(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
});
class CreditPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    credits: PropTypes.array,
    getCredits: PropTypes.func,
    getCreditById: PropTypes.func,
    createCredit: PropTypes.func,
    deleteCredit: PropTypes.func,
    updateCredit: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, credits } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: credits.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const {
      getCredits,
      getEventTypes,
      getCoaches,
      getAvailabilities,
      getMyStudent,
    } = this.props;
    const resps = await Promise.all([
      getEventTypes(),
      getCoaches(),
      getAvailabilities(),
      getMyStudent(),
      getCredits(),
    ]);

    this.setState({
      eventTypes: resps[0].payload,
      coaches: resps[1].payload,
      availabilities: resps[2].payload,
      loading: false,
    });
  }

  render() {
    const {
      history,
      credits,
      getCreditById,
      createCredit,
      deleteCredit,
      updateCredit,
      eventTypes,
      coaches,
      availabilities,
      myStudent,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCreditPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        credits={credits}
        timeAssets={myStudent && myStudent.timeAssets}
        getCreditById={getCreditById}
        eventTypes={eventTypes}
        availabilities={availabilities}
        coaches={coaches}
        createCredit={createCredit}
        deleteCredit={deleteCredit}
        updateCredit={updateCredit}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditPage);
