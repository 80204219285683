import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
// custom
import AutoComplete from "components/AutoComplete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormTeamMember extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    member: PropTypes.object,

    createTeamMember: PropTypes.func,
    updateTeamMember: PropTypes.func,
    deleteTeamMember: PropTypes.func,
    teamUsers: PropTypes.func,
    teamID: PropTypes.number,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      role: "",
      leader: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.member);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(member) {
    this.setState({
      loading: false,
      role: member ? member.role : "",
      leader: member ? member.leader : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this team, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteTeamMember, close, refresh, member } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteTeamMember(member.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Member has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateTeamMember,
      createTeamMember,
      member,
      refresh,
      close,
      teamID,
    } = this.props;

    const { role, leader, selectedMember } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (member) {
      resp = await updateTeamMember(member.id, {
        role,
        leader,
      });
    } else {
      resp = await createTeamMember({
        role,
        leader,
        userUID: selectedMember.userUID,
        teamID,
      });
    }

    this.setState({ loading: false, selectedMember: undefined });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: member ? "Team has been updated." : "Team has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, close, member, teamUsers } = this.props;

    const { loading, role, leader, selectedMember } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {member
                ? `${member.firstName} ${member.lastName}`
                : "Let's add a new Member"}
            </DialogTitle>
            <DialogContent style={{ minHeight: 400 }}>
              <DialogContentText>
                {member
                  ? "To update the member, fill the form below :"
                  : "To create a new member, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                {member === undefined ? (
                  <Grid item xs={12}>
                    {selectedMember ? (
                      <div>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          User
                        </Typography>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          style={{
                            background: "rgba(155, 155, 155, 0.1)",
                            border: "solid 1px rgba(155, 155, 155, 0.2)",
                            marginTop: 4,
                            borderRadius: 4,
                          }}
                        >
                          <Grid item>
                            <Avatar>
                              <Person />
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="body2">
                              {`${selectedMember.firstName} ${selectedMember.lastName}`}
                            </Typography>
                            <Typography
                              display="block"
                              color="primary"
                              variant="caption"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({ selectedMember: undefined })
                              }
                            >
                              Clear
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        style={{
                          background: "rgba(155, 155, 155, 0.1)",
                          border: "solid 1px rgba(155, 155, 155, 0.2)",
                          marginTop: 4,
                          borderRadius: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <AutoComplete
                            placeHolder={"Search for User"}
                            onSelect={(m) => {
                              this.setState({
                                selectedMember: m.value,
                              });
                            }}
                            suggestions={
                              teamUsers &&
                              teamUsers.map((v) => ({
                                value: v,
                                label: `${v.firstName} ${v.lastName} (${v.email})`,
                              }))
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  []
                )}
                <Grid item xs={12}>
                  <TextField
                    id="role"
                    label="Role"
                    className={classes.textField}
                    value={role}
                    onChange={this.handleChange("role")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={leader}
                        onChange={this.handleCheckboxChange("leader")}
                        value="leader"
                      />
                    }
                    label="Leader"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {member && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {member ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormTeamMember));
