import { POST } from "constants/methods";

const harmony = "/harmony";
const aptitude = "/aptitude";

export function harmonyCallback(body) {
  const url = `${harmony}/callbacks`;
  return {
    method: POST,
    url,
    body,
  };
}

export function aptitudeCallback(body) {
  const url = `${aptitude}/callbacks`;
  return {
    method: POST,
    url,
    body,
  };
}
