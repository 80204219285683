import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWalletTwoTone";
import Back from "@material-ui/icons/ArrowBack";
import Calendar from "@material-ui/icons/CalendarTodayTwoTone";
import Check from "@material-ui/icons/CheckTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import History from "@material-ui/icons/HistoryTwoTone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import Payment from "@material-ui/icons/PaymentTwoTone";
import { withStyles } from "@material-ui/styles";
import Card from "components/Card";
// reactor
import PageContainer from "components/Page";
import PaymentsList from "components/PaymentsList";
import PDF from "components/PDF";
import { REACT_APP_API } from "config";
import { MY_ACCOUNT } from "constants/routes";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperInvoicePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    app: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    invoice: PropTypes.object,
    customer: PropTypes.object,
    loading: PropTypes.bool,
    cards: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    selectedCard: undefined,
    paymentOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.cards.length && !this.props.cards.length) {
      const { cards } = nextProps;
      for (const key in cards) {
        if (cards.hasOwnProperty(key)) {
          const card = cards[key];
          if (card.default) {
            this.setState({ selectedCard: card });
            break;
          }
        }
      }
    }
  }

  async pay() {
    const { payInvoice, refresh, invoice } = this.props;
    const { selectedCard } = this.state;
    const { NotificationCenter } = this.context;
    this.setState({ processing: true });
    const resp = await payInvoice(invoice.id, { stripeToken: selectedCard.id });

    if (resp.success) {
      this.setState({ processing: false, paymentOpen: false });
      refresh();
      NotificationCenter.sweetAlert(
        {
          title: "Success",
          success: true,
          body: "Your invoice has been paid",
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );
    } else {
      this.setState({ processing: false });
    }
  }

  render() {
    const {
      classes,
      loading,
      invoice,
      customer,
      history,
      cards,
      app,
    } = this.props;

    const { selectedCard, paymentOpen, processing } = this.state;

    let invoiceStatus = "Due";
    let invoiceColor = "#ff9800";
    let invoiceIcon = <Calendar style={{ color: "white" }} />;
    if (invoice && invoice.paid) {
      invoiceStatus = "Paid";
      invoiceColor = "#4caf50";
      invoiceIcon = <Check style={{ color: "white" }} />;
    }
    if (invoice && invoice.lastPayment && invoice.lastPayment.refunded) {
      invoiceStatus = "Refunded";
      invoiceColor = "#2196f3";
      invoiceIcon = <History style={{ color: "white" }} />;
    }

    return (
      <PageContainer
        helmet="Invoice"
        noPadding
        loadingMessage={"Loading Invoice"}
        loading={loading}
        header={
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: 10, zIndex: 9999 }}
          >
            <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
              <Chip
                label={"Back"}
                className={classes.backChip}
                onClick={() => history.push(`${MY_ACCOUNT}?index=0`)}
                variant="outlined"
                avatar={
                  <Avatar className={classes.avatar}>
                    <Back className={classes.icon} />
                  </Avatar>
                }
              />
              <Chip
                label={`Invoice #${invoice ? invoice.id : ""}`}
                className={classes.backChip}
                variant="contained"
                color="primary"
                avatar={
                  <Avatar className={classes.avatar}>
                    <InsertDriveFile className={classes.icon} />
                  </Avatar>
                }
              />
            </Breadcrumbs>
          </Grid>
        }
      >
        {invoice ? (
          <Grid container style={{ flexGrow: 1 }} alignItems="stretch">
            <Grid
              item
              xs={12}
              md={6}
              style={{
                background: "#303030",
                height: "100%",
              }}
            >
              <PDF
                src={`${REACT_APP_API}/bazaar/invoices/${invoice.id}/preview`}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ overflow: "auto" }}>
              <Grid container style={{ padding: 8 }}>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                  <Paper
                    className="card"
                    style={{ color: "#3f51b5", height: "calc(100% - 90px)" }}
                  >
                    <Paper
                      className="cardHeader"
                      style={{ background: "#3f51b5" }}
                    >
                      <AccountBalanceWallet style={{ color: "white" }} />
                    </Paper>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Typography
                          display="block"
                          variant="h6"
                          color="inherit"
                        >
                          Total
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography display="block" variant="h4">
                          USD {formattedPrice(invoice && invoice.total)}
                        </Typography>
                        <Typography display="block" color="textSecondary">
                          Subtotal {formattedPrice(invoice && invoice.subTotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                  <Paper
                    className="card"
                    style={{ color: invoiceColor, height: "calc(100% - 90px)" }}
                  >
                    <Paper
                      className="cardHeader"
                      style={{ background: invoiceColor }}
                    >
                      {invoiceIcon}
                    </Paper>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Typography
                          display="block"
                          variant="h6"
                          color="inherit"
                        >
                          Status
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography display="block" variant="h4">
                          {invoiceStatus}
                        </Typography>
                        <Typography display="block" color="textSecondary">
                          {invoice && invoice.paid && invoice.lastPayment
                            ? moment(invoice.lastPayment.paymentDate)
                                .parseZone()
                                .fromNow()
                            : moment(invoice && invoice.dueDate).calendar()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{ padding: 8 }}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div>
                        <Typography display="block" variant="h6">
                          Customer
                        </Typography>
                        <Typography display="block" color="textSecondary">
                          Customer Billing Info
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <div>
                        <Typography display="block" variant="body2">
                          {customer && customer.companyName}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.email}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.phone}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.fullName}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.address1}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.address2}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.zip} {customer && customer.city}{" "}
                          {customer && customer.state}
                        </Typography>
                        <Typography display="block" variant="body2">
                          {customer && customer.country}
                        </Typography>
                      </div>
                    </ExpansionPanelDetails>
                    <Divider />
                  </ExpansionPanel>
                </Grid>
                {!invoice.paid ? (
                  <Grid item xs={12} style={{ padding: 8 }}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Pay Invoice
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container spacing={2}>
                            {cards &&
                              cards.map((card) => (
                                <Grid item key={card.id} xs={12}>
                                  <Card
                                    dardMode={app.darkMode}
                                    card={card}
                                    key={card.id}
                                    selected={
                                      selectedCard &&
                                      selectedCard.id === card.id
                                    }
                                    onSelect={(c) =>
                                      this.setState({ selectedCard: c })
                                    }
                                  />
                                </Grid>
                              ))}
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  this.setState({ paymentOpen: true })
                                }
                              >
                                <Payment style={{ marginRight: 8 }} /> Pay your
                                Invoice Now
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                ) : (
                  []
                )}
                {invoice && invoice.payment ? (
                  <Grid item xs={12}>
                    <PaymentsList
                      history={history}
                      simple
                      payments={[invoice.payment]}
                    />
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              <Grid item style={{ padding: 16 }}>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "none",
                  }}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_API}/bazaar/invoices/${invoice.id}/preview`,
                      "_blank"
                    )
                  }
                >
                  Download Invoice
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          []
        )}
        <Dialog
          open={paymentOpen}
          onClose={() => this.setState({ paymentOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {processing ? (
            <React.Fragment>
              <DialogTitle id="alert-dialog-title">Processing</DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <CircularProgress />
              </DialogContent>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DialogTitle id="alert-dialog-title">
                You are About to Pay the Invoice #{invoice && invoice.id}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  USD {formattedPrice(invoice && invoice.total)} will be charged
                  on your card ending with{" "}
                  {selectedCard && selectedCard.lastFour}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ paymentOpen: false })}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.pay.bind(this)}
                  color="primary"
                  variant="raised"
                  autoFocus
                >
                  Pay
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Dialog>
      </PageContainer>
    );
  }
}
export default withStyles(styles)(WrapperInvoicePage);
