import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CreditCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    credit: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes, credit } = this.props;

    return (
      <Paper className={classes.container}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            {credit &&
            credit.good &&
            credit.good.pictures &&
            credit.good.pictures.length ? (
              <img
                alt="Private Session"
                src={credit.good.pictures[0].url}
                height={50}
              />
            ) : (
              []
            )}
            <Typography display="block" variant="body2">
              {credit.good && credit.good.title}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {credit.good && credit.good.description}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Chip
              label={"Schedule Private Session"}
              onClick={() => {
                if (
                  credit.good &&
                  credit.good.title &&
                  credit.good &&
                  credit.good.title.indexOf("30") !== -1
                ) {
                  window.open(
                    "https://calendly.com/drdongreene/30min",
                    "_blank"
                  );
                } else {
                  window.open(
                    "https://calendly.com/drdongreene/60min",
                    "_blank"
                  );
                }
              }}
              color="primary"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CreditCard);
