import { networkAction } from "helpers/network/networkAction";

import {
  GET_QUESTIONS,
  GET_QUESTION_BY_ID,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  CREATE_QUESTION,
} from "constants/questions";

import * as Api from "api";

export const getQuestions = () => async (dispatch) =>
  networkAction(dispatch, GET_QUESTIONS, Api.getQuestions, []);

export const getQuestionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_QUESTION_BY_ID, Api.getQuestionById, [id]);

export const deleteQuestion = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_QUESTION, Api.deleteQuestion, [id]);

export const createQuestion = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_QUESTION, Api.createQuestion, [body]);

export const updateQuestion = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_QUESTION, Api.updateQuestion, [id, body]);
