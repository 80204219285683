import { CART } from "constants/routes";
import { checkAccess } from "helpers/auth";
import CartPage from "./container/CartPage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: CART,
    withSidebar: true,
    public: true,
    withAppBar: true,
    component: CartPage,
  };
}
