import { networkAction } from "helpers/network/networkAction";

import {
  GET_SURVEYS,
  GET_SURVEY_BY_ID,
  DELETE_SURVEY,
  UPDATE_SURVEY,
  CREATE_SURVEY,
  ANSWER_QUESTION,
  GET_PROFILE,
} from "constants/surveys";

import * as Api from "api";

export const getSurveys = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_SURVEYS, Api.getSurveys, [filters]);

export const getSurveyById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SURVEY_BY_ID, Api.getSurveyById, [id]);

export const getProfile = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROFILE, Api.getProfile, [id]);

export const answerQuestion = (body) => async (dispatch) =>
  networkAction(dispatch, ANSWER_QUESTION, Api.answerQuestion, [body]);

export const deleteSurvey = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SURVEY, Api.deleteSurvey, [id]);

export const createSurvey = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SURVEY, Api.createSurvey, [body]);

export const updateSurvey = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SURVEY, Api.updateSurvey, [id, body]);
