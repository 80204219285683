import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material-ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Email from "@material-ui/icons/EmailTwoTone";
import VisibilityOff from "@material-ui/icons/VisibilityOffTwoTone";
import Visibility from "@material-ui/icons/VisibilityTwoTone";
import withStyles from "@material-ui/styles/withStyles";
// custom
import { Copyright } from "components";
// config
import {
  REACT_APP_LOGO,
  RESET_PASSWORD_ENABLED,
  SIGN_UP_ENABLED,
} from "config";
import Card from "creativeTim/Card/Card";
import CardHeader from "creativeTim/Card/CardHeader";
import Header from "creativeTim/Header/Header";
// creativeTim components
import HeaderLinks from "creativeTim/Header/HeaderLinks";
// helpers
import { redirectAccordingToRole } from "helpers/redirect";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

const PADDING = 6;

class WrapperSigninPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,

    // Api
    signin: PropTypes.func,
    getCurrentUser: PropTypes.func,
  };

  static contextTypes = {
    openSignup: PropTypes.func,
    openForgotPassword: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      email: "",
      password: "",
      permanent: false,
      loading: false,
    };
  }

  async signin() {
    const { signin, history, getCurrentUser } = this.props;
    const { email, password, permanent } = this.state;

    const { localStorage } = window;
    const { redirectUrl } = localStorage;

    this.setState({ loading: true });

    delete localStorage.redirectUrl;

    const resp = await signin({
      email,
      password,
      permanent,
    });

    this.setState({ loading: false });

    if (resp.success) {
      setTimeout(() => getCurrentUser(), 200);
      if (redirectUrl) {
        if (redirectUrl.substring(0, 4) === "http") {
          window.location.replace(`${redirectUrl}`);
        } else {
          history.push(`${redirectUrl}`);
        }
      } else {
        if (resp.payload.defaultAppURL) {
          window.open(`https://${resp.payload.defaultAppURL}`);
        }

        history.push(
          `${redirectAccordingToRole(resp.role)}${window.location.search}`
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { email, password, permanent, showPassword, loading } = this.state;

    const { openSignup, openForgotPassword } = this.context;

    return (
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Helmet>
          <title>Signin</title>
          <meta name="Signin" content="Signin page" />
        </Helmet>
        <Header absolute color="transparent" rightLinks={<HeaderLinks />} />
        <Grid item xs={12}>
          <Zoom in>
            <div className={classes.zoomContainer}>
              <Card className={classes.card}>
                {loading ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                      padding: "60px 0px 60px 0px",
                    }}
                  >
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography display="block" variant="body2">
                        Loading ...
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    <form
                      onKeyPress={(e) => {
                        //eslint-disable-line
                        if (e.key === "Enter") {
                          this.signin();
                        }
                      }}
                    >
                      <CardHeader color="primary">
                        <img alt="logo" src={REACT_APP_LOGO} height="60" />
                      </CardHeader>
                      <div
                        style={{
                          overflow: "auto",
                          padding: "15px 30px 15px 30px",
                        }}
                      >
                        <Grid container justify="flex-start">
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="adornment-password">
                                Email
                              </InputLabel>
                              <Input
                                tabindex="1"
                                id="adornment-password"
                                type={"text"}
                                value={email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      tabindex="-1"
                                      edge="end"
                                      aria-label="Toggle password visibility"
                                      // onClick={() => this.setState({ showPassword: !showPassword })}
                                    >
                                      <Email />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="adornment-password">
                                Password
                              </InputLabel>
                              <Input
                                tabindex="2"
                                id="adornment-password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      tabindex="-1"
                                      edge="end"
                                      aria-label="Toggle password visibility"
                                      onClick={() =>
                                        this.setState({
                                          showPassword: !showPassword,
                                        })
                                      }
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item style={{ paddingTop: PADDING }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabindex="-1"
                                  checked={permanent}
                                  onChange={(e) =>
                                    this.setState({
                                      permanent: e.target.checked,
                                    })
                                  }
                                  value="permanent"
                                  color="primary"
                                />
                              }
                              label={
                                <Typography
                                  display="block"
                                  color="textSecondary"
                                >
                                  Remember Me
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.signin.bind(this)}
                            >
                              Login
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            {RESET_PASSWORD_ENABLED && (
                              <div
                                className={classes.iForgotButton}
                                onClick={openForgotPassword}
                              >
                                <Typography
                                  display="block"
                                  variant="caption"
                                  gutterBottom
                                >
                                  I Forgot my Password
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </div>
                )}
              </Card>
              {SIGN_UP_ENABLED && (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.signupButton}
                  onClick={() => openSignup()}
                >
                  Create an Account
                </Button>
              )}
            </div>
          </Zoom>
        </Grid>
        <Copyright />
      </Grid>
    );
  }
}

export default withStyles(styles)(WrapperSigninPage);
