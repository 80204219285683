import { GET, PUT, DELETE, POST } from "constants/methods";

const timezones = "/unity/timezones";

export function getTimezones() {
  const url = `${timezones}?all=true`;
  return {
    method: GET,
    url,
  };
}

export function getTimezoneById(id) {
  const url = `${timezones}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTimezone(id, body) {
  const url = `${timezones}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTimezone(id) {
  const url = `${timezones}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTimezone(body) {
  const url = `${timezones}`;
  return {
    method: POST,
    url,
    body,
  };
}
