export default (theme) => ({
  container: {
    padding: 5,
  },
  inputBase: {
    background: "rgba(155,155,155,0.1)",
    borderRadius: 4,
    color: theme.palette.type === "dark" ? "white" : "black",
    border: `solid 1px rgba(155,155,155,0.3)`,
    padding: "4px 8px",
  },
});
