import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Chart from "react-apexcharts";

// material-ui
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./styles";

class DatetimeGraph extends Component {
  static propTypes = {
    data: PropTypes.array,
    dataKey: PropTypes.string,
    dateKey: PropTypes.string,
    height: PropTypes.number,
    color: PropTypes.string,
    noHistory: PropTypes.bool,
    min: PropTypes.any,
    max: PropTypes.any,
    label: PropTypes.string,
  };

  state = {
    graph: 30,
  };

  getGraphConfig(range) {
    const {
      data,
      dataKey,
      dateKey,
      height,
      color,
      min,
      max,
      label,
    } = this.props;
    const graphData = [];
    const colors = [];

    const currentDay = moment();
    for (let index = 0; index < range; index++) {
      graphData.push([currentDay.toDate().getTime(), 0]);
      currentDay.subtract(1, "d");
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const now = moment(new Date()); // todays date
        const end = moment(element[dateKey]); // another date
        const duration = moment.duration(now.diff(end));
        const days = Math.round(duration.asDays());
        if (graphData[days]) {
          graphData[days][1] += element[dataKey] / 100;
          colors.push("#777777");
        }
      }
    }

    const options = {
      chart: {
        height: height || 200,
      },
      colors: [color || "#FF7F00"],
      stroke: {
        width: 0,
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: "solid",
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
        min,
        max,
      },
    };
    const series = [
      {
        name: label || "Usage",
        data: graphData,
      },
    ];

    const config = { options, series, type: "area" };
    return config;
  }

  render() {
    const { height, color, noHistory } = this.props;
    const { graph } = this.state;
    const config = this.getGraphConfig(graph);

    return (
      <div>
        <Chart
          options={config.options}
          series={config.series}
          type={config.type}
          height={height || 200}
        />
        {noHistory !== true ? (
          <Grid
            container
            spacing={1}
            style={{ paddingLeft: 20, color: color || "#FF7F00" }}
          >
            <Grid item>
              <Typography
                display="block"
                color={graph === 7 ? "inherit" : "textSecondary"}
                className="link"
                onClick={() => this.setState({ graph: 7 })}
              >
                Last Week
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                color={graph === 30 ? "inherit" : "textSecondary"}
                className="link"
                onClick={() => this.setState({ graph: 30 })}
              >
                Last Month
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                color={graph === 183 ? "inherit" : "textSecondary"}
                className="link"
                onClick={() => this.setState({ graph: 183 })}
              >
                Last 6 Months
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                color={graph === 365 ? "inherit" : "textSecondary"}
                className="link"
                onClick={() => this.setState({ graph: 365 })}
              >
                Last Year
              </Typography>
            </Grid>
          </Grid>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DatetimeGraph);
