import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Duo from "@material-ui/icons/DuoTwoTone";
import Market from "@material-ui/icons/Store";
import { withStyles } from "@material-ui/styles";
import EventBook from "components/EventBook";
import EventList from "components/EventList";
// material-ui
import Page from "components/Page";
// Custom
import TimeAssetList from "components/TimeAssetList";
// routes
import { MARKET } from "constants/routes";
// helpers
import { getState } from "helpers/calendy";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperEventsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    // urlParams: PropTypes.object,
    history: PropTypes.object,
    student: PropTypes.object,
    loading: PropTypes.bool,
    events: PropTypes.array,
    availabilities: PropTypes.array,
    timeAssets: PropTypes.array,
    coaches: PropTypes.array,
    eventTypes: PropTypes.array,
  };

  state = {
    hover: false,
    marketHover: false,
    eventBookOpen: false,
  };

  render() {
    const {
      loading,
      events,
      timeAssets,
      eventTypes,
      availabilities,
      coaches,
      classes,
      history,
      student,
    } = this.props;

    const { hover, marketHover, eventBookOpen } = this.state;

    const calendyState = getState(events, timeAssets, eventTypes);

    return (
      <Page
        helmet={"Private Sessions"}
        loading={loading}
        tabs={
          <Tabs
            value={0}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Private Sessions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <Grid
          container
          style={{ maxWidth: 1000, marginLeft: "auto", marginRight: "auto" }}
          spacing={2}
        >
          {calendyState.remaining > 0 ? (
            <Grid item>
              <Paper
                elevation={hover ? 10 : 0}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                onClick={() => this.setState({ eventBookOpen: true })}
                style={{
                  color: "white",
                  padding: 20,
                  borderRadius: 12,
                  cursor: "pointer",
                  transition: "box-shadow 0.4s",
                  background:
                    "linear-gradient(to bottom, #2196f3 0%, #1976d2 100%)",
                }}
              >
                <Grid container spacing={2} alignContent="center">
                  <Grid item>
                    <Duo style={{ fontSize: 50 }} color="inherit" />
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      color="inherit"
                      style={{ color: "rgba(255,255,255,0.56)" }}
                    >
                      Schedule a
                    </Typography>
                    <Typography display="block" variant="h6" color="inherit">
                      Private Session
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            []
          )}
          <Grid item>
            <Paper
              elevation={marketHover ? 10 : 0}
              onMouseEnter={() => this.setState({ marketHover: true })}
              onMouseLeave={() => this.setState({ marketHover: false })}
              onClick={() => history.push(`${MARKET}?id=1&index=2`)}
              style={{
                color: "white",
                padding: 20,
                borderRadius: 12,
                cursor: "pointer",
                transition: "box-shadow 0.4s",
                background:
                  "linear-gradient(to bottom, #8bc34a 0%, #689f38 100%)",
              }}
            >
              <Grid container spacing={2} alignContent="center">
                <Grid item>
                  <Market style={{ fontSize: 50 }} color="inherit" />
                </Grid>
                <Grid item>
                  <Typography
                    display="block"
                    color="inherit"
                    style={{ color: "rgba(255,255,255,0.56)" }}
                  >
                    Purchase
                  </Typography>
                  <Typography display="block" variant="h6" color="inherit">
                    Private Sessions
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TimeAssetList
              timeAssets={timeAssets}
              events={events}
              eventTypes={eventTypes}
            />
          </Grid>
          {events && events.length ? (
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Events
              </Typography>
              <EventList
                coaches={coaches}
                eventTypes={eventTypes}
                events={events || []}
                student={student}
                timeAssets={timeAssets || []}
                // onSelect={selectedEventType => history.push(`${EVENT_TYPE}?id=${selectedEventType.id}`)}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <EventBook
          open={eventBookOpen}
          close={() => this.setState({ eventBookOpen: false })}
          history={history}
          events={events || []}
          timeAssets={timeAssets || []}
          eventTypes={student.eventTypes}
          coaches={coaches || []}
          availabilities={availabilities || []}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventsPage);
