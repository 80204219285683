import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

// custom
import Shelve from "components/Shelve";

// styles
import styles from "./styles";

class Shelves extends Component {
  static propTypes = {
    shelves: PropTypes.object,
    cart: PropTypes.array,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
    history: PropTypes.object,
  };

  render() {
    const { shelves, updateCart, getCurrentCart, history, cart } = this.props;

    return (
      <div>
        <Grid container spacing={2}>
          {shelves &&
            shelves.map((shelve) => (
              <Grid key={shelve.id} item xs={12}>
                <Shelve
                  shelve={shelve}
                  history={history}
                  updateCart={updateCart}
                  getCurrentCart={getCurrentCart}
                  cart={cart}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Shelves);
