import { SURVEY } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import SurveyPage from "./container/SurveyPage";

export default function getSettingsRoute(userAuth) {
  const { account, harmony } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [harmony.name]: [harmony.roles.user, harmony.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SURVEY,
    withSidebar: false,
    withAppBar: false,
    component: SurveyPage,
  };
}
