// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    const { children, classes } = this.props;

    return <div className={classes.root}>{children}</div>;
  }
}

export default withStyles(styles)(Root);
