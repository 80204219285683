import { networkAction } from "helpers/network/networkAction";

import {
  GET_STUDENTS,
  GET_STUDENT_BY_ID,
  GET_CURRENT_STUDENT,
  GET_MY_STUDENT,
  DELETE_STUDENT,
  UPDATE_STUDENT,
  CREATE_STUDENT,
} from "constants/students";

import * as Api from "api";

export const getStudents = () => async (dispatch) =>
  networkAction(dispatch, GET_STUDENTS, Api.getStudents, []);

export const getMyStudent = () => async (dispatch) =>
  networkAction(dispatch, GET_MY_STUDENT, Api.getMyStudent, []);

export const getCurrentStudent = () => async (dispatch) =>
  networkAction(dispatch, GET_CURRENT_STUDENT, Api.getCurrentStudent, []);

export const getStudentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDENT_BY_ID, Api.getStudentById, [id]);

export const deleteStudent = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STUDENT, Api.deleteStudent, [id]);

export const createStudent = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STUDENT, Api.createStudent, [body]);

export const updateStudent = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STUDENT, Api.updateStudent, [id, body]);
