import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import MoneyAttach from "@material-ui/icons/AttachMoneyTwoTone";
import AutoRenew from "@material-ui/icons/AutorenewTwoTone";
import CreditCard from "@material-ui/icons/CreditCardTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import { withStyles } from "@material-ui/styles";
// reactor
import Page from "components/Page";
// constants
import { INVOICE, MY_ACCOUNT } from "constants/routes";
// helper
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperPaymentPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    cart: PropTypes.object,
    payment: PropTypes.object,
    loading: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, payment } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      refundCollapsed: false,
      ...payment,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      this.setState({ ...nextProps.payment });
    }
  }

  render() {
    const { classes, history, loading, payment } = this.props;

    const { message } = this.state;

    return (
      <Page
        helmet="Payment"
        loadingMessage={"Loading Payment"}
        loading={loading}
        header={
          <div className={classes.header}>
            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs separator="-" arial-label="Breadcrumb">
                  <Chip
                    onClick={() => history.push(`${MY_ACCOUNT}?index=2`)}
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back />
                      </Avatar>
                    }
                    variant="outlined"
                    className={classes.chip}
                    label="Back"
                  />
                  {payment && payment.invoiceID ? (
                    <Chip
                      label={`Invoice #${payment.invoiceID}`}
                      className={classes.chip}
                      onClick={() =>
                        history.push(`${INVOICE}?id=${payment.invoiceID}`)
                      }
                      variant="outlined"
                      avatar={
                        <Avatar className={classes.avatar}>
                          <InsertDriveFile className={classes.icon} />
                        </Avatar>
                      }
                    />
                  ) : (
                    []
                  )}
                  {/* <Chip
                    label={`Payment #${payment.id}`}
                    className={classes.chip}
                    variant="contained"
                    color="primary"
                    avatar={(
                      <Avatar className={classes.avatar}>
                        <InsertDriveFile className={classes.icon} />
                      </Avatar>
                    )}
                  /> */}
                </Breadcrumbs>
              </Grid>
            </Grid>
          </div>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            className={classes.container}
            alignItems="stretch"
          >
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#e91e63", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#e91e63" }}>
                  <CreditCard style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Status
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      {payment.paid ? "Paid" : "Rejected"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Transaction made{" "}
                      {moment(payment.paymentDate).parseZone().fromNow()} (
                      {moment(payment.paymentDate).calendar()})
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#9c27b0", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#9c27b0" }}>
                  <MoneyAttach style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Total
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      USD {payment.total ? formattedPrice(payment.total) : "-"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Using a Credit Card (Stripe)
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {payment.refunded ? (
              <Grid item xs={12} md={4}>
                <Paper
                  className="card"
                  style={{ color: "#2196f3", height: "calc(100% - 90px)" }}
                >
                  <Paper
                    className="cardHeader"
                    style={{ background: "#2196f3" }}
                  >
                    <AutoRenew style={{ color: "white" }} />
                  </Paper>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Typography display="block" variant="h6" color="inherit">
                        Refund
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography display="block" variant="h3">
                        Refunded
                      </Typography>
                      <Typography display="block" color="textSecondary">
                        Refund #{payment.refundID}
                      </Typography>
                      <Typography display="block" color="textSecondary">
                        {payment.refundReason}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : (
              []
            )}
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Payment Reference
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Transaction Date.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography display="block" variant="body2">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip
                          label={moment(payment.paymentDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            {payment.message ? (
              <Grid item xs={12}>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography display="block" variant="h6">
                        Message
                      </Typography>
                      <Typography display="block" color="textSecondary">
                        Admin message.
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div
                      style={{
                        width: "100%",
                        background: "rgba(255,193,7, 0.1)",
                        border: "solid 1px rgba(255,193,7, 0.3)",
                        padding: 6,
                      }}
                    >
                      <Typography display="block" variant="body2">
                        {message}
                      </Typography>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPaymentPage);
