import TableHead from "@material-ui/core/TableHead";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class RendTableHead extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    const { children } = this.props;

    return <TableHead>{children}</TableHead>;
  }
}

export default withStyles(styles)(RendTableHead);
