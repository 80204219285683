import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// actions
import * as Actions from "actions";

// helpers
import { getJsonFromUrl } from "helpers";

// component
import WrapperMyAccountPage from "../component/WrapperMyAccountPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  updateBazaarCustomer: (...args) =>
    dispatch(Actions.updateBazaarCustomer(...args)),
  getBazaarCurrentCustomer: (...args) =>
    dispatch(Actions.getBazaarCurrentCustomer(...args)),
  getCards: (...args) => dispatch(Actions.getCards(...args)),
  updateCard: (...args) => dispatch(Actions.updateCard(...args)),
  deleteCard: (...args) => dispatch(Actions.deleteCard(...args)),
  createCard: (...args) => dispatch(Actions.createCard(...args)),
  getPayments: (...args) => dispatch(Actions.getPayments(...args)),
  setCardAsFavorite: (...args) => dispatch(Actions.setCardAsFavorite(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
  updateUserPassword: (...args) =>
    dispatch(Actions.updateUserPassword(...args)),
  getBazaarCustomerFull: (...args) =>
    dispatch(Actions.getBazaarCustomerFull(...args)),
  getOrganizations: () => dispatch(Actions.getOrganizations()),
  getOrganizationById: (...args) =>
    dispatch(Actions.getOrganizationById(...args)),
  createOrganization: (...args) =>
    dispatch(Actions.createOrganization(...args)),
  deleteOrganization: (...args) =>
    dispatch(Actions.deleteOrganization(...args)),
  deleteOrganizationUser: (...args) =>
    dispatch(Actions.deleteOrganizationUser(...args)),
  updateOrganization: (...args) =>
    dispatch(Actions.updateOrganization(...args)),
  getOrganizationUsers: (...args) =>
    dispatch(Actions.getOrganizationUsers(...args)),
  addUserToOrganization: (...args) =>
    dispatch(Actions.addUserToOrganization(...args)),
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
});
class MyAccountPage extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    app: PropTypes.object,
    getCards: PropTypes.func,
    updateBazaarCustomer: PropTypes.func,
    deleteCard: PropTypes.func,
    createCard: PropTypes.func,
    updateCard: PropTypes.func,
    getPayments: PropTypes.func,
    payments: PropTypes.func,
    getBazaarCustomerFull: PropTypes.func,
    setCardAsFavorite: PropTypes.func,
    cards: PropTypes.array,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    getBazaarCurrentCustomer: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    getOrganizationById: PropTypes.func,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    joinOrganization: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const {
      getCards,
      getCurrentUser,
      getBazaarCurrentCustomer,
      getBazaarCustomerFull,
    } = this.props;

    const resps = await Promise.all([
      getCards(),
      getCurrentUser(),
      getBazaarCurrentCustomer(),
    ]);

    const resp = await getBazaarCustomerFull(resps[2].payload.id);

    this.setState({ loading: false, customer: resp.payload });
  }

  render() {
    const { urlParams, loading, customer } = this.state;

    const {
      user,
      history,
      updateUser,
      updateUserPassword,
      updateBazaarCustomer,
      cards,
      app,
      updateCard,
      deleteCard,
      createCard,
      setCardAsFavorite,
      getOrganizationById,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      addUserToOrganization,
      joinOrganization,
    } = this.props;

    return (
      <WrapperMyAccountPage
        history={history}
        updateCard={updateCard}
        deleteCard={deleteCard}
        createCard={createCard}
        setCardAsFavorite={setCardAsFavorite}
        customer={customer}
        updateCustomer={updateBazaarCustomer}
        refresh={this.refresh.bind(this)}
        user={user}
        cards={cards}
        updateUser={updateUser}
        updateUserPassword={updateUserPassword}
        urlParams={urlParams}
        loading={loading}
        app={app}
        organizations={user.organizations}
        getOrganizationById={getOrganizationById}
        createOrganization={createOrganization}
        deleteOrganization={deleteOrganization}
        updateOrganization={updateOrganization}
        getOrganizationUsers={getOrganizationUsers}
        deleteOrganizationUser={deleteOrganizationUser}
        addUserToOrganization={addUserToOrganization}
        joinOrganization={joinOrganization}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
