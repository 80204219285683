// material-ui
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import emoji from "node-emoji";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Text extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    const { children, classes } = this.props;

    return (
      <Typography color="inherit" display="inline" className={classes.font}>
        {children &&
          children.replace &&
          children.replace(/:\w+:/gi, (name) => emoji.get(name))}
      </Typography>
    );
  }
}

export default withStyles(styles)(Text);
