import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const factors = `/${service}/factors`;

export function getFactors() {
  const url = `${factors}`;
  return {
    method: GET,
    url,
  };
}

export function getFactorById(id) {
  const url = `${factors}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateFactor(id, body) {
  const url = `${factors}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteFactor(id) {
  const url = `${factors}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createFactor(body) {
  const url = `${factors}`;
  return {
    method: POST,
    url,
    body,
  };
}
