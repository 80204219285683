import * as Api from "api";
import {
  CREATE_ASSESSMENT_INQUIRY,
  DELETE_ASSESSMENT_INQUIRY,
  GET_ASSESSMENT_INQUIRIES,
  GET_ASSESSMENT_INQUIRY_BY_ID,
  UPDATE_ASSESSMENT_INQUIRY,
} from "constants/assessmentInquiries";
import { networkAction } from "helpers/network/networkAction";

export const getAssessmentInquiries = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_ASSESSMENT_INQUIRIES,
    Api.getAssessmentInquiries,
    []
  );

export const getAssessmentInquiryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_ASSESSMENT_INQUIRY_BY_ID,
    Api.getAssessmentInquiryById,
    [id]
  );

export const deleteAssessmentInquiry = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_ASSESSMENT_INQUIRY,
    Api.deleteAssessmentInquiry,
    [id]
  );

export const createAssessmentInquiry = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_ASSESSMENT_INQUIRY,
    Api.createAssessmentInquiry,
    [body]
  );

export const updateAssessmentInquiry = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_ASSESSMENT_INQUIRY,
    Api.updateAssessmentInquiry,
    [id, body]
  );
