export default (theme) => ({
  container: {
    flexGrow: 1,
    width: "100%",
    background: theme.palette.type === "dark" ? "#212121" : "#37474f",
  },
  drawer: {
    padding: 10,
    width: 300,
  },
});
