// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class InlineCode extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    const { children, classes } = this.props;

    return <span className={classes.inlineCode}>{children}</span>;
  }
}

export default withStyles(styles)(InlineCode);
