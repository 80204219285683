import {
  Grid,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import MoreVert from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
// material-ui
import { withStyles } from "@material-ui/styles";
import { FormEnrollment, FormSurvey } from "components";
import ChoiceModal from "components/ChoiceModal";
// reactor
import Page from "components/Page";
// custom
import Table from "components/TablePagination";
import { STUDENT } from "constants/routes";
import OrganizationInvite from "hooks/OrganizationInvite";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperStudentsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    loading: PropTypes.bool,
    user: PropTypes.object,
    currentOrganization: PropTypes.object,
    urlParams: PropTypes.object,
    createOrganizationUser: PropTypes.func,
    updateOrganizationUser: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    createEnrollment: PropTypes.func,
    courses: PropTypes.array,
    createSurvey: PropTypes.func,
    refresh: PropTypes.func,
    assessments: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      loading,
      history,
      currentOrganization,
      updateOrganizationUser,
      createOrganizationUser,
      deleteOrganizationUser,
      assessments,
      createEnrollment,
      courses,
      createSurvey,
      refresh,
      user,
    } = this.props;

    const { NotificationCenter } = this.context;

    return (
      <Page
        helmet="Students"
        loadingMessage={"Loading Students"}
        loading={loading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Chip
              label="Invite People"
              onClick={() => this.setState({ organizationInviteOpen: true })}
              icon={<SendIcon style={{ color: "white" }} />}
              style={{ background: "#607d8b", color: "white" }}
            />
            {assessments && assessments.length ? (
              <Chip
                label="Assign Assessment"
                style={{ marginLeft: 8 }}
                onClick={() => this.setState({ formSurveyOpen: true })}
              />
            ) : (
              []
            )}
            {courses && courses.length ? (
              <Chip
                label="Assign Course"
                style={{ marginLeft: 8 }}
                onClick={() => this.setState({ formEnrollmentOpen: true })}
              />
            ) : (
              []
            )}
          </Grid>
          <Grid item xs={12}>
            <Table
              data={currentOrganization && currentOrganization.users}
              meta={[
                {
                  path: "icon",
                  numeric: false,
                  width: 30,
                  render: (t, d) => (
                    <Avatar>
                      {d.firstName[0]}
                      {d.lastName[0]}{" "}
                    </Avatar>
                  ),
                },
                {
                  path: "id",
                  title: "Full Name",
                  numeric: false,
                  transform: (t, d) => `${d.firstName} ${d.lastName}`,
                },
                {
                  path: "email",
                  title: "Email",
                  numeric: false,
                },
                {
                  path: "pending",
                  title: "Status",
                  numeric: false,
                  transform: (t, d) => (d.pending ? "Pending" : "Active"),
                  render: (t, d) => (
                    <div>
                      {d.pending ? (
                        <Typography
                          variant="overline"
                          style={{
                            border: "solid 1px #ff9800",
                            padding: "2px 4px",
                            color: "#ff9800",
                            lineHeight: 1,
                          }}
                        >
                          Pending
                        </Typography>
                      ) : (
                        <Typography
                          variant="overline"
                          style={{
                            border: "solid 1px #4caf50",
                            padding: "2px 4px",
                            color: "#4caf50",
                            lineHeight: 1,
                          }}
                        >
                          Active
                        </Typography>
                      )}
                      {d.id === 0 ? (
                        <Typography
                          variant="overline"
                          style={{
                            border: "solid 1px #f44336",
                            padding: "2px 4px",
                            color: "#f44336",
                            lineHeight: 1,
                            marginLeft: 4,
                          }}
                        >
                          No Account
                        </Typography>
                      ) : (
                        []
                      )}
                    </div>
                  ),
                },
                {
                  path: "id",
                  title: "",
                  width: 30,
                  render: (t, d) => (
                    <InviteActions
                      d={d}
                      updateOrganizationUser={updateOrganizationUser}
                      deleteOrganizationUser={deleteOrganizationUser}
                      NotificationCenter={NotificationCenter}
                      refresh={refresh}
                      history={history}
                    />
                  ),
                },
              ]}
              onRowSelect={(c) => {
                if (c.id) history.push(`${STUDENT}?id=${c.id}`);
              }}
              title={"Students"}
            />
          </Grid>
        </Grid>
        <OrganizationInvite
          organization={currentOrganization}
          api={{
            createOrganizationUser,
          }}
          refresh={this.props.refresh}
          NotificationCenter={this.context.NotificationCenter}
          open={this.state.organizationInviteOpen}
          close={() => this.setState({ organizationInviteOpen: false })}
        />
        <FormSurvey
          open={this.state.formSurveyOpen}
          organizationID={user.organizationID}
          assessments={assessments}
          close={() => {
            this.setState({
              formSurveyOpen: false,
            });
          }}
          createSurvey={createSurvey}
          refresh={refresh}
        />
        <FormEnrollment
          open={this.state.formEnrollmentOpen}
          organizationID={user.organizationID}
          courses={courses}
          close={() => {
            this.setState({
              formEnrollmentOpen: false,
            });
          }}
          createEnrollment={createEnrollment}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStudentsPage);

const InviteActions = ({
  d,
  updateOrganizationUser,
  NotificationCenter,
  deleteOrganizationUser,
  refresh,
  history,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [choiceOpen, setChoiceOpen] = React.useState(false);

  const reinvite = (organizationUser, instantJoin) => {
    updateOrganizationUser(organizationUser.relationID, {
      instantJoin,
    });
    NotificationCenter.sweetAlert({
      title: "Invitation Sent!",
      success: true,
      timestamp: new Date().getTime(),
    });
    setTimeout(NotificationCenter.hide, 2000);
    refresh();
  };

  const removeFromOrganization = () => {
    deleteOrganizationUser(d.relationID).then((r) => {
      if (r.success) {
        NotificationCenter.sweetAlert({
          title: `${d.firstName} ${d.lastName} has been removed.`,
          success: true,
          timestamp: new Date().getTime(),
        });
        refresh();
        setTimeout(NotificationCenter.hide, 1500);
      }
    });
  };

  const requestDelete = () => {
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: `You are about to remove ${d.firstName} ${d.lastName} from the organization.`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: removeFromOrganization,
        },
      }
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MoreVert
        style={{ fontSize: 16 }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      />
      <Menu
        id="simple-menu"
        disablePortal
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled={d.id && !d.pending}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(null);
            setChoiceOpen(true);
          }}
        >
          <ListItemText primary="Invite Again" />
          <ListItemSecondaryAction>
            <SendIcon />
          </ListItemSecondaryAction>
        </MenuItem>
      </Menu>
      <ChoiceModal
        open={choiceOpen}
        close={() => setChoiceOpen(false)}
        title={"What would like to do?"}
        options={[
          {
            icon: <SendIcon />,
            color: "#2196f3",
            title: "Resend Invite",
            subtitle: "Let the user join by himself",
            callback: () => {
              reinvite(d, false);
              setChoiceOpen(false);
            },
          },
          {
            icon: <ControlPointIcon />,
            color: "#9e9e9e",
            title: "Force Join",
            subtitle: "Add the user to the org instantly",
            callback: () => {
              reinvite(d, true);
              setChoiceOpen(false);
            },
          },
        ]}
      />
    </div>
  );
};
