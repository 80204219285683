import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import { SURVEY } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SurveyCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    survey: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes, survey, history } = this.props;

    let label = "Start Assessment";

    if (survey.answersIdx > 0) {
      label = "Continue Assessment";
    }

    if (survey.answersIdx === survey.questionsCount) {
      label = "View Profile";
    }

    return (
      <Paper
        className={classes.container}
        style={{ borderLeft: `solid 3px ${survey.assessmentColor}` }}
      >
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Grid container justify="space-between">
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <img
                      alt="assessment icon"
                      className={classes.darkMode}
                      src={survey.assessmentIcon}
                      style={{
                        maxHeight: 45,
                        maxWidth: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="body2">
                      {survey.assessmentName}
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {survey.userFullName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {survey.percentage !== 0 ? (
                    <Grid item xs={12}>
                      <Typography display="block" variant="body2">
                        {survey.moduleName}
                      </Typography>
                      <Divider />
                      <Typography display="block" variant="body2">
                        {survey.lectureName}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography display="block" color="textSecondary">
                        You have not started the course yet.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: 10 }}>
            {(survey.answersIdx / survey.questionsCount) * 100 > 0 ? (
              <Typography display="block" variant="body2">
                Started {moment(survey.dateTaken).calendar()}
              </Typography>
            ) : (
              <Typography display="block" variant="body2">
                Not Started Yet
              </Typography>
            )}
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item style={{ color: survey.assessmentColor }}>
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    color="inherit"
                    value={(survey.answersIdx / survey.questionsCount) * 100}
                  />
                  <Typography
                    display="block"
                    variant="body2"
                    color="inherit"
                    className={classes.value}
                  >
                    {Math.round(
                      (survey.answersIdx / survey.questionsCount) * 100
                    )}
                    <span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Chip
                  label={label}
                  color="primary"
                  style={{ background: survey.assessmentColor }}
                  onClick={() => {
                    if (survey.assessmentID === 33) {
                      window.open(
                        `https://survey.winningonstage.com/assessments/${survey.id}`,
                        "_blank"
                      );
                    } else {
                      history.push(`${SURVEY}?id=${survey.id}`);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(SurveyCard);
