import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

// custom
import ShelveGoods from "components/ShelveGoods";

// styles
import styles from "./styles";

class Shelve extends Component {
  static propTypes = {
    classes: PropTypes.object,
    shelve: PropTypes.object,
    refresh: PropTypes.func,
    cart: PropTypes.array,
    history: PropTypes.object,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  render() {
    const {
      shelve,
      refresh,
      history,
      updateCart,
      getCurrentCart,
      cart,
    } = this.props;

    return (
      <div>
        <Grid container justify="space-between">
          <Grid item style={{ flexGrow: 1 }}>
            <Typography display="block" variant="h6">
              {shelve.name}
            </Typography>
            <Typography display="block" variant="subheading">
              {shelve.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <ShelveGoods
              history={history}
              goods={shelve.goods}
              refresh={refresh}
              updateCart={updateCart}
              getCurrentCart={getCurrentCart}
              cart={cart}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "10px 0px 0px 0px" }}>
            <Divider />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Shelve);
