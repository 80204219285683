import { GET_TEAM_USERS } from "constants/teams";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_TEAM_USERS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
