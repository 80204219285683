export default (theme) => ({
  h1: {
    fontSize: 28,
    fontWeight: 400,
    marginBottom: 25,
    marginTop: 25,
    opacity: 0.7,
    fontFamily: "merriweather, sans-serif !important",
  },
  h2: {
    fontSize: 26,
    fontWeight: 500,
    paddingBottom: 4,
    borderBottom: `1px solid ${
      theme.palette.type === "dark"
        ? "rgba(255,255,255,0.4)"
        : "rgba(0,0,0,0.4)"
    }`,
    width: "100%",
    display: "block",
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "merriweather, sans-serif !important",
  },
  h3: {
    fontSize: 24,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "merriweather, sans-serif !important",
  },
  h4: {
    fontSize: 18,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "merriweather, sans-serif !important",
  },
  h5: {
    fontSize: 16,
    marginBottom: 25,
    marginTop: 25,
    fontFamily: "merriweather, sans-serif !important",
  },
  h6: {
    fontSize: 14,
    color: theme.palette.text,
    marginBottom: 25,
    marginTop: 25,
    opacity: 0.5,
    fontFamily: "merriweather, sans-serif !important",
  },
});
