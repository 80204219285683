import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "calendy";
const coaches = `/${service}/coaches`;

export function getCoaches() {
  const url = `${coaches}`;
  return {
    method: GET,
    url,
  };
}

export function getDailyAvailabilities(
  coachID,
  date,
  format,
  combinedCoach,
  tzName
) {
  const url = `${coaches}/${coachID}/dailyAvailabilities?date=${date}&format=${format}&tzName=${tzName}${
    combinedCoach ? `&teacherID=${combinedCoach}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCoachById(id) {
  const url = `${coaches}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCoach(id, body) {
  const url = `${coaches}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCoach(id) {
  const url = `${coaches}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCoach(body) {
  const url = `${coaches}`;
  return {
    method: POST,
    url,
    body,
  };
}
