import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// helpers
import { getState } from "helpers/calendy";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EventBook extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    close: PropTypes.func,
    history: PropTypes.object,
    timeAssets: PropTypes.array,
    eventTypes: PropTypes.array,
    events: PropTypes.array,
    availabilities: PropTypes.array,
    coaches: PropTypes.array,
  };

  state = {
    selectedEventType: undefined,
    selectedCoach: undefined,
  };

  render() {
    const {
      timeAssets,
      open,
      close,
      eventTypes,
      events,
      coaches,
      availabilities,
      history,
    } = this.props;

    const { selectedEventType, selectedCoach } = this.state;

    const calendyState = getState(events, timeAssets, eventTypes);

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Schedule a Private Session
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <div
                style={{
                  background: "#9ccc65",
                  height: 15,
                  width: 15,
                  borderRadius: 15,
                  border: "solid 3px white",
                  boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                }}
              />
            </Grid>
            <Grid item>
              <Typography display="block" variant="body2">
                Remainig
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {selectedEventType ? (
                  <span>
                    <span style={{ textDecoration: "line-through" }}>
                      {`${calendyState.remaining} Minutes`}
                    </span>
                    {` ${
                      calendyState.remaining - selectedEventType.duration
                    } Minutes`}
                  </span>
                ) : (
                  `${calendyState.remaining} Minutes`
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="subtitle">
                <b>Event Type</b>
              </Typography>
              <Typography display="block" variant="body2">
                Select the type of event you wish to schedule:
              </Typography>
            </Grid>
            {eventTypes &&
              eventTypes.map((et) => {
                if (calendyState.remaining - et.duration >= 0) {
                  const coach = coaches.find((c) => c.id === et.coachID);
                  const teacher = et.teacherID
                    ? coaches.find((c) => c.id === et.teacherID)
                    : undefined;
                  const selected =
                    selectedEventType &&
                    `${selectedEventType.id}${selectedEventType.coachID}${selectedEventType.teacherID}` ===
                      `${et.id}${et.coachID}${et.teacherID}`;
                  return (
                    <Grid
                      item
                      onClick={() =>
                        this.setState({
                          selectedEventType: et,
                          selectedCoach: undefined,
                        })
                      }
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          background: selected
                            ? "rgba(33,150,243,0.1)"
                            : "rgba(155,155,155,0.1)",
                          border: selected
                            ? "solid 1px rgba(33,150,243,0.3)"
                            : "solid 1px rgba(155,155,155,0.3)",
                          padding: 12,
                          borderRadius: 6,
                          minWidth: 200,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: 80,
                            height: teacher ? 75 : undefined,
                          }}
                        >
                          <Avatar
                            src={coach && coach.avatar}
                            style={{
                              height: 60,
                              width: 60,
                              border: "solid 2px white",
                            }}
                          />
                          {teacher ? (
                            <Avatar
                              src={teacher && teacher.avatar}
                              style={{
                                height: 30,
                                width: 30,
                                border: "solid 2px white",
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                              }}
                            />
                          ) : (
                            []
                          )}
                        </div>
                        <Typography display="block" variant="h6">
                          {et.name}
                        </Typography>
                        {/* <Typography display="block" variant="body2">
                          Coach: {coach && coach.fullName}
                        </Typography> */}
                        {teacher ? (
                          <Typography display="block" variant="body2">
                            Teacher: {teacher && teacher.fullName}
                          </Typography>
                        ) : (
                          []
                        )}
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Duration: {et.duration} minutes
                        </Typography>
                      </div>
                    </Grid>
                  );
                }
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: "#e0e0e0",
              textTransform: "none",
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // history.push(
              //   `${SCHEDULE}?coachID=${selectedEventType.coachID}&eventTypeID=${selectedEventType.id}&teacherID=${selectedEventType.teacherID}`
              // )
              window.open(
                `https://calendly.com/drdongreene/${
                  selectedEventType.id === 2 ? "60" : "30"
                }min`,
                "_blank"
              );
            }}
            variant="contained"
            disabled={selectedEventType === undefined}
            style={{
              background: "#2196f3",
              color: "white",
              textTransform: "none",
              opacity: selectedEventType === undefined ? 0.3 : 1,
            }}
            color="primary"
            autoFocus
          >
            Select Date & Time
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EventBook);
