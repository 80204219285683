export default (theme) => ({
  container: {
    padding: 20,
  },
  input: {
    background: theme.palette.background.default,
    border: "solid 1px rgba(155,155,155, 0.4)",
    borderRadius: 4,
    padding: 6,
    margin: "6px 0px 6px 0px",
    width: "calc(100% - 20px)",
  },
});
