export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    borderColor: "white",
    color: "white",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
  },
  backChip: {
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing.unit * 1.5,
  },
  linearColorPrimary: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  linearBarColorPrimary: {
    backgroundColor: "white",
  },
  button: {
    textTransform: "none",
    width: "100%",
    background: "white",
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  darkMode: {
    background:
      theme.palette.type === "dark" ? "rgba(255,255,255,1)" : undefined,
    borderRadius: 4,
    padding: 4,
  },
  drawer: {
    // height: 'calc(100vh - 47px)',
    // marginTop: 47,
    width: 320,
    flex: 1,
    position: "absolute",
  },
  mobileDrawer: {
    width: 320,
  },
});
