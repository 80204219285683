// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ImgShadow extends Component {
  static propTypes = {
    classes: PropTypes.object,
    alt: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    elevation: PropTypes.number,
    top: PropTypes.number,
    opacity: PropTypes.number,
  };

  static defaultProps = {
    elevation: 10,
    top: 5,
    opacity: 1,
  };

  render() {
    const { alt, src, title, elevation, top, opacity } = this.props;

    return (
      <div style={{ position: "relative", margin: 18 }}>
        <img
          src={src}
          alt={"shadow"}
          style={{
            maxWidth: "calc(100% - 40px)",
            filter: `blur(${elevation}px)`,
            position: "absolute",
            borderRadius: 4,
            top,
            opacity,
            left: 0,
          }}
        />
        <img
          alt={alt}
          src={src}
          title={title}
          style={{
            maxWidth: "calc(100% - 40px)",
            borderRadius: 4,
          }}
        />
        <img
          alt={alt}
          src={src}
          title={title}
          style={{
            maxWidth: "calc(100% - 40px)",
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: 4,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ImgShadow);
