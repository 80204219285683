import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const questions = `/${service}/questions`;

export function getQuestions() {
  const url = `${questions}`;
  return {
    method: GET,
    url,
  };
}

export function getQuestionById(id) {
  const url = `${questions}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateQuestion(id, body) {
  const url = `${questions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteQuestion(id) {
  const url = `${questions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createQuestion(body) {
  const url = `${questions}`;
  return {
    method: POST,
    url,
    body,
  };
}
