import { networkAction } from "helpers/network/networkAction";

import {
  GET_COURSES,
  GET_COURSE_BY_ID,
  DELETE_COURSE,
  UPDATE_COURSE,
  CREATE_COURSE,
  GET_COURSE_PREVIEW,
} from "constants/courses";

import * as Api from "api";

export const getCourses = () => async (dispatch) =>
  networkAction(dispatch, GET_COURSES, Api.getCourses, []);

export const getCourseById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COURSE_BY_ID, Api.getCourseById, [id]);

export const getCoursePreview = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COURSE_PREVIEW, Api.getCoursePreview, [id]);

export const deleteCourse = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COURSE, Api.deleteCourse, [id]);

export const createCourse = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COURSE, Api.createCourse, [body]);

export const updateCourse = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COURSE, Api.updateCourse, [id, body]);
