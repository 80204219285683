import { Step, StepConnector, StepLabel, Stepper } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#ff9800",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#ff9800",
    },
  },
  line: {
    borderColor: "#bdbdbd",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#ff9800",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#ff9800",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default function EnrollmentProgression({ enrollment, history }) {
  const [activeStep, setActiveStep] = React.useState(1);
  const classes = useStyles(styles);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (module) => () => {
    history.push({
      search: `?id=${enrollment.id}&moduleID=${module.id}&lectureID=${module.lectures[0].id}`,
    });
    // setActiveStep(step);
  };

  return (
    <>
      <Stepper
        alternativeLabel
        style={{ background: "none" }}
        activeStep={enrollment.modules.findIndex(
          (m) => m.id === enrollment.module?.id
        )}
        connector={<QontoConnector />}
      >
        {enrollment.modules.map((m) => (
          <Step key={m.id}>
            <StepLabel
              onClick={handleStep(m)}
              style={{ cursor: "pointer" }}
              StepIconComponent={QontoStepIcon}
            >
              <span style={{ color: "white", fontSize: 12 }}>{m.name}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}
