import moment from "moment";

export function getState(events, timeAssets, eventTypes) {
  const today = moment();
  const totalDuration =
    (events &&
      events.reduce(
        (a, e) => (e.billableDuration ? Number(a + e.billableDuration) : a),
        0
      )) ||
    0;
  const totalAssets =
    (timeAssets &&
      timeAssets.reduce(
        (a, e) => (e.duration ? Number(a + e.duration) : a),
        0
      )) ||
    0;
  const totalSchedule =
    (events &&
      events.reduce((a, e) => {
        const dateFrom = moment(e.dateFromUTC);
        if (today.diff(dateFrom) <= 0) {
          return (
            a + eventTypes &&
            eventTypes.find((et) => et.id === e.eventTypeID).duration
          );
        }
        return a;
      }, 0)) ||
    0;
  const remaining = totalAssets - totalDuration - totalSchedule;

  return {
    totalDuration,
    totalAssets,
    totalSchedule,
    remaining,
  };
}
