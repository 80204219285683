import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Category from "@material-ui/icons/Category";
import Delete from "@material-ui/icons/Delete";
import Factor from "@material-ui/icons/FindReplace";
import Flip from "@material-ui/icons/Rotate90DegreesCcw";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Question extends Component {
  static propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    question: PropTypes.object,
    updateQuestion: PropTypes.func,
    isDragging: PropTypes.bool,
    setCategoryCallBack: PropTypes.func,
    clearCallback: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { question } = this.props;
    this.state = {
      ...question,
      loading: false,
      hover: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateQuestion, refresh, question } = this.props;

    if (question[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateQuestion(question.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async categoryCallback(selectedCategory) {
    const { updateQuestion, question, refresh, clearCallback } = this.props;
    await updateQuestion(question.id, { categoryID: selectedCategory.id });
    clearCallback();
    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteQuestion, refresh, question } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteQuestion(question.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Question has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      question,
      isDragging,
      setCategoryCallBack,
      disabled,
    } = this.props;
    const { name, loading, categoryID, hover } = this.state;

    let elevation = 1;
    let borderLeft;
    if (hover) {
      elevation = 3;
      borderLeft = "solid 3px #ff9800";
    }
    if (isDragging) {
      elevation = 5;
      borderLeft = "solid 3px #2196f3";
    }

    return (
      <Paper
        elevation={elevation}
        className={classes.container}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          borderLeft,
          opacity: loading ? 0.5 : 1,
        }}
      >
        <Grid container justify="space-between">
          <Grid item style={{ flexGrow: 1 }}>
            <Typography display="block" variant="body2">
              <b>Question {question.order + 1}</b>
            </Typography>
            <InputBase
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              disabled={loading || disabled}
              multiline
              onBlur={() => this.save("name")}
              className={hover ? classes.inputHover : classes.input}
              value={name}
              onChange={this.handleChange("name")}
            />
            <Divider />
            <br />
            {categoryID === null ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Chip
                    label="Choose Category"
                    disabled={disabled}
                    onClick={() =>
                      setCategoryCallBack(
                        this.categoryCallback.bind(this),
                        question
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Factor className={classes.darkModeFix} />
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        color="textSecondary"
                        variant="caption"
                      >
                        Factor
                      </Typography>
                      <Typography display="block" variant="body2">
                        {question.factorName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Category className={classes.darkModeFix} />
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        color="textSecondary"
                        variant="caption"
                      >
                        Category
                      </Typography>
                      <Typography display="block" variant="body2">
                        {question.categoryName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Chip
                    label="Change"
                    disabled={disabled}
                    onClick={() =>
                      setCategoryCallBack(
                        this.categoryCallback.bind(this),
                        question
                      )
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={"Delete Question"}>
              <IconButton
                disabled={disabled}
                size="small"
                onClick={this.delete.bind(this)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                question.flip
                  ? "Question flipped. Click to unflip."
                  : "Question not flipped. Click to flip."
              }
            >
              <IconButton
                size="small"
                disabled={disabled}
                color={question.flip ? "primary" : "default"}
                onClick={() => {
                  this.state.flip = !this.state.flip;
                  this.save("flip");
                }}
              >
                <Flip />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Question);
