import { networkAction } from "helpers/network/networkAction";

import {
  GET_MODULES,
  GET_MODULE_BY_ID,
  DELETE_MODULE,
  UPDATE_MODULE,
  CREATE_MODULE,
} from "constants/modules";

import * as Api from "api";

export const getModules = () => async (dispatch) =>
  networkAction(dispatch, GET_MODULES, Api.getModules, []);

export const getModuleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MODULE_BY_ID, Api.getModuleById, [id]);

export const deleteModule = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MODULE, Api.deleteModule, [id]);

export const createModule = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MODULE, Api.createModule, [body]);

export const updateModule = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MODULE, Api.updateModule, [id, body]);
