import { networkAction } from "helpers/network/networkAction";

import {
  GET_EVENT_TYPES,
  GET_EVENT_TYPE_BY_ID,
  DELETE_EVENT_TYPE,
  UPDATE_EVENT_TYPE,
  CREATE_EVENT_TYPE,
} from "constants/eventTypes";

import * as Api from "api";

export const getEventTypes = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_TYPES, Api.getEventTypes, []);

export const getEventTypeById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_TYPE_BY_ID, Api.getEventTypeById, [id]);

export const deleteEventType = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT_TYPE, Api.deleteEventType, [id]);

export const createEventType = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT_TYPE, Api.createEventType, [body]);

export const updateEventType = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT_TYPE, Api.updateEventType, [id, body]);
