import { networkAction } from "helpers/network/networkAction";

import {
  GET_TIMEZONES,
  GET_TIMEZONE_BY_ID,
  DELETE_TIMEZONE,
  UPDATE_TIMEZONE,
  CREATE_TIMEZONE,
} from "constants/timezones";

import * as Api from "api";

export const getTimezones = () => async (dispatch) =>
  networkAction(dispatch, GET_TIMEZONES, Api.getTimezones, []);

export const getTimezoneById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TIMEZONE_BY_ID, Api.getTimezoneById, [id]);

export const deleteTimezone = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TIMEZONE, Api.deleteTimezone, [id]);

export const createTimezone = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TIMEZONE, Api.createTimezone, [body]);

export const updateTimezone = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TIMEZONE, Api.updateTimezone, [id, body]);
