import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// material-ui
import withStyles from "@material-ui/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

// helpers
import { sortDown, sortUp, clone } from "helpers";

// styles
import styles from "./styles";

class TableWrapper extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    meta: PropTypes.array,
    onRowSelect: PropTypes.func,
    noPaper: PropTypes.bool,
    noHeader: PropTypes.bool,
    refreshKey: PropTypes.number,
    entryPerPage: PropTypes.number,
  };

  static defaultProps = {
    entryPerPage: 20,
  };

  constructor(...args) {
    super(...args);
    const { data } = this.props;
    this.state = {
      data,
      orderBy: undefined,
      order: undefined,
      searchContent: "",
      pageIndex: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });

    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({ pageIndex: 1 });
    }
  }

  getTableHead() {
    const { meta, classes } = this.props;
    const { orderBy, order } = this.state;

    return (
      <TableHead>
        <TableRow>
          {meta.map((m, k) => (
            <Hidden
              {...m.hidden}
              key={`TableCell_${k}`} //eslint-disable-line
            >
              <TableCell
                className={classNames(classes.head, classes.body)}
                style={{
                  width: m.width,
                }}
              >
                <Tooltip
                  title="Sort"
                  placement={m.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === m.path}
                    direction={order}
                    onClick={() => this.handleRequestSort(m)}
                  >
                    {m.title}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            </Hidden>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  getHighlightedText(text, searchContent) {
    if (text) {
      const search = `${text}`
        .toLowerCase()
        .search(searchContent.toLowerCase());
      if (search !== -1 && text && searchContent) {
        return (
          <div>
            <span>{text.substring(0, search)}</span>
            <span style={{ background: "#ffff00" }}>
              {text.substring(search, search + searchContent.length)}
            </span>
            <span>
              {text.substring(search + searchContent.length, text.length)}
            </span>
          </div>
        );
      }
    }

    return text === "null" ? "" : text;
  }

  getTableBody(data) {
    const { searchContent } = this.state;
    const { meta, onRowSelect, classes, rowStyle } = this.props;

    return (
      <TableBody>
        {data.map((d, k) => (
          <TableRow
            className={classes.tableRow}
            style={rowStyle ? rowStyle(d) : undefined}
            hover
            key={`TableRow_${k}`} //eslint-disable-line
            onClick={() => {
              if (onRowSelect) {
                onRowSelect(d, k);
              }
            }}
          >
            {meta.map((m, l) => (
              <Hidden
                {...m.hidden}
                key={`TableCell_${k}_${l}`} //eslint-disable-line
              >
                <TableCell
                  padding="dense"
                  numeric={m.numeric}
                  style={{
                    width: m.width,
                  }}
                >
                  {m.component === undefined ? (
                    <div>
                      {m.render
                        ? m.render(
                            this.getHighlightedText(
                              `${m.prefix || ""}${
                                m.transform
                                  ? m.transform(d[m.path], d)
                                  : d[m.path]
                              }${m.postfix || ""}`,
                              searchContent
                            ),
                            d,
                            Number(l)
                          )
                        : this.getHighlightedText(
                            `${m.prefix || ""}${
                              m.transform
                                ? m.transform(d[m.path], d)
                                : d[m.path]
                            }${m.postfix || ""}`,
                            searchContent
                          )}
                    </div>
                  ) : (
                    <m.component datum={d} {...m.inject} />
                  )}
                </TableCell>
              </Hidden>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  handleRequestSort(m) {
    const { data, order, orderBy } = this.state;
    let newOrder = "desc";

    if (orderBy === m.path && order === "desc") {
      newOrder = "asc";
    }

    const sortedData = data.sort((a, b) => {
      if (newOrder === "desc") {
        return sortDown(a, b, m.path, m.type);
      }

      return sortUp(a, b, m.path, m.type);
    });

    this.setState({
      data: sortedData,
      order: newOrder,
    });
  }

  handleSearch(searchContent, charAdded) {
    const { meta } = this.props;

    const data = charAdded
      ? clone(this.state.data) //eslint-disable-line
      : clone(this.props.data); //eslint-disable-line
    let searchData = [];

    if (searchContent && searchContent.length) {
      for (const k in data) {
        if (data.hasOwnProperty(k)) {
          const d = data[k];
          let match = false;
          for (const l in meta) {
            if (meta.hasOwnProperty(l)) {
              const m = meta[l];
              if (
                `${m.transform ? m.transform(d[m.path], d) : d[m.path]}`
                  .toLowerCase()
                  .search(searchContent.toLowerCase()) !== -1
              ) {
                match = true;
              }
            }
          }

          if (match) {
            searchData.push(d);
          }
        }
      }
    } else {
      searchData = data;
    }

    this.setState({
      searchContent,
      data: searchData,
      orderBy: undefined,
      order: undefined,
    });
  }

  render() {
    const { data, pageIndex } = this.state;
    const { classes, title, noPaper, noHeader, entryPerPage } = this.props;

    return (
      <Paper style={{ boxShadow: noPaper ? "none" : undefined }}>
        {noHeader !== true ? (
          <Toolbar variant="dense" className={classes.head}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography
                  display="block"
                  variant="h6"
                  id="tableTitle"
                  className={classes.paddingTop}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={data && Math.floor(data.length / entryPerPage) + 1}
                  page={pageIndex}
                  onChange={(e, v) => this.setState({ pageIndex: v })}
                />
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          []
        )}
        <Table size="small">
          {this.getTableHead()}
          {this.getTableBody(
            data.slice(
              (pageIndex - 1) * entryPerPage,
              (pageIndex - 1) * entryPerPage + entryPerPage
            )
          )}
        </Table>
        {noHeader !== true ? (
          <Toolbar variant="dense" className={classes.head}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Pagination
                  color="primary"
                  count={data && Math.floor(data.length / entryPerPage) + 1}
                  page={pageIndex}
                  onChange={(e, v) => this.setState({ pageIndex: v })}
                />
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          []
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(TableWrapper);
