import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// config
import {
  REACT_APP_API,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
  REACT_APP_FAVICON,
  REACT_APP_FRONT,
} from "config";
import { routeNames } from "constants/routeNames";
// routes
import { CART } from "constants/routes";
// helpers
import { setFavicon } from "helpers";
import { getCookie, removeCookie } from "helpers/cookie";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Routes from "routes";
import theme from "themes/theme";
// component
import WrapperRootPage from "../component/WrapperRootPage";

const mapStateToProps = (state) => ({
  errors: state.errors,
  app: state.app,
  user: state.user,
  userApps: state.userApps,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  checkAccess: (...args) => dispatch(Actions.checkAccess(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  getMyStudent: (...args) => dispatch(Actions.getMyStudent(...args)),
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  storeResetPasswordToken: (...args) =>
    dispatch(Actions.storeResetPasswordToken(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  requestResetPassword: (...args) =>
    dispatch(Actions.requestResetPassword(...args)),
  resetPassword: (...args) => dispatch(Actions.resetPassword(...args)),
  signup: (...args) => dispatch(Actions.signup(...args)),
  validateEmail: (...args) => dispatch(Actions.validateEmail(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  setOrganizationByDefault: (...args) =>
    dispatch(Actions.setOrganizationByDefault(...args)),
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
  getCurrentCart: (...args) => dispatch(Actions.getCurrentCart(...args)),
  deleteCart: (...args) => dispatch(Actions.deleteCart(...args)),
  validateCart: (...args) => dispatch(Actions.validateCart(...args)),
  updateCart: (...args) => dispatch(Actions.updateCart(...args)),
  stopImpersonate: (...args) => dispatch(Actions.stopImpersonate(...args)),
});

class RootPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    cookies: PropTypes.object,
    checkAccess: PropTypes.func,
    getCurrentUser: PropTypes.func,
    getUserApps: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,

    user: PropTypes.object,
    userApps: PropTypes.array,
    errors: PropTypes.object,
    app: PropTypes.object,

    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,

    stopImpersonate: PropTypes.func,
    getMyStudent: PropTypes.func,
    cart: PropTypes.object,
    getCurrentCart: PropTypes.func,
    updateCart: PropTypes.func,
    deleteCart: PropTypes.func,
    validateCart: PropTypes.func,
    updateCartGood: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
  };

  static childContextTypes = {
    checkAccess: PropTypes.func,
    userApps: PropTypes.array,
  };

  getChildContext() {
    const { checkAccess, userApps } = this.props;
    return {
      checkAccess,
      userApps,
    };
  }

  componentWillMount() {
    console.log(
      `%cDeveloped by ${REACT_APP_CLIENT_NAME}`,
      `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`
    );
    console.log(
      "%cin Los Angeles, California",
      "color: #607d8b; font-size: 18px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_EMAIL}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_WEBSITE}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%cRunning on ${REACT_APP_API} API`,
      "color: #607d8b; font-size: 12px"
    );
    setFavicon(REACT_APP_FAVICON);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.uuid && this.props.user.uuid === undefined) {
      setTimeout(this.purgeCart.bind(this), 200);
    }
  }

  getRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  getAllRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }

  purgeCart() {
    // replace API cart by the one set in cookie if it exists
    console.log("purgeCart");

    const {
      getCurrentCart,
      deleteCart,
      validateCart,
      updateCart,
      user,
    } = this.props;

    let cart;

    try {
      const cartCookie = getCookie("cart");
      if (cartCookie) {
        cart = JSON.parse(cartCookie);
      }
    } catch (error) {
      removeCookie("cart");
    }

    if (cart && cart.goods && cart.goods.length && user.uuid) {
      return new Promise(async (resolve) => {
        const resp1 = await getCurrentCart();
        const currentCart = resp1.payload;

        await deleteCart(currentCart.id);
        const resp2 = await getCurrentCart();
        const newCart = resp2.payload;
        const resp3 = await validateCart(cart);
        const newCartContent = resp3.payload;
        await updateCart(newCart.id, newCartContent);
        removeCookie("cart");
        window.location.replace(`${REACT_APP_FRONT}${CART}`);
        resolve();
      });
    }
  }

  init() {
    const { getUserApps, getCurrentCart, getMyStudent } = this.props;
    return Promise.all([getCurrentCart(), getUserApps(), getMyStudent()]);
  }

  render() {
    const {
      app,
      user,
      getCurrentUser,
      checkAccess,
      signout,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      resetPassword,
      setDarkMode,
      userApps,
      stopImpersonate,
      history,
      cart,
      setOrganizationByDefault,
      joinOrganization,
    } = this.props;

    let muiTheme = createMuiTheme({
      palette: { ...theme, type: this.props.app.darkMode ? "dark" : "light" },
      overrides: {
        MuiTypography: {
          // Name of the component ⚛️ / style sheet
          root: {
            // Name of the rule
            color: this.props.app.darkMode ? "white" : "black", // Some CSS
          },
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    });

    muiTheme = responsiveFontSizes(muiTheme);

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router>
          <WrapperRootPage
            stopImpersonate={stopImpersonate}
            setDarkMode={setDarkMode}
            app={app}
            checkAccess={checkAccess}
            getCurrentUser={getCurrentUser}
            storeResetPasswordToken={storeResetPasswordToken}
            signout={signout}
            requestResetPassword={requestResetPassword}
            resetPassword={resetPassword}
            validateEmail={validateEmail}
            signup={signup}
            user={user}
            userApps={userApps}
            errors={errors}
            routes={this.getRoutes()}
            allRoutes={this.getAllRoutes()}
            init={this.init.bind(this)}
            setOrganizationByDefault={setOrganizationByDefault}
            joinOrganization={joinOrganization}
            history={history}
            cart={cart}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(RootPage)
);
