import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// material-ui
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

// components
import Timezone from "components/Timezone";

// styles
import styles from "./styles";
import { ClickAwayListener, Popper, Paper } from "@material-ui/core";

class TimezoneSelect extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timezones: PropTypes.array,
    tzName: PropTypes.string,
    onChange: PropTypes.func,
  };

  state = {
    hover: false,
    anchorEl: null,
  };

  getData() {
    const { timezones } = this.props;
    const data = [];

    for (const key in timezones) {
      if (timezones.hasOwnProperty(key)) {
        const timezone = timezones[key];
        data.push({
          label: `(GMT${timezone.utc}) ${timezone.display}`,
          value: timezone.name,
        });
      }
    }

    return data;
  }

  render() {
    const { tzName, onChange, timezones } = this.props;
    const { hover, anchorEl } = this.state;

    return (
      <div>
        <div
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
          style={{
            background: hover ? "rgba(155,155,155,0.1)" : undefined,
            cursor: "pointer",
          }}
        >
          <Typography display="block" variant="caption">
            <Timezone tzName={tzName} timezones={timezones} /> ▾
          </Typography>
        </div>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener
            onClickAway={() => this.setState({ anchorEl: null })}
          >
            <Paper style={{ width: 400 }}>
              <Select
                className="basic-single"
                placeholder="Select the correct timezone"
                classNamePrefix="select"
                defaultValue={tzName}
                isClearable
                isSearchable
                name="tzName"
                onChange={(i) => {
                  onChange(i.value);
                  this.setState({ anchorEl: null });
                }}
                options={this.getData()}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(TimezoneSelect);
