import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "calendy";
const availabilities = `/${service}/availabilities`;

export function getAvailabilities(filters) {
  const url = `${availabilities}${
    filters ? `?filters=${JSON.stringify(filters)}&limit=100` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAvailabilityById(id) {
  const url = `${availabilities}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateAvailability(id, body) {
  const url = `${availabilities}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAvailability(id) {
  const url = `${availabilities}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAvailability(body) {
  const url = `${availabilities}`;
  return {
    method: POST,
    url,
    body,
  };
}
