import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const teamMembers = `/${service}/teamMembers`;

export function getTeamMembers() {
  const url = `${teamMembers}`;
  return {
    method: GET,
    url,
  };
}

export function getTeamMemberById(id) {
  const url = `${teamMembers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTeamMember(id, body) {
  const url = `${teamMembers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTeamMember(id) {
  const url = `${teamMembers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTeamMember(body) {
  const url = `${teamMembers}`;
  return {
    method: POST,
    url,
    body,
  };
}
