import * as Api from "api";
import {
  CREATE_INQUIRY,
  DELETE_INQUIRY,
  GET_INQUIRIES,
  GET_INQUIRY_BY_ID,
  UPDATE_INQUIRY,
} from "constants/inquiries";
import { networkAction } from "helpers/network/networkAction";

export const getInquiries = () => async (dispatch) =>
  networkAction(dispatch, GET_INQUIRIES, Api.getInquiries, []);

export const getInquiryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INQUIRY_BY_ID, Api.getInquiryById, [id]);

export const deleteInquiry = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_INQUIRY, Api.deleteInquiry, [id]);

export const createInquiry = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_INQUIRY, Api.createInquiry, [body]);

export const updateInquiry = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_INQUIRY, Api.updateInquiry, [id, body]);
