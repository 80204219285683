import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperEnrollmentPage from "../component/WrapperEnrollmentPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  enrollments: state.enrollments,
});

const mapDispatchToProps = (dispatch) => ({
  getEnrollments: () => dispatch(Actions.getEnrollments()),
  getEnrollmentById: (...args) => dispatch(Actions.getEnrollmentById(...args)),
  createEnrollment: (...args) => dispatch(Actions.createEnrollment(...args)),
  deleteEnrollment: (...args) => dispatch(Actions.deleteEnrollment(...args)),
  updateEnrollment: (...args) => dispatch(Actions.updateEnrollment(...args)),
});
class EnrollmentPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    enrollments: PropTypes.array,
    getEnrollments: PropTypes.func,
    getEnrollmentById: PropTypes.func,
    createEnrollment: PropTypes.func,
    deleteEnrollment: PropTypes.func,
    updateEnrollment: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, enrollments } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: enrollments.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getEnrollments } = this.props;
    await Promise.all([getEnrollments()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      enrollments,
      getEnrollmentById,
      createEnrollment,
      deleteEnrollment,
      updateEnrollment,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperEnrollmentPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        enrollments={enrollments}
        history={history}
        getEnrollmentById={getEnrollmentById}
        createEnrollment={createEnrollment}
        deleteEnrollment={deleteEnrollment}
        updateEnrollment={updateEnrollment}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentPage);
