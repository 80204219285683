import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import { withStyles } from "@material-ui/styles";

// custom
import Event from "components/Event";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class EventsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    events: PropTypes.array,
    coaches: PropTypes.array,
    eventTypes: PropTypes.array,
    onSelect: PropTypes.func,
    updateEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    history: PropTypes.object,
    student: PropTypes.object,
    refresh: PropTypes.func,
  };

  state = {
    selection: "upcoming",
  };

  render() {
    const {
      events,
      classes,
      onSelect,
      updateEvent,
      student,
      history,
      coaches,
      eventTypes,
      deleteEvent,
      refresh,
    } = this.props;

    const { selection } = this.state;

    const es = events
      .filter((e) => {
        const today = moment();
        const dateFrom = moment(e.dateFromUTC);
        if (selection === "upcoming") {
          return today.diff(dateFrom) <= 0;
        }

        return today.diff(dateFrom) > 0;
      })
      .sort((e) => {
        const today = moment();
        const dateFrom = moment(e.dateFromUTC);
        return today.diff(dateFrom);
      });

    let dateCursor;
    return (
      <div
        style={{
          width: "100%",
          maxWidth: 1000,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container spacing={1} style={{ paddingBottom: 8 }}>
          <Grid item>
            <Chip
              variant={selection === "upcoming" ? "filled" : "outlined"}
              label={"Upcoming"}
              color={selection === "upcoming" ? "primary" : undefined}
              onClick={() => this.setState({ selection: "upcoming" })}
            />
          </Grid>
          <Grid item>
            <Chip
              variant={selection === "past" ? "filled" : "outlined"}
              label={"Past"}
              color={selection === "past" ? "primary" : undefined}
              onClick={() => this.setState({ selection: "past" })}
            />
          </Grid>
        </Grid>
        {es.length === 0 ? (
          <Typography display="block" color="textSecondary">
            No {selection} sessions
          </Typography>
        ) : (
          []
        )}
        {es.map((e) => {
          const date = moment(e.dateFromUTC);
          const JSX = [];
          if (
            dateCursor === undefined ||
            date.format("YYYY/MM/DD") !== dateCursor.format("YYYY/MM/DD")
          ) {
            dateCursor = date.clone();
            JSX.push(
              <div className={classes.listHeader}>
                <Typography display="block" color="textSecondary">
                  {date.format("ll")}
                </Typography>
              </div>
            );
          }
          JSX.push(
            <Event
              key={e.id}
              event={{
                ...e,
                eventType: eventTypes.find((et) => et.id === e.eventTypeID),
                coach: coaches.find((c) => c.id === e.coachID),
                student,
              }}
              history={history}
              updateEvent={updateEvent}
              deleteEvent={deleteEvent}
              refresh={refresh}
            />
          );
          return JSX;
        })}
      </div>
    );
  }
}

export default withStyles(styles)(EventsList);
