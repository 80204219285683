import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import Money from "@material-ui/icons/AttachMoneyTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GraphicEq from "@material-ui/icons/GraphicEqTwoTone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import Usage from "@material-ui/icons/VerticalAlignBottomTwoTone";
import { withStyles } from "@material-ui/styles";
import CouponsList from "components/CouponsList";
import DatetimeGraph from "components/DatetimeGraph";
// reactor
import Page from "components/Page";
import UsagesList from "components/UsagesList";
// constants
import { INVOICE, MY_ACCOUNT } from "constants/routes";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperStatementPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    statement: PropTypes.object,
    refresh: PropTypes.func,
    generateInvoice: PropTypes.func,
    loading: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  async generateInvoice() {
    const { generateInvoice, statement, history } = this.props;
    const resp = await generateInvoice(statement.id);
    if (resp.success) {
      history.push(`${INVOICE}?id=${resp.payload.id}`);
    }
  }

  render() {
    const { classes, history, loading, statement } = this.props;

    let status = "Current";
    if (statement && statement.invoiceID) status = "Invoiced";
    if (statement && statement.invoice && statement.invoice.paid)
      status = "Paid";

    return (
      <Page
        helmet="Statement"
        loadingMessage={"Loading Statement"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => history.push(`${MY_ACCOUNT}?index=0`)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {statement && statement.invoiceID ? (
                  <Chip
                    label={`Invoice #${statement.invoiceID}`}
                    className={classes.chip}
                    onClick={() =>
                      history.push(`${INVOICE}?id=${statement.invoiceID}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <InsertDriveFile className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
                <Chip
                  label={`Statement #${statement ? statement.id : ""}`}
                  className={classes.chip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <GraphicEq className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
      >
        <Grid container style={{ maxWidth: 1200, margin: "auto" }} spacing={2}>
          <Grid xs={12}>
            <Typography display="block" variant="h3">
              {statement
                ? moment(statement.creationDate).format("MMMM YYYY")
                : "-"}{" "}
              Statement
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              className="card"
              style={{ color: "#ffc107", height: "calc(100% - 90px)" }}
            >
              <Paper className="cardHeader" style={{ background: "#ffc107" }}>
                <Usage style={{ color: "white" }} />
              </Paper>
              <Grid container justify="flex-end">
                <Grid item>
                  <Typography display="block" variant="h6" color="inherit">
                    Usage
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography display="block" variant="h4">
                    {statement && statement.usages
                      ? `${statement.usages.length} Usages`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              className="card"
              style={{ color: "#ff9800", height: "calc(100% - 90px)" }}
            >
              <Paper className="cardHeader" style={{ background: "#ff9800" }}>
                <Money style={{ color: "white" }} />
              </Paper>
              <Grid container justify="flex-end">
                <Grid item>
                  <Typography display="block" variant="h6" color="inherit">
                    Current Total
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography display="block" variant="h4">
                    {statement ? `USD ${formattedPrice(statement.total)}` : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              className="card"
              style={{ color: "#ff5722", height: "calc(100% - 90px)" }}
            >
              <Paper className="cardHeader" style={{ background: "#ff5722" }}>
                <InsertDriveFile style={{ color: "white" }} />
              </Paper>
              <Grid container justify="flex-end">
                <Grid item>
                  <Typography display="block" variant="h6" color="inherit">
                    Status
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography display="block" variant="h4">
                    {status}
                  </Typography>
                  <Typography display="block" color="textSecondary">
                    {statement &&
                    statement.invoice &&
                    statement.invoice.creationDate
                      ? `Invoiced on ${moment(
                          statement.invoice.creationDate
                        ).calendar()}`
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <DatetimeGraph
                noHistory
                label="Payroll Form(s)"
                min={
                  statement
                    ? moment(statement.creationDate).toDate().getTime()
                    : undefined
                }
                max={
                  statement
                    ? moment(statement.creationDate)
                        .add("months", 1)
                        .subtract("days", 1)
                        .toDate()
                        .getTime()
                    : undefined
                }
                data={statement && statement.usages ? statement.usages : []}
                height={200}
                dateKey={"date"}
                dataKey={"quantity"}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography display="block" variant="h6">
                    Usages
                  </Typography>
                  <Typography display="block" color="textSecondary">
                    View all Records.
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <div style={{ width: "100%" }}>
                  <UsagesList usages={statement ? statement.usages : []} />
                </div>
              </ExpansionPanelDetails>
              <Divider />
            </ExpansionPanel>
          </Grid>
          {statement && statement.coupons && statement.coupons.length ? (
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Coupons
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      View Coupons Related to the Statement.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div style={{ width: "100%" }}>
                    <CouponsList
                      coupons={statement ? statement.coupons : []}
                      history={history}
                      noPaper
                      noTitle
                    />
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
          ) : (
            []
          )}
          {/* {
            true
              ? (
                <Grid item xs={12}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div>
                        <Typography display="block" variant="h6">
                          Generate Invoice
                        </Typography>
                        <Typography display="block" color="textSecondary">
                          Create an Invoice related to your Latest Usages.
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <div style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          onClick={this.generateInvoice.bind(this)}
                        >
                          Generate Invoice
                        </Button>
                      </div>
                    </ExpansionPanelDetails>
                    <Divider />
                  </ExpansionPanel>
                </Grid>
              ) : []
          } */}
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStatementPage);
