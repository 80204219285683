import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const overflow = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

class Calendar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    isAvailable: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const tzName = "America/Los_Angeles";
    this.state = {
      now: moment.tz(tzName),
      monthCursor: moment.tz(tzName),
    };
  }

  nextMonth() {
    this.setState((prevState) => ({
      monthCursor: prevState.monthCursor.add(1, "month"),
    }));
  }

  prevMonth() {
    this.setState((prevState) => ({
      monthCursor: prevState.monthCursor.subtract(1, "month"),
    }));
  }

  render() {
    const { classes, isAvailable, onSelect, selected } = this.props;
    const { monthCursor, now } = this.state;

    const BEGIN_MONTH_BLANK = [];
    const MONTH_DAYS = [];

    const daysInMonth = monthCursor.daysInMonth();
    const firstDayOfMonth = monthCursor.clone().startOf("month");
    let dayCursor = firstDayOfMonth;

    for (
      let index = 0;
      index < overflow[firstDayOfMonth.format("dddd")];
      index++
    ) {
      BEGIN_MONTH_BLANK.push(
        <div className={classes.flex}>
          <IconButton disabled style={{ width: 30, height: 30, padding: 0 }} />
        </div>
      );
    }

    for (let index = 0; index < daysInMonth; index++) {
      const isPast = dayCursor.isBefore(now);
      let available = true;
      if (isAvailable) {
        available = isAvailable(dayCursor);
      }

      // console.log(`${dayCursor.format('DD/MM')} isBefore ${now.format('DD/MM')} = ${dayCursor.isBefore(now)}`);
      if (dayCursor.format("dddd") === "Sunday") {
        MONTH_DAYS.push(<div style={{ flexBasis: "100%" }} />);
      }
      const a = dayCursor.clone();
      const selectable = !isPast && available;
      let sameDay = false;
      if (selected) {
        sameDay = dayCursor.isSame(selected, "day");
      }
      let background;
      let color;
      if (selectable) {
        background = "rgba(255,152,0, 0.1)";
        color = "#ff9800";
      }
      if (sameDay) {
        background = "#ff9800";
        color = "white";
      }
      MONTH_DAYS.push(
        <div className={classes.flex}>
          <IconButton
            disabled={isPast || !selectable}
            onClick={() => onSelect(a)}
            style={{
              width: 40,
              height: 40,
              padding: 0,
              background,
              color,
            }}
          >
            <Typography display="block" color="inherit">
              {dayCursor.format("D")}
            </Typography>
          </IconButton>
        </div>
      );
      dayCursor = dayCursor.clone().add(1, "day");
    }

    return (
      <div style={{ width: 400 }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            color: "#ff9800",
          }}
        >
          <div className={classes.flex} style={{ flex: 1 }}>
            <Typography display="block" variant="body2">
              {monthCursor.format("MMMM YYYY")}
            </Typography>
          </div>
          <div>
            <IconButton
              color="inherit"
              disabled={monthCursor.format("MM/YYYY") === now.format("MM/YYYY")}
              onClick={this.prevMonth.bind(this)}
            >
              <NavigateBefore />
            </IconButton>
            <IconButton color="inherit" onClick={this.nextMonth.bind(this)}>
              <NavigateNext />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div style={{ flexBasis: "100%" }} />
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              SUN
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              MON
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              TUE
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              WED
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              THU
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              FRI
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography display="block" color="textSecondary">
              SAT
            </Typography>
          </div>
          <div style={{ flexBasis: "100%" }} />
          {BEGIN_MONTH_BLANK}
          {MONTH_DAYS}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
