import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperTeamPage from "../component/WrapperTeamPage";

const mapStateToProps = (state) => ({
  teamUsers: state.teamUsers,
  assessments: state.assessments,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamById: (...args) => dispatch(Actions.getTeamById(...args)),
  createTeamMember: (...args) => dispatch(Actions.createTeamMember(...args)),
  deleteTeamMember: (...args) => dispatch(Actions.deleteTeamMember(...args)),
  updateTeamMember: (...args) => dispatch(Actions.updateTeamMember(...args)),
  updateTeam: (...args) => dispatch(Actions.updateTeam(...args)),
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  getTeamUsers: (...args) => dispatch(Actions.getTeamUsers(...args)),
});
class TeamPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    updateTeamMember: PropTypes.func,
    createTeamMember: PropTypes.func,
    deleteTeamMember: PropTypes.func,
    updateTeam: PropTypes.func,
    getTeamUsers: PropTypes.func,
    getAssessments: PropTypes.func,
    teamUsers: PropTypes.array,
    assessments: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, getTeamUsers, getAssessments } = this.props;
    const urlParams = getJsonFromUrl(location);
    getAssessments();
    getTeamUsers(urlParams.id);
    this.state = {
      urlParams,
      teamID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { teamID } = this.state;
    const { getTeamById } = this.props;

    const resp = await getTeamById(teamID);
    this.setState({ team: resp.payload, loading: false });
  }

  render() {
    const {
      location,
      history,
      updateTeamMember,
      createTeamMember,
      deleteTeamMember,
      updateTeam,
      teamUsers,
      assessments,
    } = this.props;

    const { urlParams, loading, team } = this.state;

    return (
      <WrapperTeamPage
        history={history}
        location={location}
        urlParams={urlParams}
        team={team}
        loading={loading}
        refresh={this.refresh.bind(this)}
        assessments={assessments}
        updateTeamMember={updateTeamMember}
        createTeamMember={createTeamMember}
        deleteTeamMember={deleteTeamMember}
        updateTeam={updateTeam}
        teamUsers={teamUsers}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
