import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const profiles = `/${service}/profiles`;

export function getProfiles() {
  const url = `${profiles}`;
  return {
    method: GET,
    url,
  };
}

export function getProfileById(id) {
  const url = `${profiles}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProfile(id, body) {
  const url = `${profiles}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProfile(id) {
  const url = `${profiles}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProfile(body) {
  const url = `${profiles}`;
  return {
    method: POST,
    url,
    body,
  };
}
