import { META_ONLY } from "constants/methods";
import { REACT_APP_FRONT_BASE } from "config";

import * as Routes from "routes";
import { routeNames } from "constants/routeNames";

import { errorNotification } from "actions/errors";
import Fetcher from "helpers/network/network";

const fetcher = new Fetcher();

function getRoutes() {
  const routes = [];

  for (const k in routeNames) {
    if (routeNames.hasOwnProperty(k)) {
      const route = Routes[routeNames[k]]({});
      if (route.onEnter()) {
        routes.unshift(route);
      }
    }
  }

  return routes;
}

export async function networkAction(dispatch, action, apiCall, args) {
  let call;
  if (args[0] === META_ONLY) {
    call = apiCall("");
    return call;
  }

  call = apiCall(...args);

  dispatch({ type: `${action}_LOADING` });
  const resp = await fetcher[call.method]({ url: call.url, body: call.body });
  let json;
  try {
    json = await resp.json();
  } catch (e) {
    console.log(e);
  }

  if (resp.status === 401) {
    const routes = getRoutes();
    const path = window.location.pathname;
    const currentRoute = routes.find((e) => e.path === path);
    if (currentRoute && currentRoute.public) return;
    const uri = window.location.href;
    const uriEncoded = encodeURIComponent(uri);

    window.location.replace(
      `${REACT_APP_FRONT_BASE}/signin?redirectTo=${uriEncoded}`
    );
  }

  if (resp.status !== 200 && resp.status !== 204) {
    dispatch(errorNotification(json));
    const ac = {
      type: `${action}_ERROR`,
      payload: json,
      success: false,
    };
    dispatch(ac);
    return ac;
  }

  const ac = {
    type: `${action}_SUCCESS`,
    payload: json,
    success: true,
  };
  dispatch(ac);
  return ac;
}
