import { networkAction } from "helpers/network/networkAction";

import {
  GET_MEDIAS,
  GET_MEDIA_BY_ID,
  DELETE_MEDIA,
  UPDATE_MEDIA,
  CREATE_MEDIA,
} from "constants/medias";

import * as Api from "api";

export const getMedias = () => async (dispatch) =>
  networkAction(dispatch, GET_MEDIAS, Api.getMedias, []);

export const getMediaById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MEDIA_BY_ID, Api.getMediaById, [id]);

export const deleteMedia = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MEDIA, Api.deleteMedia, [id]);

export const createMedia = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MEDIA, Api.createMedia, [body]);

export const updateMedia = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MEDIA, Api.updateMedia, [id, body]);
