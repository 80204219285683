import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperEventsPage from "../component/WrapperEventsPage";

const mapStateToProps = (state) => ({
  myStudent: state.myStudent,
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypes: (...args) => dispatch(Actions.getEventTypes(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
  getMyStudent: (...args) => dispatch(Actions.getMyStudent(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
});
class EventsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getEventTypes: PropTypes.func,
    myStudent: PropTypes.object,
    getCoaches: PropTypes.func,
    getAvailabilities: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };

    this.refresh();
  }

  async refresh() {
    const {
      getEventTypes,
      getCoaches,
      getAvailabilities,
      getMyStudent,
    } = this.props;

    const resps = await Promise.all([
      getEventTypes(),
      getCoaches(),
      getAvailabilities(),
      getMyStudent(),
    ]);

    this.setState({
      eventTypes: resps[0].payload,
      coaches: resps[1].payload,
      availabilities: resps[2].payload,
      loading: false,
    });
  }

  render() {
    const { location, history, myStudent } = this.props;

    const {
      urlParams,
      loading,
      eventTypes,
      coaches,
      availabilities,
    } = this.state;

    return (
      <WrapperEventsPage
        history={history}
        location={location}
        timeAssets={myStudent && myStudent.timeAssets}
        eventTypes={eventTypes}
        availabilities={availabilities}
        coaches={coaches}
        events={myStudent && myStudent.events}
        student={myStudent}
        loading={loading}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
