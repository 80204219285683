import { networkAction } from "helpers/network/networkAction";

import {
  GET_COACHES,
  GET_COACH_PUBLIC_AVAILABILITIES,
  GET_DAILY_AVAILABILITIES,
  GET_COACH_BY_ID,
  DELETE_COACH,
  UPDATE_COACH,
  CREATE_COACH,
} from "constants/coaches";

import * as Api from "api";

export const getCoaches = () => async (dispatch) =>
  networkAction(dispatch, GET_COACHES, Api.getCoaches, []);

export const getDailyAvailabilities = (
  coachID,
  date,
  format,
  combinedCoach,
  tzName
) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_DAILY_AVAILABILITIES,
    Api.getDailyAvailabilities,
    [coachID, date, format, combinedCoach, tzName]
  );

export const getEventTypesPublicAvailabilities = (coachID, filters) => async (
  dispatch
) =>
  networkAction(
    dispatch,
    GET_COACH_PUBLIC_AVAILABILITIES,
    Api.getEventTypesPublicAvailabilities,
    [coachID, filters]
  );

export const getCoachById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COACH_BY_ID, Api.getCoachById, [id]);

export const deleteCoach = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COACH, Api.deleteCoach, [id]);

export const createCoach = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COACH, Api.createCoach, [body]);

export const updateCoach = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COACH, Api.updateCoach, [id, body]);
