export default (theme) => ({
  tabs: {
    background: "white",
  },
  tabsIndicator: {
    backgroundColor: theme.palette.text,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.text,
      opacity: 1,
    },
    "&$tabSelected": {
      color: theme.palette.text,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.text,
    },
  },
  tabSelected: {},
  floatingActionButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  darkModeFix: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  darkMode: {
    background:
      theme.palette.type === "dark" ? "rgba(255,255,255,1)" : undefined,
    borderRadius: 4,
    padding: 4,
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  circularContainer: {
    position: "relative",
    width: 40,
  },
  value: {
    position: "absolute",
    top: 10,
    textAlign: "center",
    width: "100%",
  },
});
