export default (theme) => ({
  container: {
    padding: 5,
  },
  time: {
    background: "rgba(33,150,243,0.1)",
    border: "solid 1px rgba(33,150,243,0.3)",
    color: "#2196f3",
    borderRadius: 20,
    fontSize: 12,
    padding: "2px 6px",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
});
