import { networkAction } from "helpers/network/networkAction";

import {
  GET_PERSONALITIES,
  GET_PERSONALITIE_BY_ID,
  DELETE_PERSONALITIE,
  UPDATE_PERSONALITIE,
  CREATE_PERSONALITIE,
} from "constants/personalities";

import * as Api from "api";

export const getPersonalities = () => async (dispatch) =>
  networkAction(dispatch, GET_PERSONALITIES, Api.getPersonalities, []);

export const getPersonalitieById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PERSONALITIE_BY_ID, Api.getPersonalitieById, [
    id,
  ]);

export const deletePersonalitie = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PERSONALITIE, Api.deletePersonalitie, [id]);

export const createPersonalitie = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PERSONALITIE, Api.createPersonalitie, [body]);

export const updatePersonalitie = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PERSONALITIE, Api.updatePersonalitie, [
    id,
    body,
  ]);
