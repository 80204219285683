// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperStudentsPage from "../component/WrapperStudentsPage";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationUsers: (...args) =>
    dispatch(Actions.getOrganizationUsers(...args)),
  getOrganizationById: (...args) =>
    dispatch(Actions.getOrganizationById(...args)),
  createOrganizationUser: (...args) =>
    dispatch(Actions.createOrganizationUser(...args)),
  updateOrganizationUser: (...args) =>
    dispatch(Actions.updateOrganizationUser(...args)),
  deleteOrganizationUser: (...args) =>
    dispatch(Actions.deleteOrganizationUser(...args)),
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  createSurvey: (...args) => dispatch(Actions.createSurvey(...args)),
  getCourses: (...args) => dispatch(Actions.getCourses(...args)),
  createEnrollment: (...args) => dispatch(Actions.createEnrollment(...args)),
});
class StudentsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    user: PropTypes.object,

    getOrganizationUsers: PropTypes.func,
    getOrganizationById: PropTypes.func,
    createOrganizationUser: PropTypes.func,
    updateOrganizationUser: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    getAssessments: PropTypes.func,
    getCourses: PropTypes.func,
    createEnrollment: PropTypes.func,
    createSurvey: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { user, location } = this.props;
    let currentOrganization = { name: "No Organization" };
    if (user.organizations && user.organizations.length) {
      currentOrganization = user.organizations.find(
        (o) => o.id === user.organizationID
      );
      if (currentOrganization === undefined) {
        currentOrganization = user.organizations[0];
      }
    }

    this.state = {
      currentOrganizationID: currentOrganization.id,
      urlParams: getJsonFromUrl(location),
      currentOrganization,
      loading: true,
      assessments: [],
      courses: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { currentOrganizationID } = this.state;
    const {
      getOrganizationById,
      getOrganizationUsers,
      getAssessments,
      getCourses,
    } = this.props;

    const resps = await Promise.all([
      getOrganizationById(currentOrganizationID),
      getOrganizationUsers(currentOrganizationID),
      getAssessments(),
      getCourses(),
    ]);

    const currentOrganization = resps[0].payload;
    currentOrganization.users = resps[1].payload;
    const assessments = resps[2].payload;
    const courses = resps[3].payload;

    this.setState({
      currentOrganization,
      loading: false,
      assessments,
      courses,
    });
  }

  render() {
    const {
      location,
      history,
      createOrganizationUser,
      updateOrganizationUser,
      deleteOrganizationUser,
      createEnrollment,
      createSurvey,
      user,
    } = this.props;

    const {
      urlParams,
      loading,
      currentOrganization,
      assessments,
      courses,
    } = this.state;

    return (
      <WrapperStudentsPage
        history={history}
        user={user}
        location={location}
        refresh={this.refresh.bind(this)}
        urlParams={urlParams}
        loading={loading}
        assessments={assessments}
        createSurvey={createSurvey}
        createEnrollment={createEnrollment}
        currentOrganization={currentOrganization}
        createOrganizationUser={createOrganizationUser}
        updateOrganizationUser={updateOrganizationUser}
        deleteOrganizationUser={deleteOrganizationUser}
        courses={courses}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
