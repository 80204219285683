import { networkAction } from "helpers/network/networkAction";

import {
  GET_ENROLLMENTS,
  GET_ENROLLMENT_BY_ID,
  DELETE_ENROLLMENT,
  UPDATE_ENROLLMENT,
  CREATE_ENROLLMENT,
  GET_ENROLLMENT_PROGRESS,
  SET_ENROLLMENT_PROGRESS,
} from "constants/enrollments";

import * as Api from "api";

export const getEnrollments = () => async (dispatch) =>
  networkAction(dispatch, GET_ENROLLMENTS, Api.getEnrollments, []);

export const getEnrollmentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ENROLLMENT_BY_ID, Api.getEnrollmentById, [id]);

export const getEnrollmentProgress = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ENROLLMENT_PROGRESS, Api.getEnrollmentProgress, [
    id,
  ]);

export const setEnrollmentProgress = (id, body) => async (dispatch) =>
  networkAction(dispatch, SET_ENROLLMENT_PROGRESS, Api.setEnrollmentProgress, [
    id,
    body,
  ]);

export const deleteEnrollment = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ENROLLMENT, Api.deleteEnrollment, [id]);

export const createEnrollment = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ENROLLMENT, Api.createEnrollment, [body]);

export const updateEnrollment = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ENROLLMENT, Api.updateEnrollment, [id, body]);
