export default (theme) => ({
  container: {
    padding: 5,
  },
  listSubheader: {
    background: theme.palette.background.paper,
    width: "100%",
    cursor: "pointer",
  },
});
