export default (theme) => ({
  container: {
    padding: 5,
  },
  track: {
    background: "white",
  },
  thumb: {
    background: "white",
  },
});
