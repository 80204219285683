import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  backChip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing.unit * 1.5,
  },
  item: {
    background: theme.palette.background.default,
    padding: 15,
    borderLeft: "solid 3px #2196f3",
  },
  pdfToolBox: {
    position: "absolute",
    padding: 20,
    background: "rgba(0,0,0,0.4)",
    marginTop: -120,
    color: "white",
    borderRadius: 8,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    width: 150,
  },
});
