import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// styles
import styles from "./styles";

class TeamInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    team: PropTypes.object,
    updateTeam: PropTypes.func,
    refresh: PropTypes.func,
    assessments: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { team } = this.props;
    this.state = {
      ...team,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateTeam, refresh, team } = this.props;

    if (team[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateTeam(team.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { classes, assessments } = this.props;

    const {
      loading,
      name,
      description,
      dateFrom,
      dateTo,
      assessmentID,
    } = this.state;

    return (
      <Paper className={classes.container}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography display="block" variant="h6">
              General
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="qty">Assessment</InputLabel>
              <Select
                inputProps={{
                  name: "qty",
                  id: "qty",
                }}
                value={assessmentID}
                onChange={(e) => {
                  this.setState(
                    {
                      assessmentID: e.target.value,
                    },
                    () => this.save("assessmentID", true)
                  );
                }}
              >
                {assessments.map((a) => (
                  <MenuItem value={a.id}>{a.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              disabled={loading}
              onBlur={() => this.save("name")}
              value={name}
              label="Name"
              onChange={this.handleChange("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("description");
              }}
              disabled={loading}
              onBlur={() => this.save("description")}
              value={description}
              label="Description"
              onChange={this.handleChange("description")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("dateFrom");
              }}
              disabled={loading}
              type="date"
              label="Date From"
              onBlur={() => this.save("dateFrom")}
              value={moment(dateFrom).format("YYYY-MM-DD")}
              onChange={this.handleChange("dateFrom")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("dateTo");
              }}
              disabled={loading}
              label="Date To"
              type="date"
              onBlur={() => this.save("dateTo")}
              value={moment(dateTo).format("YYYY-MM-DD")}
              onChange={this.handleChange("dateTo")}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(TeamInfo);
