// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import Blockquote from "./renderers/Blockquote";
import Code from "./renderers/Code";
// Renderers
import Heading from "./renderers/Heading";
import Image from "./renderers/Image";
import InlineCode from "./renderers/InlineCode";
import Link from "./renderers/Link";
import List from "./renderers/List";
import ListItem from "./renderers/ListItem";
import Paragraph from "./renderers/Paragraph";
import Root from "./renderers/Root";
import Table from "./renderers/Table";
import TableBody from "./renderers/TableBody";
import TableCell from "./renderers/TableCell";
import TableHead from "./renderers/TableHead";
import TableRow from "./renderers/TableRow";
import Text from "./renderers/Text";
// styles
import styles from "./styles";

class Markdown extends Component {
  static propTypes = {
    source: PropTypes.string,
    darkMode: PropTypes.bool,
  };

  static childContextTypes = {
    Wrapper: PropTypes.object,
  };

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.darkMode !== this.props.darkMode ||
      nextProps.source !== this.props.source
    ) {
      return true;
    }
    return false;
  }

  getMd(source) {
    const { darkMode } = this.props;

    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    let i;

    return (
      <ReactMarkdown
        source={source}
        renderers={{
          root: (props) => <Root {...props} />,
          heading: (props) => <Heading {...props} />,
          text: (props) => <Text {...props} />,
          image: (props) => <Image {...props} />,
          paragraph: (props) => <Paragraph {...props} />,
          link: (props) => <Link {...props} />,
          linkReference: (props) => <Link {...props} />,
          list: (props) => <List {...props} />,
          listItem: (props) => {
            let checkboxId;
            if (props.checked !== null) {
              checkboxId = i;
              i += 1;
            }
            return <ListItem {...props} checkboxId={checkboxId} />;
          },
          blockquote: (props) => <Blockquote {...props} />,
          table: (props) => <Table {...props} />,
          tableHead: (props) => <TableHead {...props} />,
          tableBody: (props) => <TableBody {...props} />,
          tableRow: (props) => <TableRow {...props} />,
          tableCell: (props) => <TableCell {...props} />,
          code: (props) => <Code {...props} darkMode={darkMode} />,
          inlineCode: (props) => <InlineCode {...props} />,
        }}
        escapeHtml={false}
        astPlugins={[parseHtml]}
      />
    );
  }

  render() {
    const { source } = this.props;

    return this.getMd(source);
  }
}

export default withStyles(styles)(Markdown);
