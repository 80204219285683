import { EMAIL } from "constants/regexp";

export function isEmail(string) {
  return EMAIL.test(String(string).toLowerCase());
}

export function positiveNumber(number) {
  console.log(number);
  return (
    !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number) >= 0
  );
}

export function validNumber(number) {
  return !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number);
}
