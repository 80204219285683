import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperStatementPage from "../component/WrapperStatementPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getStatementById: (...args) => dispatch(Actions.getStatementById(...args)),
  generateInvoice: (...args) => dispatch(Actions.generateInvoice(...args)),
});
class StatementPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    generateInvoice: PropTypes.func,
    getStatementById: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      statementID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { statementID } = this.state;
    const { getStatementById } = this.props;

    const resp = await getStatementById(statementID);
    this.setState({ statement: resp.payload, loading: false });
  }

  render() {
    const { location, history, generateInvoice } = this.props;

    const { urlParams, loading, statement } = this.state;

    return (
      <WrapperStatementPage
        history={history}
        location={location}
        urlParams={urlParams}
        statement={statement}
        refresh={this.refresh.bind(this)}
        generateInvoice={generateInvoice}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StatementPage);
