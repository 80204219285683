import { ENROLLMENTS, SIGNIN, ASSESSMENTS, MARKET } from "constants/routes";

export function redirectAccordingToRole(accessLevel) {
  if (accessLevel) {
    const { services } = accessLevel;
    const { account, harmony } = services;
    let role = "anon";
    if (account && account.find((r) => r === "superadmin")) role = "superadmin";
    if (harmony && harmony.find((r) => r === "user")) role = "student";
    if (harmony && harmony.find((r) => r === "admin")) role = "admin";

    try {
      let redirectRoute;

      switch (role) {
        case "superadmin":
          redirectRoute = MARKET;
          break;
        case "student":
          redirectRoute = MARKET;
          break;
        case "admin":
          redirectRoute = ASSESSMENTS;
          break;
        case "anon":
          redirectRoute = SIGNIN;
          break;
        default:
          redirectRoute = "";
      }

      return redirectRoute;
    } catch (e) {}
  }

  return ENROLLMENTS;
}
