import { emphasize } from "@material-ui/core/styles/colorManipulator";
const drawerWidth = 320;

export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    height: "calc(100vh - 48px)",
    overflowY: "scroll",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  chip: {
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing.unit * 1.5,
  },
  sticky: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.default,
    padding: 10,
    borderRadius: 0,
    zIndex: 999,
  },
  circularContainer: {
    position: "relative",
  },
  back: {
    color: "rgba(127,127,127,0.5)",
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  value: {
    position: "absolute",
    top: 10,
    textAlign: "center",
    width: "100%",
  },
  drawer: {
    // height: 'calc(100vh - 47px)',
    // marginTop: 47,
    width: 320,
    flex: 1,
    position: "absolute",
  },
  mobileDrawer: {
    width: 320,
  },
});
