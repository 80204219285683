import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Back from "@material-ui/icons/ArrowBack";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import People from "@material-ui/icons/People";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormTeamMember from "components/FormTeamMember";
// reactor
import Page from "components/Page";
import Table from "components/Table";
import TeamInfo from "components/TeamInfo";
import { REACT_APP_API } from "config";
// routes
import { TEAMS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperTeamPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    team: PropTypes.object,
    refresh: PropTypes.func,
    updateTeamMember: PropTypes.func,
    createTeamMember: PropTypes.func,
    deleteTeamMember: PropTypes.func,
    updateTeam: PropTypes.func,
    teamUsers: PropTypes.array,
    assessments: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  onSelect(selectedMember) {
    this.setState({ selectedMember, formMemberOpen: true });
  }

  getStepContent() {
    const { team, updateTeam, refresh, assessments } = this.props;

    return (
      <Grid
        container
        spacing={2}
        style={{ maxWidth: 900, marginLeft: "auto", marginRight: "auto" }}
      >
        <Grid item xs={12}>
          <TeamInfo
            team={team}
            assessments={assessments}
            updateTeam={updateTeam}
            refresh={refresh}
          />
        </Grid>
        <Grid item>
          <div>
            <Fab
              color="primary"
              size="small"
              variant="extended"
              aria-label="Add"
              style={{ textTransform: "none" }}
              onClick={() => this.setState({ formMemberOpen: true })}
            >
              <AddIcon fontSize="small" /> Member
            </Fab>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table
            data={team && team.members ? team.members : []}
            meta={[
              {
                path: "icon",
                numeric: false,
                width: 30,
                render: (t, d) => (
                  <Avatar>
                    {d.firstName && d.firstName[0]}
                    {d.lastName && d.lastName[0]}{" "}
                  </Avatar>
                ),
              },
              {
                path: "id",
                title: "Full Name",
                numeric: false,
                transform: (t, d) => `${d.firstName} ${d.lastName}`,
              },
              {
                path: "email",
                title: "Email",
                numeric: false,
              },
              {
                path: "role",
                title: "Role",
                numeric: false,
                transform: (t, d) => `${t}${d.leader ? " (Leader)" : ""}`,
              },
              {
                path: "surveyID",
                title: "Profile",
                numeric: false,
                render: (t) => (
                  <Chip
                    variant="outlined"
                    label={`Profile #${t}`}
                    icon={<OpenInNewIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(
                        `${REACT_APP_API}/harmony/surveys/${t}/profile`,
                        "_blank"
                      );
                    }}
                  />
                ),
              },
            ]}
            title={"Members"}
            onRowSelect={this.onSelect.bind(this)}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      team,
      refresh,
      updateTeamMember,
      createTeamMember,
      deleteTeamMember,
      teamUsers,
    } = this.props;

    const { index, selectedMember, formMemberOpen } = this.state;

    return (
      <Page
        helmet="Team"
        loadingMessage={"Loading Team"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }} justify="space-between">
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"All Teams"}
                  className={classes.backChip}
                  onClick={() => history.push(TEAMS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {team && team.id ? (
                  <Chip
                    label={team.name}
                    className={classes.backChip}
                    onClick={() => history.push(TEAMS)}
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <People className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Chip
                label="View Team Profile"
                color="primary"
                onClick={() =>
                  window.open(
                    `${REACT_APP_API}/harmony/teams/${team.id}/profile`,
                    "_blank"
                  )
                }
              />
            </Grid>
          </Grid>
        }
      >
        {this.getStepContent()}
        <FormTeamMember
          open={formMemberOpen}
          member={selectedMember}
          close={() => {
            this.setState({
              formMemberOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedMember: undefined,
                }),
              200
            );
          }}
          createTeamMember={createTeamMember}
          deleteTeamMember={deleteTeamMember}
          updateTeamMember={updateTeamMember}
          teamID={team ? team.id : undefined}
          teamUsers={teamUsers}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTeamPage);
