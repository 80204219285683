// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperEnrollmentPage from "../component/WrapperEnrollmentPage";

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getEnrollmentById: (...args) => dispatch(Actions.getEnrollmentById(...args)),
  getEnrollmentProgress: (...args) =>
    dispatch(Actions.getEnrollmentProgress(...args)),
  setEnrollmentProgress: (...args) =>
    dispatch(Actions.setEnrollmentProgress(...args)),
  getCoursePreview: (...args) => dispatch(Actions.getCoursePreview(...args)),
});
class EnrollmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getEnrollmentProgress: PropTypes.func,
    setEnrollmentProgress: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      enrollmentID: Number(urlParams.id),
      lectureID: urlParams.lectureID ? Number(urlParams.lectureID) : undefined,
      moduleID: urlParams.moduleID ? Number(urlParams.moduleID) : undefined,
      enrollment: {},
      progress: [],
      loading: true,
      previewID: Number(urlParams.previewID),
    };
  }

  componentDidMount() {
    this.refresh();
    this.refreshProgress();
  }

  componentWillReceiveProps(nextProps) {
    const urlParams = getJsonFromUrl(nextProps.location);
    const { enrollment, enrollmentID, lectureID, moduleID } = this.state;
    const _enrollmentID = urlParams.id;
    const _lectureID = urlParams.lectureID;
    const _moduleID = urlParams.moduleID;

    if (
      (_enrollmentID !== enrollmentID ||
        _lectureID !== lectureID ||
        _moduleID !== moduleID) &&
      enrollment.id
    ) {
      this.setState(
        {
          enrollmentID: _enrollmentID ? Number(_enrollmentID) : undefined,
          lectureID: _lectureID ? Number(_lectureID) : undefined,
          moduleID: _moduleID ? Number(_moduleID) : undefined,
        },
        this.refreshBookmark
      );
    }
  }

  async refresh() {
    const { getEnrollmentById, getCoursePreview } = this.props;
    const { enrollmentID, previewID } = this.state;

    let resp;

    if (previewID) {
      resp = await getCoursePreview(previewID);
    } else {
      resp = await getEnrollmentById(enrollmentID);
    }

    if (resp.success) {
      const enrollment = resp.payload;
      this.setState(
        {
          loading: false,
          enrollment,
        },
        this.refreshBookmark
      );
    }
  }

  refreshBookmark() {
    const { history } = this.props;
    const { enrollment, lectureID, moduleID } = this.state;
    if (lectureID === undefined && moduleID === undefined) {
      history.push({
        search: `?id=${enrollment.id}&moduleID=${enrollment.moduleID}&lectureID=${enrollment.lectureID}`,
      });
      return;
    }
    const moduleIndex = enrollment.modules.findIndex((m) => m.id === moduleID);
    const lectureIndex = enrollment.modules[moduleIndex].lectures.findIndex(
      (l) => l.id === lectureID
    );
    enrollment.nextLecture = undefined;
    enrollment.previousLecture = undefined;
    enrollment.module = enrollment.modules[moduleIndex];
    enrollment.lecture = enrollment.modules[moduleIndex].lectures[lectureIndex];

    // Previous
    if (
      lectureIndex > 0 &&
      enrollment.modules[moduleIndex].lectures[lectureIndex - 1]
    ) {
      enrollment.previousLecture =
        enrollment.modules[moduleIndex].lectures[lectureIndex - 1];
    } else if (
      moduleIndex > 0 &&
      enrollment.modules[moduleIndex - 1] &&
      enrollment.modules[moduleIndex - 1].lectures &&
      enrollment.modules[moduleIndex - 1].lectures.length
    ) {
      enrollment.previousLecture =
        enrollment.modules[moduleIndex - 1].lectures[
          enrollment.modules[moduleIndex - 1].lectures.length - 1
        ];
    }

    // Next
    if (enrollment.modules[moduleIndex].lectures[lectureIndex + 1]) {
      enrollment.nextLecture =
        enrollment.modules[moduleIndex].lectures[lectureIndex + 1];
    } else if (
      enrollment.modules[moduleIndex + 1] &&
      enrollment.modules[moduleIndex + 1].lectures &&
      enrollment.modules[moduleIndex + 1].lectures.length
    ) {
      enrollment.nextLecture = enrollment.modules[moduleIndex + 1].lectures[0];
    }

    this.setState({
      enrollment,
    });
    this.refreshProgress();
  }

  async refreshProgress() {
    const { getEnrollmentProgress } = this.props;
    const { enrollmentID, previewID } = this.state;

    if (previewID) return;
    const resp = await getEnrollmentProgress(enrollmentID);
    if (resp.success) {
      this.setState({ progress: resp.payload });
    }
  }

  render() {
    const { location, history, app, setEnrollmentProgress } = this.props;

    const { urlParams, enrollment, loading, progress, previewID } = this.state;

    return (
      <WrapperEnrollmentPage
        enrollment={enrollment}
        refreshProgress={
          previewID
            ? () => console.log("No Progress")
            : this.refreshProgress.bind(this)
        }
        setEnrollmentProgress={setEnrollmentProgress}
        progress={progress}
        loading={loading}
        history={history}
        location={location}
        urlParams={urlParams}
        app={app}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentPage);
