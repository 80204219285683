import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperSurveyPage from "../component/WrapperSurveyPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  surveys: state.surveys,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveys: () => dispatch(Actions.getSurveys()),
  getSurveyById: (...args) => dispatch(Actions.getSurveyById(...args)),
  createSurvey: (...args) => dispatch(Actions.createSurvey(...args)),
  deleteSurvey: (...args) => dispatch(Actions.deleteSurvey(...args)),
  updateSurvey: (...args) => dispatch(Actions.updateSurvey(...args)),
});
class SurveyPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    surveys: PropTypes.array,
    getSurveys: PropTypes.func,
    getSurveyById: PropTypes.func,
    createSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
    updateSurvey: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, surveys } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: surveys.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getSurveys } = this.props;
    await Promise.all([getSurveys()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      surveys,
      getSurveyById,
      createSurvey,
      deleteSurvey,
      updateSurvey,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSurveyPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        surveys={surveys}
        getSurveyById={getSurveyById}
        createSurvey={createSurvey}
        deleteSurvey={deleteSurvey}
        updateSurvey={updateSurvey}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
