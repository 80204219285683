// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class AssessmentsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    assessments: PropTypes.array,
    getAssessments: PropTypes.func,
    history: PropTypes.func,
    onSelect: PropTypes.func,
  };

  onSelect(selectedAssessment) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedAssessment);
    }
  }

  render() {
    const { assessments, classes, history, getAssessments } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getAssessments}
        keyword="assessments"
        metaDataEndpoint="/harmony/assessments/searchMetadata"
      >
        <Table
          data={assessments}
          rowStyle={(d) => ({
            borderLeft: `solid 3px ${d.color}`,
            opacity: d.active ? 1 : 0.5,
          })}
          meta={[
            {
              path: "icon",
              render: (t) => (
                <img
                  className={classes.darkMode}
                  src={t}
                  alt="assessment"
                  style={{ height: 40 }}
                />
              ),
              width: 30,
            },
            {
              path: "name",
              title: "Name",
              numeric: false,
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t}
                  </Typography>
                  <Typography
                    display="block"
                    color="textSecondary"
                    variant="caption"
                  >
                    {d.active ? "Active" : "Unactive"}
                    {d.locked ? (
                      <>
                        - <i class="fad fa-lock-alt"></i> Locked
                      </>
                    ) : (
                      []
                    )}
                  </Typography>
                </React.Fragment>
              ),
            },
          ]}
          title={"Assessments"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(AssessmentsList);
