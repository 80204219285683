import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// helpers
import { getState } from "helpers";
import { durationFormatter } from "helpers/date";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TimeAssetList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timeAssets: PropTypes.array,
    events: PropTypes.array,
    eventTypes: PropTypes.array,
  };

  render() {
    const { classes, timeAssets, events, eventTypes } = this.props;

    const calendyState = getState(events, timeAssets, eventTypes);

    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          {timeAssets && timeAssets.length ? (
            <Grid item xs={12} md={6}>
              <Typography display="block" variant="h6">
                Purchased Time
              </Typography>
              <div
                style={{
                  width: "100%",
                  height: 30,
                  overflow: "hidden",
                  borderRadius: 6,
                  marginBottom: 20,
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    background: "#bdbdbd",
                    transition: "width 1s",
                    width: `${
                      (calendyState.totalDuration / calendyState.totalAssets) *
                      100
                    }%`,
                  }}
                />
                <div
                  style={{
                    background: "#42a5f5",
                    transition: "width 1s",
                    width: `${
                      (calendyState.totalSchedule / calendyState.totalAssets) *
                      100
                    }%`,
                  }}
                />
                <div
                  style={{
                    background: "#9ccc65",
                    transition: "width 1s",
                    width: `${
                      (calendyState.remaining / calendyState.totalAssets) * 100
                    }%`,
                  }}
                />
              </div>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <div
                        style={{
                          background: "#bdbdbd",
                          height: 15,
                          width: 15,
                          borderRadius: 15,
                          border: "solid 3px white",
                          boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography display="block" variant="body2">
                        Consumed
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {durationFormatter(calendyState.totalDuration)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <div
                        style={{
                          background: "#42a5f5",
                          height: 15,
                          width: 15,
                          borderRadius: 15,
                          border: "solid 3px white",
                          boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography display="block" variant="body2">
                        Scheduled
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {durationFormatter(calendyState.totalSchedule)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <div
                        style={{
                          background: "#9ccc65",
                          height: 15,
                          width: 15,
                          borderRadius: 15,
                          border: "solid 3px white",
                          boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography display="block" variant="body2">
                        Remainig
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {durationFormatter(calendyState.remaining)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )}
          {timeAssets && timeAssets.length ? (
            <Grid item xs={12} md={6}>
              <Typography display="block" variant="h6">
                Purchase History
              </Typography>
              <div>
                <Divider />
                <div
                  style={{
                    height: 120,
                    position: "relative",
                  }}
                >
                  {timeAssets && timeAssets.length > 3 ? (
                    <div className={classes.shadow} />
                  ) : (
                    []
                  )}
                  <div className={classes.frame}>
                    {timeAssets &&
                      timeAssets.map((ta) => (
                        <Grid
                          container
                          alignItems="center"
                          justify="space-between"
                        >
                          <Grid item>
                            <Typography display="block" variant="body2">
                              {durationFormatter(ta.duration)}
                            </Typography>
                            {ta.used ? (
                              <Typography
                                display="block"
                                style={{ color: "#f44336" }}
                                variant="caption"
                              >
                                Used
                              </Typography>
                            ) : (
                              []
                            )}
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="body2">
                              {moment(ta.datePurchased).format("lll")}
                            </Typography>
                            <Typography
                              display="block"
                              variant="caption"
                              color="textSecondary"
                            >
                              {moment(ta.datePurchased).fromNow()}
                            </Typography>
                          </Grid>
                          {/* <Grid item>
                            <Chip
                              label="View Invoice"
                              variant="outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.location.replace(
                                  `/${PAYMENT}?id=${ta.paymentID}`
                                );
                              }}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ))}
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(TimeAssetList);
