import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const modules = `/${service}/modules`;

export function getModules() {
  const url = `${modules}`;
  return {
    method: GET,
    url,
  };
}

export function getModuleById(id) {
  const url = `${modules}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateModule(id, body) {
  const url = `${modules}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteModule(id) {
  const url = `${modules}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createModule(body) {
  const url = `${modules}`;
  return {
    method: POST,
    url,
    body,
  };
}
