import { networkAction } from "helpers/network/networkAction";

import { HARMONY_CALLBACK, APTITUDE_CALLBACK } from "constants/callbacks";

import * as Api from "api";

export const harmonyCallback = (body) => async (dispatch) =>
  networkAction(dispatch, HARMONY_CALLBACK, Api.harmonyCallback, [body]);

export const aptitudeCallback = (body) => async (dispatch) =>
  networkAction(dispatch, APTITUDE_CALLBACK, Api.aptitudeCallback, [body]);
