import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Stepper from "@material-ui/core/Stepper";
// material-ui
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import Payment from "@material-ui/icons/Payment";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
import Warning from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/styles";
import Card from "components/Card";
import CreateCard from "components/CreateCard";
// reactor
import Page from "components/Page";
// constant
import {
  REACT_APP_BAZAAR_CALLBACK,
  REACT_APP_FRONT_BASE,
  STRIPE_IMAGES,
  STRIPE_TOKEN,
} from "config";
// constants
import { CART, MARKET, MY_ACCOUNT, PAYMENT } from "constants/routes";
// helpers
import { formattedPrice } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
// styles
import styles from "./styles";

class WrapperCheckoutPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object,
    app: PropTypes.object,
    refreshKey: PropTypes.number,
    updateCartGood: PropTypes.func,
    deleteCartGood: PropTypes.func,
    createCard: PropTypes.func,
    refresh: PropTypes.func,
    deleteCartCoupon: PropTypes.func,
    payCart: PropTypes.func,
    updateCart: PropTypes.func,
    cart: PropTypes.object,
    cards: PropTypes.object,
    customer: PropTypes.object,
    loading: PropTypes.bool,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      activeStep: 1,
      addCardCollapsed: false,
      processing: false,
      token: "",
      // pay,
      // callbacks: {
      //   harmony: harmonyCallback,
      //   aptitude: aptitudeCallback,
      // },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedCard } = this.state;
    if (
      nextProps.cards &&
      nextProps.cards[0] &&
      nextProps.cards[0].default &&
      selectedCard === undefined
    ) {
      this.setState({
        selectedCard: nextProps.cards[0],
      });
    }
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getSummaryStepContent(step) {
    const { cart, customer } = this.props;

    const { selectedCard } = this.state;

    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Typography display="block" style={{ fontWeight: 600 }}>
              {customer.fullName}
            </Typography>
            <Typography display="block" variant="body2">
              {customer.email}
            </Typography>
            <Typography display="block" variant="body2">
              {customer.address1}
            </Typography>
            <Typography display="block" variant="body2">
              {customer.address2}
            </Typography>
            <Typography display="block" variant="body2">
              {customer.zip} {customer.city} {customer.state} {customer.country}
            </Typography>
          </React.Fragment>
        );
      case 1:
        if (selectedCard === undefined) {
          return (
            <Chip
              label={"Select way of Payment"}
              color="primary"
              avatar={
                <Avatar>
                  <Warning />
                </Avatar>
              }
            />
          );
        }
        return (
          <Chip
            label={`${selectedCard ? selectedCard.brand : ""} ending on ${
              selectedCard ? selectedCard.lastFour : ""
            }`}
          />
        );
      case 2:
        return `${cart && cart.goods ? cart.goods.length : "-"} Item${
          cart && cart.goods && cart.goods.length > 1 ? "s" : ""
        }`;
      default:
        return "Unknown step";
    }
  }

  getStepContent(step) {
    const { classes, cards, app, createCard, refresh, history } = this.props;

    const { addCardCollapsed, selectedCard } = this.state;

    switch (step) {
      case 0:
        return (
          <Button
            variant="outlined"
            onClick={() => history.push(`${MY_ACCOUNT}?index=2`)}
          >
            Edit
          </Button>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            {cards &&
              cards.map((card) => (
                <Grid item key={card.id} xs={12}>
                  <Card
                    dardMode={app.darkMode}
                    card={card}
                    key={card.id}
                    onSelect={(c) => this.setState({ selectedCard: c })}
                    selected={
                      selectedCard && selectedCard.id
                        ? selectedCard.id === card.id
                        : false
                    }
                  />
                </Grid>
              ))}
            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  this.setState((prevState) => ({
                    addCardCollapsed: !prevState.addCardCollapsed,
                  }))
                }
              >
                {addCardCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                Add a Card
              </Button>
              <Collapse in={addCardCollapsed} style={{ paddingTop: 20 }}>
                {addCardCollapsed ? (
                  <Paper className={classes.addCard}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          We accept all major credit and debit cards. We use
                          Stripe to secure your transaction. Your information is
                          saved with Stripe servers only.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <img
                          src={`${STRIPE_IMAGES}/visa.png`}
                          alt="visa"
                          width="30"
                        />
                      </Grid>
                      <Grid item>
                        <img
                          src={`${STRIPE_IMAGES}/master-card.png`}
                          alt="master-card"
                          width="30"
                        />
                      </Grid>
                      <Grid item>
                        <img
                          src={`${STRIPE_IMAGES}/diners-club.png`}
                          alt="diners-club"
                          width="30"
                        />
                      </Grid>
                      <Grid item>
                        <img
                          src={`${STRIPE_IMAGES}/discover.png`}
                          alt="discover"
                          width="30"
                        />
                      </Grid>
                      <Grid item>
                        <img
                          src={`${STRIPE_IMAGES}/jcb.png`}
                          alt="jcb"
                          width="30"
                        />
                      </Grid>
                    </Grid>
                    <StripeProvider apiKey={STRIPE_TOKEN}>
                      <Elements>
                        <CreateCard
                          createCard={createCard}
                          refresh={refresh}
                          onClose={() =>
                            this.setState({ addCardCollapsed: false })
                          }
                        />
                      </Elements>
                    </StripeProvider>
                  </Paper>
                ) : (
                  <div />
                )}
              </Collapse>
            </Grid>
          </Grid>
        );
      default:
        return "Unknown step";
    }
  }

  getSteps() {
    const { cart } = this.props;
    const steps = ["Billing Address"];
    if (cart && cart.total > 0) {
      steps.push("Select Way of Payment");
    }
    return steps;
  }

  async applyCoupon() {
    const { updateCart, cart, refresh } = this.props;
    const { coupons } = cart;
    const { token } = this.state;

    coupons.push({
      token,
    });

    await updateCart(cart.id, cart);
    refresh();
    this.setState({
      token: "",
    });
  }

  async deleteCoupon(index) {
    const { deleteCartCoupon, cart, refresh } = this.props;
    const { coupons } = cart;

    await deleteCartCoupon(coupons[index].relationID);
    refresh();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async payNow() {
    const { payCart, cart, refresh } = this.props;
    const { selectedCard } = this.state;
    this.setState({ processing: true });

    const resp = await payCart(cart.id, {
      stripeToken: selectedCard ? selectedCard.id : "FREE",
    });
    if (resp.success) {
      const payment = resp.payload;

      await fetch(REACT_APP_BAZAAR_CALLBACK, {
        body: JSON.stringify({ paymentID: payment.id }),
        method: "POST",
      });

      this.setState({
        processing: false,
        pay: resp.payload,
      });
    } else {
      this.setState({ processing: false });
    }
    refresh();
  }

  render() {
    const { classes, cart, history, user, loading } = this.props;
    const steps = this.getSteps();

    const { token, processing, activeStep, selectedCard, pay } = this.state;

    const goods = cart && cart.goods ? cart.goods : [];

    if (user && user.uuid === undefined) {
      return (
        <Page helmet="Sign in">
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            direction="column"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <Grid item>
              <img
                src={`${STRIPE_IMAGES}/id-card.png`}
                style={{ height: 150 }}
                alt="shopping bags"
              />
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="h4"
                style={{ fontWeight: 800 }}
              >
                Do you have an account ?
              </Typography>
              <Typography display="block" color="textSecondary">
                In order to finalize your order, you need to be signed in.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      window.location.replace(`${REACT_APP_FRONT_BASE}/?signup`)
                    }
                  >
                    Create an Account
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.location.replace(`${REACT_APP_FRONT_BASE}`)
                    }
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Page>
      );
    }

    if (pay) {
      return (
        <Page helmet="All Set !">
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            direction="column"
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <Grid item>
              <img
                src={`${STRIPE_IMAGES}/shopper.png`}
                style={{ height: 150 }}
                alt="shopping bags"
              />
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography
                display="block"
                variant="h4"
                style={{ fontWeight: 800 }}
              >
                You are all Set !
              </Typography>
              <Typography display="block" color="textSecondary">
                Thank you for your payment. You will receive an email receipt
                shortly.
                <br />
                Products have been added to your account. You can find them
                under the "My Purchases" item from the main menu.
              </Typography>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 20 }}
                justify="center"
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => history.push(MARKET)}
                  >
                    Continue Shopping
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => history.push(`${PAYMENT}?id=${pay.id}`)}
                  >
                    View Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item style={{ marginTop: 20, width: 'calc(100% - 40px)' }}>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                {
                  callbacks.harmony.surveys && callbacks.harmony.surveys.length
                    ? (
                      <Grid item xs={12}>
                        <SurveysList
                          surveys={callbacks.harmony.surveys}
                          history={history}
                        />
                      </Grid>
                    ) : []
                }
                {
                  callbacks.aptitude.enrollments && callbacks.aptitude.enrollments.length
                    ? (
                      <Grid item xs={12}>
                        <EnrollmentsList
                          enrollments={callbacks.aptitude.enrollments}
                          history={history}
                        />
                      </Grid>
                    ) : []
                }
              </Grid>
            </Grid> */}
          </Grid>
        </Page>
      );
    }

    return (
      <Page
        helmet="Checkout"
        loadingMessage={processing ? "Processing" : "Loading Checkout"}
        loading={loading || processing}
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            direction="row-reverse"
            spacing={2}
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <Grid item xs={12} md={4} style={{ position: "sticky", top: 0 }}>
              <Paper className={classes.paper}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography display="block" variant="h6">
                      Subtotal ({goods.length} item{goods.length > 1 ? "s" : ""}
                      ):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="h6" color="primary">
                      {formattedPrice(cart.subTotal)}
                    </Typography>
                  </Grid>
                </Grid>
                <ExpansionPanel style={{ boxShadow: "none" }}>
                  <ExpansionPanelSummary
                    style={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography display="block" variant="caption">
                      Coupons
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <InputBase
                      fullWidth
                      className={classes.inputBase}
                      value={token}
                      placeholder="Enter Coupon Code"
                      onChange={this.handleChange("token")}
                    />
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ color: "white" }}
                      onClick={this.applyCoupon.bind(this)}
                    >
                      Apply
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
                <Grid container spacing={2}>
                  {cart &&
                    cart.coupons &&
                    cart.coupons.map((c, index) => (
                      <Grid item key={c.token}>
                        <Chip
                          onDelete={() => this.deleteCoupon(Number(index))}
                          label={`${c.token} - ${c.percent}%`}
                        />
                      </Grid>
                    ))}
                </Grid>
                {cart.total !== cart.subTotal ? (
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography display="block" variant="subheading">
                        Discount:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        variant="subheading"
                        color="primary"
                      >
                        {formattedPrice(cart.subTotal - cart.total)}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item>
                    <Typography display="block" variant="subheading">
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      variant="subheading"
                      color="primary"
                    >
                      {formattedPrice(cart.total)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container justify="flex-end" style={{ marginTop: 20 }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        selectedCard === undefined && cart && cart.total > 0
                      }
                      onClick={this.payNow.bind(this)}
                    >
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography display="block" variant="h6">
                    <Payment /> Checkout
                  </Typography>
                  <Typography display="block" color="textSecondary">
                    Click on a step to review.
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    onClick={() => history.push(CART)}
                    label={"Review Cart"}
                    avatar={
                      <Avatar>
                        <ShoppingCart />
                      </Avatar>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <div>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                  className={classes.stepper}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        onClick={() => this.setState({ activeStep: index })}
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {label}
                        <Typography display="block" variant="body2">
                          {this.getSummaryStepContent(index)}
                        </Typography>
                      </StepButton>
                      <StepContent>{this.getStepContent(index)}</StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCheckoutPage);
