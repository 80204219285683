// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperSurveyPage from "../component/WrapperSurveyPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyById: (...args) => dispatch(Actions.getSurveyById(...args)),
  getAssessmentById: (...args) => dispatch(Actions.getAssessmentById(...args)),
  getProfile: (...args) => dispatch(Actions.getProfile(...args)),
  getAssessmentQuestions: (...args) =>
    dispatch(Actions.getAssessmentQuestions(...args)),
  answerQuestion: (...args) => dispatch(Actions.answerQuestion(...args)),
  createPersonalitie: (...args) =>
    dispatch(Actions.createPersonalitie(...args)),
});
class SurveyPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getSurveyById: PropTypes.func,
    getAssessmentById: PropTypes.func,
    getProfile: PropTypes.func,
    getAssessmentQuestions: PropTypes.func,
    answerQuestion: PropTypes.func,
    updatePersonalitie: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      fake: urlParams.fake || false,
      surveyID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { surveyID } = this.state;
    const { getSurveyById } = this.props;

    const surveyResp = await getSurveyById(surveyID);
    const survey = surveyResp.payload;

    this.setState({ survey, loading: false });
  }

  render() {
    const {
      location,
      history,
      answerQuestion,
      getProfile,
      createPersonalitie,
    } = this.props;

    const { urlParams, loading, survey, questions, fake } = this.state;

    return (
      <WrapperSurveyPage
        history={history}
        location={location}
        urlParams={urlParams}
        survey={survey}
        assessment={survey ? survey.assessment : undefined}
        questions={questions}
        answerQuestion={answerQuestion}
        getProfile={getProfile}
        createPersonalitie={createPersonalitie}
        fake={fake}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
