export function getJsonFromUrl(location) {
  const query = location.search.substr(1);
  const result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function goBack(history, fallback) {
  const urlParams = getJsonFromUrl(window.location);
  console.log(urlParams);
  if (urlParams.back) {
    console.log(decodeURIComponent(urlParams.back));
    history.push(decodeURIComponent(urlParams.back));
  } else {
    history.push(fallback);
  }
}

export function buildForURL(search) {
  return Object.keys(search)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(search[k])}`)
    .join("&");
}

export function updateURLParameter(key, value) {
  const urlParams = getJsonFromUrl(window.location);
  delete urlParams[""];
  urlParams[key] = value;
  return buildForURL(urlParams);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export function removeURLParameter(key) {
  const urlParams = getJsonFromUrl(window.location);
  delete urlParams[key];
  return buildForURL(urlParams);
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function setFavicon(icon) {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = icon;
  document.getElementsByTagName("head")[0].appendChild(link);
}
