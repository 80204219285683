import { Chip, MenuItem, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Delete from "@material-ui/icons/Delete";
// routes
import { ASSESSMENTS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import fileDownload from "react-file-download";
// styles
import preview from "./preview.png";
import styles from "./styles";

class AssessmentMarketing extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateAssessment: PropTypes.func,
    deleteAssessment: PropTypes.func,
    updateInquiry: PropTypes.func,
    getAssessmentById: PropTypes.func,
    assessment: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    app: PropTypes.object,
    createAssessmentInquiry: PropTypes.object,
    deleteAssessmentInquiry: PropTypes.object,
    inquiries: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      ...assessment,
      loading: false,
    };
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    UploadCenter.disregard();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleColorChange = (name) => (color) => {
    this.setState({ [name]: color.hex });
  };

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({
            icon: r.payload,
          });
          this.save("icon");
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async save(name, numeric) {
    const { updateAssessment, refresh, assessment } = this.props;

    if (assessment[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateAssessment(assessment.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async saveInquiry(name, numeric) {
    const { updateInquiry, refresh, assessment } = this.props;

    this.setState({
      loading: true,
    });

    await updateInquiry(assessment.inquiry.id, {
      [name]: numeric
        ? Number(this.state.inquiry[name])
        : this.state.inquiry[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteAssessment, refresh, assessment, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteAssessment(assessment.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Assessment has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      history.push(ASSESSMENTS);
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async createInquiry(e) {
    const { createAssessmentInquiry, refresh, assessment } = this.props;
    await createAssessmentInquiry({
      assessmentID: assessment.id,
      inquiryID: e.target.value,
    });
    refresh();
  }

  async deleteInquiry(relationID) {
    const { deleteAssessmentInquiry, refresh } = this.props;
    await deleteAssessmentInquiry(relationID);
    refresh();
  }

  render() {
    const { classes, assessment, getAssessmentById, inquiries } = this.props;

    const {
      loading,
      icon,
      color,
      groupID,
      thresholds,
      organizationID,
      locked,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography display="block" variant="h6">
              Marketing
            </Typography>
            {icon ? (
              <img
                src={icon}
                alt="assessment-logo"
                style={{
                  height: 75,
                  background: "#FFFFFF",
                  borderRadius: 4,
                  padding: 2,
                }}
              />
            ) : (
              []
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Icon URL:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Drag and drop to upload an icon.
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("icon");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("icon")}
                  className={classes.inputBase}
                  value={icon}
                  onChange={this.handleChange("icon")}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Organization ID
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("organizationID", true);
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("organizationID", true)}
                  className={classes.inputBase}
                  value={organizationID}
                  onChange={this.handleChange("organizationID")}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Group ID
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("groupID", true);
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("groupID", true)}
                  className={classes.inputBase}
                  value={groupID}
                  onChange={this.handleChange("groupID")}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Thresholds
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("thresholds");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("thresholds")}
                  className={classes.inputBase}
                  value={thresholds}
                  onChange={this.handleChange("thresholds")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Color:
                </Typography>
                <SketchPicker
                  color={color || ""}
                  disableAlpha
                  onChange={!locked && this.handleColorChange("color")}
                  onChangeComplete={() => {
                    if (!locked) this.save("color");
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div style={{ position: "relative" }}>
            <img
              src={preview}
              style={{
                width: "100%",
                background: color,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Inquiries
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              You can asks questions before a student takes an assessment.
            </Typography>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: 4 }}
            >
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  disabled={loading || locked}
                  label="Add Inquiry"
                  value={""}
                  onChange={this.createInquiry.bind(this)}
                >
                  {inquiries.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {assessment.inquiries?.map((i) => (
                <Grid item key={i.id}>
                  <Tooltip title={i.question}>
                    <Chip
                      label={i.name}
                      onDelete={() => this.deleteInquiry(i.relationID)}
                    />
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Export
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Export the assessment in order to save it or import it later in a
              different organization.
            </Typography>
            <Button
              onClick={async () => {
                const resp = await getAssessmentById(assessment.id);
                const n = resp.payload;
                fileDownload(JSON.stringify(n, null, "\t"), `${n.name}.json`);
              }}
              color="primary"
              variant="raised"
            >
              <CloudDownload style={{ marginRight: 15 }} /> Export
            </Button>
          </Paper>
          <Paper
            className={loading ? classes.paperLoading : classes.dangerPaper}
            style={{ marginTop: 32 }}
          >
            <Typography display="block" variant="h6">
              Danger Zone
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Delete the Assessment.
            </Typography>
            <Button
              onClick={this.delete.bind(this)}
              style={{ color: "white", background: "#f44336" }}
              variant="raised"
            >
              <Delete style={{ marginRight: 15 }} /> Delete
            </Button>
            <Typography display="block" variant="caption" color="textSecondary">
              The Assessment is currently{" "}
              <b>{locked ? "locked" : "unlocked"}</b>.
            </Typography>
            {/* <Button
              onClick={() => {
                this.setState(
                  (p) => ({ locked: !p.locked }),
                  () => {
                    this.save("locked");
                  }
                );
              }}
              style={{
                color: "white",
                background: locked ? "black" : "#9e9e9e",
              }}
              variant="raised"
            >
              {locked ? (
                <i class="fad fa-lock-open-alt p-right"></i>
              ) : (
                <i class="fad fa-lock-alt p-right"></i>
              )}
              {locked ? "Unlock" : "Lock"}
            </Button> */}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AssessmentMarketing);
