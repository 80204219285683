import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Store from "@material-ui/icons/StoreTwoTone";
import Videocam from "@material-ui/icons/VideocamTwoTone";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import CreditCard from "components/CreditCard";
// constants
import { MARKET } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CreditsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    credits: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedCredit) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCredit);
    }
  }

  render() {
    const { credits, history, classes } = this.props;

    if (credits.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Grid item>
            <Videocam
              style={{ fontSize: 70 }}
              className={classes.darkModeFix}
            />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No Private Sessions.
            </Typography>
            <Typography display="block" variant="body" color="secondary">
              Visit the Market to Purchase Private Sessions.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: 20,
              }}
              onClick={() => history.push(`${MARKET}?index=2`)}
            >
              <Store style={{ paddingRight: 10 }} /> Market
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <Grid container spacing={2} alignItems="stretch">
          {credits &&
            credits.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CreditCard key={e.id} credit={e} history={history} />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CreditsList);
