import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import withStyles from "@material-ui/styles/withStyles";
// custom
import Card from "components/Card";
import CreateCard from "components/CreateCard";
import FormBilling from "components/FormBilling";
import FormPersonalInfo from "components/FormPersonalInfo";
// custom
import FormResetPassword from "components/FormResetPassword";
// reactor
import Page from "components/Page";
import PaymentsList from "components/PaymentsList";
// constant
import { STRIPE_TOKEN } from "config";
import PropTypes from "prop-types";
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
// styles
import styles from "./styles";

class WrapperMyAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refresh: PropTypes.func,
    updateUserPassword: PropTypes.func,
    updateCustomer: PropTypes.func,
    customer: PropTypes.object,
    cards: PropTypes.array,
    deleteCard: PropTypes.func,
    setCardAsFavorite: PropTypes.func,
    createCard: PropTypes.func,
    updateCard: PropTypes.func,
    loading: PropTypes.bool,
    app: PropTypes.object,

    organizations: PropTypes.array,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    joinOrganization: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter } = this.context;
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      addCardCollapsed: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const {
      updateUserPassword,
      updateUser,
      user,
      refresh,
      cards,
      updateCard,
      deleteCard,
      createCard,
      setCardAsFavorite,
      app,
      customer,
      updateCustomer,
      history,
    } = this.props;

    const { index, addCardCollapsed } = this.state;

    switch (index) {
      // case 0:
      //   return (
      //     <StatementsList
      //       statements={customer ? customer.statements : []}
      //       history={history}
      //     />
      //   );
      // case 0:
      //   return (
      //     <InvoicesList
      //       invoices={customer ? customer.invoices : []}
      //       history={history}
      //     />
      //   );
      case 0:
        return (
          <PaymentsList
            payments={customer ? customer.payments : []}
            history={history}
          />
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {customer ? (
                  <Grid item xs={12}>
                    <Paper style={{ padding: 20 }}>
                      <Typography display="block" variant="h6">
                        Billing Information
                      </Typography>
                      <br />
                      <FormBilling
                        customer={customer}
                        updateCustomer={updateCustomer}
                        refresh={refresh}
                      />
                    </Paper>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <Paper style={{ padding: 20 }}>
                    <Typography display="block" variant="h6">
                      Your default purchase preference
                    </Typography>
                  </Paper>
                </Grid> */}
                {cards && cards.length ? (
                  <Grid item xs={12}>
                    <Paper style={{ padding: 20 }}>
                      <Typography display="block" variant="h6">
                        Your Credit and Debit Cards
                      </Typography>
                      <br />
                      <Grid container spacing={2}>
                        {cards &&
                          cards.map((card) => (
                            <Grid item key={card.id} xs={12}>
                              <Card
                                dardMode={app.darkMode}
                                card={card}
                                key={card.id}
                                updateCard={updateCard}
                                deleteCard={deleteCard}
                                createCard={createCard}
                                setCardAsFavorite={setCardAsFavorite}
                                refresh={refresh}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Paper>
                  </Grid>
                ) : (
                  []
                )}
                <Grid item xs={12}>
                  <Paper style={{ padding: 20 }}>
                    <Typography display="block" variant="h6">
                      Add a New Payment Method
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      We accept all major credit and debit cards. We use Stripe
                      to secure your transaction. Your information is saved with
                      Stripe servers only.
                    </Typography>
                    <br />
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.setState((prevState) => ({
                          addCardCollapsed: !prevState.addCardCollapsed,
                        }))
                      }
                    >
                      {addCardCollapsed ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                      Add a Card
                    </Button>
                    <Collapse in={addCardCollapsed} style={{ paddingTop: 20 }}>
                      <StripeProvider apiKey={STRIPE_TOKEN}>
                        <Elements>
                          <CreateCard
                            createCard={createCard}
                            refresh={refresh}
                            onClose={() =>
                              this.setState({ addCardCollapsed: false })
                            }
                          />
                        </Elements>
                      </StripeProvider>
                    </Collapse>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormPersonalInfo
                    refresh={refresh}
                    user={user}
                    updateUser={updateUser}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormResetPassword
                    user={user}
                    updateUserPassword={updateUserPassword}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Typography display="block" variant="h6">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="My Account"
        loading={loading}
        loadingMessage={"Loading Account Information"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            {/* <Tab
              label="Statements"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            /> */}
            {/* <Tab
              label="Invoices"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            /> */}
            <Tab
              label="Payment History"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Payment Options"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {/* <Tab
              label="Organizations"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            /> */}
            <Tab
              label="Login & Security"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMyAccountPage);
