import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// helpers
import { formattedPrice } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableTotal extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <Typography display="block" variant="body2">
        {formattedPrice(datum.total)}
      </Typography>
    );
  }
}

export default withStyles(styles)(TableTotal);
