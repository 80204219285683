import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "calendy";
const students = `/${service}/students`;

export function getStudents() {
  const url = `${students}`;
  return {
    method: GET,
    url,
  };
}

export function getMyStudent() {
  const url = `${students}/me`;
  return {
    method: GET,
    url,
  };
}

export function getStudentById(id) {
  const url = `${students}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getCurrentStudent() {
  const url = `${students}/me`;
  return {
    method: GET,
    url,
  };
}

export function updateStudent(id, body) {
  const url = `${students}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudent(id) {
  const url = `${students}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudent(body) {
  const url = `${students}`;
  return {
    method: POST,
    url,
    body,
  };
}
