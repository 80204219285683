import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperAssessmentPage from "../component/WrapperAssessmentPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  assessments: state.assessments,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessments: () => dispatch(Actions.getAssessments()),
  getAssessmentById: (...args) => dispatch(Actions.getAssessmentById(...args)),
});
class AssessmentPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    assessments: PropTypes.array,
    getAssessments: PropTypes.func,
    getAssessmentById: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, assessments } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: assessments.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getAssessments } = this.props;
    await Promise.all([getAssessments()]);

    this.setState({ loading: false });
  }

  render() {
    const { history, assessments, getAssessmentById } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperAssessmentPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        assessments={assessments}
        getAssessmentById={getAssessmentById}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPage);
