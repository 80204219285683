import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "harmony";
const assessmentInquiries = `${REACT_APP_API_PREFIX}/${service}/assessmentInquiries`;

export function getAssessmentInquiries(filters) {
  const url = `${assessmentInquiries}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAssessmentInquiryById(id) {
  const url = `${assessmentInquiries}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateAssessmentInquiry(id, body) {
  const url = `${assessmentInquiries}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAssessmentInquiry(id) {
  const url = `${assessmentInquiries}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAssessmentInquiry(body) {
  const url = `${assessmentInquiries}`;
  return {
    method: POST,
    url,
    body,
  };
}
