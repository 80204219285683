import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

// custom
import Banner from "components/Banner";

// styles
import styles from "./styles";

class Banners extends Component {
  static propTypes = {
    classes: PropTypes.object,
    banners: PropTypes.array,
  };

  render() {
    const { banners } = this.props;

    return (
      <Grid container spacing={2}>
        {banners.map((b) => (
          <Grid
            item
            style={{
              position: "relative",
              flex: 1,
              padding: 16,
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <Banner {...b} />
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(Banners);
