import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBackIos";
import Factor from "@material-ui/icons/FindReplace";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FactorEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    factor: PropTypes.object,
    deleteFactor: PropTypes.func,
    updateFactor: PropTypes.func,
    refresh: PropTypes.func,
    back: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { factor } = this.props;
    this.state = {
      ...factor,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateFactor, refresh, factor } = this.props;

    if (factor[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateFactor(factor.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteFactor, back, refresh, factor } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteFactor(factor.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Factor has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      back();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, back, disabled } = this.props;

    const { name, description, loading } = this.state;

    return (
      <Grid container alignItems="center" className={classes.container}>
        <Grid item>
          <IconButton size="small" onClick={back}>
            <Back fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography display="block" variant="h6">
            {name.length > 20 ? `${name.substring(0, 20)}...` : name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Factor />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Name"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("name");
            }}
            disabled={disabled}
            fullWidth
            value={name}
            onBlur={() => this.save("name")}
            onChange={this.handleChange("name")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("description");
            }}
            fullWidth
            disabled={disabled}
            multiline
            value={description}
            onBlur={() => this.save("description")}
            onChange={this.handleChange("description")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{
              background: "#f44336",
              color: "white",
            }}
            disabled={disabled}
            onClick={this.delete.bind(this)}
          >
            Delete Factor
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FactorEdit);
