import { GET, PUT, DELETE, POST } from "constants/methods";
const timeAssets = "/calendy/timeAssets";

export function getTimeAssets(filters) {
  const url = `${timeAssets}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getTimeAssetById(id) {
  const url = `${timeAssets}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTimeAsset(id, body) {
  const url = `${timeAssets}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTimeAsset(id) {
  const url = `${timeAssets}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTimeAsset(body) {
  const url = `${timeAssets}`;
  return {
    method: POST,
    url,
    body,
  };
}
