import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROFILES,
  GET_PROFILE_BY_ID,
  DELETE_PROFILE,
  UPDATE_PROFILE,
  CREATE_PROFILE,
} from "constants/profiles";

import * as Api from "api";

export const getProfiles = () => async (dispatch) =>
  networkAction(dispatch, GET_PROFILES, Api.getProfiles, []);

export const getProfileById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROFILE_BY_ID, Api.getProfileById, [id]);

export const deleteProfile = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROFILE, Api.deleteProfile, [id]);

export const createProfile = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROFILE, Api.createProfile, [body]);

export const updateProfile = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROFILE, Api.updateProfile, [id, body]);
