import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Portal from "@material-ui/core/Portal";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Factors from "components/Factors";
// custom
import Question from "components/Question";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import arrow from "./arrow.png";
// styles
import styles from "./styles";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 16;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
});

class AssessmentBuilder extends Component {
  static propTypes = {
    classes: PropTypes.object,
    assessment: PropTypes.object,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    createQuestion: PropTypes.func,
    deleteQuestion: PropTypes.func,
    updateQuestion: PropTypes.func,
    createFactor: PropTypes.func,
    deleteFactor: PropTypes.func,
    updateFactor: PropTypes.func,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      questions: assessment.questions,
    };
    MessageCenter.close("bottom");
    MessageCenter.open("right");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({ questions: nextProps.assessment.questions });
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    const { updateQuestion, refresh } = this.props;
    const { questions } = this.state;
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.questions,
      result.source.index,
      result.destination.index
    );

    this.setState({
      questions: m,
    });
    await updateQuestion(questions[result.source.index].id, {
      order: result.destination.index,
    });
    refresh();
  }

  render() {
    const {
      refresh,
      refreshKey,
      createQuestion,
      deleteQuestion,
      updateQuestion,
      assessment,
      createFactor,
      deleteFactor,
      updateFactor,
      createCategory,
      deleteCategory,
      updateCategory,
      classes,
    } = this.props;

    const { MessageCenter } = this.context;

    const {
      questions,
      categoryCallback,
      selectedQuestion,
      filter,
    } = this.state;

    let q = questions;
    if (filter) {
      q = q.filter((qu) => qu.categoryID === filter.id);
    }

    return (
      <Grid container style={{ height: 300 }}>
        <DragDropContext
          onDragEnd={this.onDragEnd.bind(this)}
          key={refreshKey}
          m
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {q &&
                  q.map((question, index) => (
                    <Draggable
                      isDragDisabled={filter !== undefined || assessment.locked}
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                    >
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(
                            s.isDragging,
                            p.draggableProps.style
                          )}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              opacity:
                                categoryCallback &&
                                selectedQuestion.id !== question.id
                                  ? 0.3
                                  : 1,
                            }}
                          >
                            <Question
                              setCategoryCallBack={(cc, q) =>
                                this.setState({
                                  categoryCallback: cc,
                                  selectedQuestion: q,
                                })
                              }
                              isDragging={
                                s.isDragging ||
                                (selectedQuestion &&
                                  selectedQuestion.id === question.id)
                              }
                              disabled={assessment.locked}
                              refresh={refresh}
                              question={question}
                              createQuestion={createQuestion}
                              deleteQuestion={deleteQuestion}
                              updateQuestion={updateQuestion}
                              clearCallback={() =>
                                this.setState({
                                  categoryCallback: undefined,
                                  selectedQuestion: undefined,
                                })
                              }
                            />
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Portal container={MessageCenter.getPortalContainer("right")}>
          <Factors
            filter={(category) => this.setState({ filter: category })}
            filterValue={filter}
            assessment={assessment}
            createFactor={createFactor}
            deleteFactor={deleteFactor}
            updateFactor={updateFactor}
            createCategory={createCategory}
            deleteCategory={deleteCategory}
            updateCategory={updateCategory}
            refresh={refresh}
            refreshKey={refreshKey}
            select={categoryCallback}
          />
        </Portal>
        <Portal container={MessageCenter.getPortalContainer("bottom")}>
          {categoryCallback ? (
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className={classes.bottomMessage}
            >
              <Grid item>
                <Typography display="block" color="inherit" variant="h6">
                  Assign Category
                </Typography>
                <Typography display="block" color="inherit">
                  Select a category from the right panel to assign it to the{" "}
                  <b>
                    Question {selectedQuestion && selectedQuestion.order + 1}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <img src={arrow} alt="arrow" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() =>
                    this.setState({
                      categoryCallback: undefined,
                      selectedQuestion: undefined,
                    })
                  }
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </Portal>
      </Grid>
    );
  }
}

export default withStyles(styles)(AssessmentBuilder);
