import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Forward30 from "@material-ui/icons/Forward30";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Replay30 from "@material-ui/icons/Replay30";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
// material-ui
import { withStyles } from "@material-ui/styles";
import { Howl } from "howler";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Siriwave from "react-siriwave";
import "./anime.css";
// styles
import styles from "./styles";

class Audio extends Component {
  static propTypes = {
    classes: PropTypes.object,
    src: PropTypes.object,
    onProgress: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { src, onProgress } = this.props;
    const sound = new Howl({
      src: [src],
      format: ["mp3"],
      volume: 0.8,
      autoplay: true,
      xhr: {
        withCredentials: true,
      },
      onend: () => {
        this.setState({
          playing: false,
        });
      },
      onload: () => {
        const interval = setInterval(() => {
          if (!this.state.prevent) {
            const progression = sound.seek(this.state.id);
            if (progression && progression !== this.state.progression) {
              this.setState({
                progression,
              });
              onProgress((progression / this.state.duration) * 100);
            }
          }
        }, 1000);
        this.setState({
          duration: sound.duration(this.state.id),
          volume: sound.volume(null, this.state.id),
          loading: false,
          interval,
        });
      },
      onplay: (id) => {
        this.setState({ id });
      },
    });

    this.state = {
      sound,
      loading: true,
      playing: true,
      progression: 0,
      prevent: false,
    };
  }

  componentWillUnmount() {
    const { sound } = this.state;
    clearInterval(this.state.interval);
    sound.unload();
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      sound,
      id,
      playing,
      progression,
      duration,
      volume,
    } = this.state;

    return (
      <div className="anime">
        {loading ? (
          <Grid
            container
            style={{ height: "100%", width: "100%" }}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress />
              <Typography display="block" variant="caption" color="inherit">
                Loading audio...
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <div>
            <div
              style={{ position: "absolute", width: "100%", marginTop: -10 }}
            >
              <div style={{ maxWidth: 600, margin: "auto" }}>
                <Siriwave
                  speed={0.08}
                  style={"ios9"} // eslint-disable-line
                  cover
                  amplitude={1.6}
                  autostart={false}
                />
              </div>
            </div>
            <div style={{ paddingTop: 70 }}>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  maxWidth: 400,
                  margin: "auto",
                  padding: 20,
                  paddingTop: 50,
                }}
              >
                <Grid item xs={12}>
                  <Slider
                    value={(progression / duration) * 100}
                    aria-labelledby="label"
                    color="inherit"
                    onDragStart={() => this.setState({ prevent: true })}
                    onDragEnd={() => {
                      sound.seek(progression, id);
                      this.setState({ prevent: false });
                    }}
                    onChange={(e, v) => {
                      this.setState({
                        progression: (v * duration) / 100,
                      });
                    }}
                  />
                  <Grid
                    container
                    justify="space-between"
                    style={{ marginTop: 10 }}
                  >
                    <Grid item>
                      <Typography
                        display="block"
                        variant="caption"
                        color="inherit"
                        style={{ opacity: 0.6 }}
                      >
                        {moment
                          .utc(
                            moment
                              .duration({ seconds: progression })
                              .as("milliseconds")
                          )
                          .format("mm:ss")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        variant="caption"
                        color="inherit"
                        style={{ opacity: 0.6 }}
                      >
                        {moment
                          .utc(
                            moment
                              .duration({ seconds: duration })
                              .as("milliseconds")
                          )
                          .format("mm:ss")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <Grid item>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          const newProg =
                            progression - 30 > 0 ? progression - 30 : 0;
                          sound.seek(newProg, id);
                          this.setState({ progression: newProg });
                        }}
                      >
                        <Replay30 />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          if (sound.playing(id)) {
                            sound.pause(id);
                          } else {
                            sound.play(id);
                          }
                          this.setState({ playing: sound.playing(id) });
                        }}
                      >
                        {playing ? (
                          <PauseCircleFilledIcon style={{ fontSize: 40 }} />
                        ) : (
                          <PlayArrowIcon style={{ fontSize: 40 }} />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          const newProg =
                            progression + 30 < duration
                              ? progression + 30
                              : duration;
                          sound.seek(newProg, id);
                          this.setState({ progression: newProg });
                        }}
                      >
                        <Forward30 />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justify="center">
                      <Grid item>
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const newVol = volume - 0.1 > 0 ? volume - 0.1 : 0;
                            sound.volume(newVol, id);
                            this.setState({ volume: newVol });
                          }}
                          style={{ padding: 4 }}
                        >
                          <VolumeDownIcon style={{ fontSize: 14 }} />
                        </IconButton>
                      </Grid>
                      <Grid item style={{ width: 150 }}>
                        <Slider
                          value={volume * 100}
                          aria-labelledby="label"
                          classes={{
                            track: classes.track,
                            thumb: classes.thumb,
                          }}
                          color="inherit"
                          onChange={(e, v) => {
                            sound.volume(v / 100, id);
                            this.setState({
                              volume: v / 100,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const newVol = volume + 0.1 < 1 ? volume + 0.1 : 1;
                            sound.volume(newVol, id);
                            this.setState({ volume: newVol });
                          }}
                          style={{ padding: 4 }}
                        >
                          <VolumeUpIcon style={{ fontSize: 14 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Audio);
