export default (theme) => ({
  container: {
    border: "1px solid rgba(155,155,155,0.3)",
    borderRadius: 4,
    marginTop: 8,
    background: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: "1px solid rgba(155,155,155,0.3)",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 50,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    padding: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  toolbarButton: {
    padding: 5,
  },
  toolbarIcon: {
    fontSize: 18,
  },
  toolbar: {
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
  },
});
