export default (theme) => ({
  paper: {
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    minHeight: 570,
  },
  padding: {
    padding: 16,
  },
  container: {
    padding: 5,
  },
  frame: {
    width: 170,
    maxHeight: 460,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      WebkitAppearance: "none",
    },
    "&::-webkit-scrollbar:vertical": {
      width: 11,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      border:
        "2px solid white" /* should match background, can't be transparent */,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
  format: {
    fontSize: 10,
    border: "solid 1px rgba(155,155,155)",
    padding: "2px 6px",
    marginRight: 8,
    cursor: "pointer",
  },
});
