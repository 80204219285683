import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

// custom
import ShelveGood from "components/ShelveGood";

// styles
import styles from "./styles";

class ShelveGoods extends Component {
  static propTypes = {
    goods: PropTypes.array,
    cart: PropTypes.array,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
    history: PropTypes.object,
  };

  render() {
    const { goods, updateCart, getCurrentCart, history, cart } = this.props;

    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {goods &&
          goods.map((g) => (
            <Grid item key={g.id} xs={12} md={6} lg={4}>
              <ShelveGood
                good={g}
                updateCart={updateCart}
                getCurrentCart={getCurrentCart}
                cart={cart}
                history={history}
              />
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(ShelveGoods);
