import React from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// constants
import { EMAIL } from "constants/regexp";

// styles
import styles from "./styles";

class FormBilling extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    customer: PropTypes.object,
    updateCustomer: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { customer } = this.props;
    this.state = {
      address1: customer.address1,
      address2: customer.address2,
      city: customer.city,
      country: customer.country,
      email: customer.email,
      fullName: customer.fullName,
      state: customer.state,
      zip: customer.zip,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
      hasChanged: true,
    });
  };

  isValid() {
    const { hasChanged, email } = this.state;

    return hasChanged && EMAIL.test(email);
  }

  async updateCustomer() {
    const { NotificationCenter } = this.context;
    const { updateCustomer, customer, refresh } = this.props;
    const {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      state,
      zip,
    } = this.state;

    const resp = await updateCustomer(customer.id, {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      state,
      zip,
    });

    if (resp.success) {
      refresh();
      NotificationCenter.stack(
        {
          title: "Success",
          subtitle: "Your info have been updated.",
          timestamp: new Date().getTime(),
          success: true,
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );

      this.setState({
        hasChanged: false,
      });
    }
  }

  render() {
    const {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      state,
      zip,
    } = this.state;

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="fullName"
              label="Full Name"
              value={fullName}
              onChange={this.handleChange("fullName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Email"
              value={email}
              onChange={this.handleChange("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address1"
              label="Address Line 1"
              value={address1}
              onChange={this.handleChange("address1")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address2"
              label="Line 2"
              value={address2}
              onChange={this.handleChange("address2")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="zip"
              label="Zip"
              value={zip}
              onChange={this.handleChange("zip")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="city"
              label="City"
              value={city}
              onChange={this.handleChange("city")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="state"
              label="State"
              value={state}
              onChange={this.handleChange("state")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="country"
              label="Country"
              value={country}
              onChange={this.handleChange("country")}
            />
          </Grid>
        </Grid>
        <br />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.updateCustomer.bind(this)}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(styles)(FormBilling);
