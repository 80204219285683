import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
// helpers
import { formattedPrice } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class UsagesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    usages: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedUsage) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedUsage);
    }
  }

  render() {
    const { usages } = this.props;

    return (
      <Table
        data={usages}
        meta={[
          {
            path: "date",
            title: "Date",
            transform: (text) => moment(text).parseZone().fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  On {moment(d.date).format("MM/DD/YYYY")}
                </Typography>
              </React.Fragment>
            ),
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
          {
            path: "quantity",
            title: "Quantity",
            transform: (t) => t / 100,
          },
          {
            path: "activityName",
            title: "Tier",
          },
          {
            path: "price",
            title: "Price",
            numeric: true,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        noPaper
        onRowSelect={(p) => console.log(p)}
      />
    );
  }
}

export default withStyles(styles)(UsagesList);
