import { Chip, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloudDownload from "@material-ui/icons/CloudDownload";
import LiveHelp from "@material-ui/icons/LiveHelpTwoTone";
import Store from "@material-ui/icons/StoreTwoTone";
// material-ui
import { withStyles } from "@material-ui/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import Table from "components/TablePagination";
import { REACT_APP_API } from "config";
import { MARKET } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SurveysList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    surveys: PropTypes.array,
    getSurveys: PropTypes.func,
    onSelect: PropTypes.func,
  };

  onSelect(selectedSurvey) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSurvey);
    }
  }

  render() {
    const { surveys, history, classes, getSurveys } = this.props;

    if (surveys.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Grid item>
            <LiveHelp
              style={{ fontSize: 70 }}
              className={classes.darkModeFix}
            />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No Assessments.
            </Typography>
            <Typography display="block" variant="body" color="secondary">
              Visit the Market to Purchase Courses.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: 20,
              }}
              onClick={() => history.push(MARKET)}
            >
              <Store style={{ paddingRight: 10 }} /> Market
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <AdvancedSearch
          history={history}
          location={window.location}
          refresh={getSurveys}
          keyword="surveys"
          metaDataEndpoint="/harmony/surveys/searchMetadata"
        >
          <Table
            data={surveys}
            noToolbar
            meta={[
              {
                path: "assessmentIcon",
                title: "",
                render: (t) => (
                  <img
                    alt="assessment icon"
                    className={classes.darkMode}
                    src={t}
                    style={{
                      height: 40,
                    }}
                  />
                ),
              },
              {
                path: "assessmentName",
                title: "Name",
              },
              {
                path: "userFullName",
                title: "Student",
              },
              {
                path: "date",
                title: "Date",
                render: (t, d) => (
                  <div>
                    {(d.answersIdx / d.questionsCount) * 100 > 0 ? (
                      <Typography display="block" variant="body2">
                        Started {moment(d.dateTaken).calendar()}
                      </Typography>
                    ) : (
                      <Typography display="block" variant="body2">
                        Not Started Yet
                      </Typography>
                    )}
                  </div>
                ),
              },
              {
                path: "address",
                title: "Progression",
                render: (t, d) => (
                  <div style={{ color: d.assessmentColor }}>
                    <div className={classes.circularContainer}>
                      <CircularProgress
                        className={classes.back}
                        variant="static"
                        value={100}
                      />
                      <CircularProgress
                        className={classes.fore}
                        variant="static"
                        color="inherit"
                        value={(d.answersIdx / d.questionsCount) * 100}
                      />
                      <Typography
                        display="block"
                        variant="body2"
                        color="inherit"
                        className={classes.value}
                      >
                        {Math.round((d.answersIdx / d.questionsCount) * 100)}
                        <span style={{ fontSize: 10 }}>%</span>
                      </Typography>
                    </div>
                  </div>
                ),
              },
              {
                path: "id",
                title: "Download",
                render: (t, d) => (
                  <Chip
                    label="PDF Profile"
                    disabled={!(d.answersIdx === d.questionsCount)}
                    icon={<CloudDownload />}
                    onClick={() =>
                      window.open(
                        `${REACT_APP_API}/harmony/surveys/${t}/profile`,
                        "_blank"
                      )
                    }
                  />
                ),
              },
            ]}
            title={"Survey"}
            // onRowSelect={this.onSelect.bind(this)}
            // onRowDoubleSelect={this.onDoubleSelect.bind(this)}
          />
        </AdvancedSearch>
        {/* <Grid container spacing={2} alignItems="stretch">
          {surveys &&
            surveys.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SurveyCard key={e.id} survey={e} history={history} />
              </Grid>
            ))}
        </Grid> */}
      </div>
    );
  }
}

export default withStyles(styles)(SurveysList);
