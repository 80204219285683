import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const teams = `/${service}/teams`;

export function getTeams() {
  const url = `${teams}`;
  return {
    method: GET,
    url,
  };
}

export function getTeamUsers(id) {
  const url = `${teams}/${id}/users`;
  return {
    method: GET,
    url,
  };
}

export function getTeamById(id) {
  const url = `${teams}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTeam(id, body) {
  const url = `${teams}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTeam(id) {
  const url = `${teams}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTeam(body) {
  const url = `${teams}`;
  return {
    method: POST,
    url,
    body,
  };
}
