import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Category from "@material-ui/icons/Category";
import Edit from "@material-ui/icons/Edit";
import Grain from "@material-ui/icons/Grain";
import Right from "@material-ui/icons/KeyboardArrowRight";
import Eye from "@material-ui/icons/RemoveRedEye";
import CloseEye from "@material-ui/icons/VisibilityOff";
import arrayMove from "array-move";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// styles
import styles from "./styles";

class Factor extends Component {
  static propTypes = {
    classes: PropTypes.object,
    filerValue: PropTypes.object,
    factor: PropTypes.object,
    disabled: PropTypes.bool,
    select: PropTypes.bool,
    refresh: PropTypes.func,
    onFactorSelect: PropTypes.func,
    onCategorieselect: PropTypes.func,
    updateCategory: PropTypes.func,
    onNewCategory: PropTypes.func,
    reorder: PropTypes.bool,
    filter: PropTypes.func,
    filterValue: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { factor } = this.props;
    this.state = {
      ...factor,
    };
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { updateCategory, refresh } = this.props;
    const categoryToUpdate = this.state.categories[oldIndex];
    updateCategory(categoryToUpdate.id, { order: newIndex }).then(refresh);
    this.setState(({ categories }) => ({
      categories: arrayMove(categories, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    if (
      !(
        e.target.id === "grab" ||
        (e.target.nearestViewportElement &&
          e.target.nearestViewportElement.id === "grab")
      )
    ) {
      return true; // Return true to cancel sorting
    }
  };

  render() {
    const {
      classes,
      factor,
      select,
      onCategorieselect,
      onFactorSelect,
      onNewCategory,
      reorder,
      filter,
      filterValue,
      disabled,
    } = this.props;

    const { categories } = this.state;

    const SortableItem = SortableElement((g) => (
      <div style={{ zIndex: 99999 }}>
        <MenuItem
          divider={Number(g.index) !== factor.categories.length - 1}
          onClick={
            select ? () => select(g.value) : () => onCategorieselect(g.value)
          }
        >
          {select || reorder ? (
            <ListItemIcon style={{ width: 30, padding: 0, margin: 0 }}>
              {select ? (
                <Radio disabled />
              ) : (
                <Grain style={{ fontSize: 14 }} id="grab" />
              )}
            </ListItemIcon>
          ) : (
            <ListItemIcon style={{ width: 30, padding: 0, margin: 0 }}>
              {filterValue && filterValue.id === g.value.id ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    filter(undefined);
                  }}
                >
                  <Eye style={{ color: "#2196f3" }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    filter(g.value);
                  }}
                >
                  <CloseEye />
                </IconButton>
              )}
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography display="block" variant="body2">
                {g.value.name}
              </Typography>
            }
            secondary={
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {`+${g.value.highQualifierName} -${g.value.lowQualifierName}`}
              </Typography>
            }
          />
          {!select && !reorder ? (
            <ListItemSecondaryAction>
              <Right />
            </ListItemSecondaryAction>
          ) : (
            []
          )}
        </MenuItem>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div>
        {items &&
          items.map((value, index) => (
            <SortableItem
              key={value.id}
              index={index}
              value={value}
              disabled={!reorder}
            />
          ))}
      </div>
    ));

    return (
      <div>
        <ListSubheader
          className={classes.listSubheader}
          onClick={() => onFactorSelect(factor)}
        >
          <Grid container alignItems="center" spacing={1}>
            {reorder ? (
              <Grid item>
                <Grain style={{ height: 15, paddingTop: 12, marginLeft: -5 }} />
              </Grid>
            ) : (
              []
            )}
            <Grid item>
              <Typography
                display="block"
                style={{ fontWeight: 600, fontSize: 18 }}
              >
                {factor.name}
              </Typography>
            </Grid>
            {!select && !reorder ? (
              <Grid item>
                <IconButton fontSize="small">
                  <Edit fontSize="small" />
                </IconButton>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </ListSubheader>
        <div>
          <SortableList
            axis="y"
            items={categories || []}
            onSortEnd={this.onSortEnd}
            shouldCancelStart={this.shouldCancelStart}
          />
          {!select && !reorder ? (
            <div style={{ textAlign: "right", paddingRight: 8 }}>
              <Chip
                label="New Category"
                disabled={disabled}
                onClick={() => onNewCategory(factor)}
                variant="outlined"
                style={{
                  margin: "6px 0px 6px 10px",
                  color: "#2196f3",
                  borderColor: "#2196f3",
                }}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Category style={{ color: "#2196f3" }} />
                  </Avatar>
                }
              />
            </div>
          ) : (
            []
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Factor);
