import {
  Backdrop,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import LinearProgress from "@material-ui/core/LinearProgress";
// material-ui
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import withWidth from "@material-ui/core/withWidth";
import Back from "@material-ui/icons/ArrowBack";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Question from "@material-ui/icons/Help";
import { withStyles } from "@material-ui/styles";
import Markdown from "components/Markdown";
// reactor
import Page from "components/Page";
import PDF from "components/PDF";
// Customer
import Speak from "components/Speak";
// helper
// config
import { QUESTION_BACKGROUND_LOGO, REACT_APP_API } from "config";
// constants
import { SURVEYS } from "constants/routes";
// helpers
import { validNumber } from "helpers/form/typeCheckers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
// styles
import styles from "./styles";

function PersonalityCheckboxes(props) {
  const [options, setOptions] = React.useState([]);

  const onChange = (e, value) => {
    const checked = e.target.checked;
    let index = options.findIndex((o) => o === value);
    if (checked) {
      if (index === -1) {
        props.updatePersonality(props.inquiry, [...options, value]);
        setOptions([...options, value]);
      }
    } else {
      if (index >= 0) {
        const _options = [...options];
        _options.splice(index, 1);
        setOptions(_options);
        props.updatePersonality(props.inquiry, _options);
      }
    }
  };

  return (
    <div>
      <Typography>{props.inquiry.question}</Typography>
      <Grid
        conatiner
        style={{
          marginLeft: 16,
        }}
      >
        {props.data.map((d) => (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  checked={options.find((o) => o === d)}
                  style={{
                    color: props.color,
                  }}
                />
              }
              onChange={(e) => onChange(e, d)}
              label={d}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

class WrapperSurveyPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    survey: PropTypes.object,
    assessment: PropTypes.object,
    loading: PropTypes.bool,
    createPersonalitie: PropTypes.func,
    answerQuestion: PropTypes.func,
    fullScreen: PropTypes.bool,
    fake: PropTypes.bool,
    width: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    const { width } = this.props;
    this.state = {
      answersIdx: undefined,
      inquiried: false,
      activity: "",
      age: "",
      children: "",
      hours: "",
      relationship: "",
      sex: "",
      years: "",
      pendingLoading: false,
      drawerOpen: width !== "xs" && width !== "sm",
      personalities: {},
    };
    this.ReactVideoPlayer = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.survey && this.props.survey === undefined) {
      this.setState({
        answersIdx: nextProps.survey.answersIdx || 0,
        completed: nextProps.survey.completed,
        inquiried: nextProps.survey.personalities?.length > 0 ?? false,
      });
    }
    if (nextProps.width !== this.props.width) {
      const newWidth = nextProps.width;
      if (newWidth !== "xs" && newWidth !== "sm") {
        this.setState({ drawerOpen: true });
      } else {
        this.setState({ drawerOpen: false });
      }
    }
  }

  getLeftDrawer() {
    const { assessment, classes } = this.props;
    const { completed } = this.state;

    return (
      <Grid
        container
        style={{
          width: 320,
          padding: 20,
          height: "100vh",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item>
          <img
            src={assessment.icon}
            className={classes.darkMode}
            alt="assessment"
            style={{ maxWidth: "100%", maxHeight: 50 }}
          />
          <Typography display="block" variant="h6">
            {assessment.name}
          </Typography>
          <Markdown source={assessment.description} />
        </Grid>
        {!completed ? (
          <Grid
            item
            style={{
              background: "rgba(155,155,155, 0.1)",
              border: "solid 1px rgba(155,155,155, 0.3)",
              borderRadius: 8,
              padding: 10,
            }}
          >
            <Typography display="block" variant="subheading">
              Instructions
            </Typography>
            <Markdown source={assessment.instructions} />
          </Grid>
        ) : (
          <Grid
            item
            style={{
              background: "rgba(155,155,155, 0.1)",
              border: "solid 1px rgba(155,155,155, 0.3)",
              borderRadius: 8,
              padding: 10,
            }}
          >
            <Typography display="block" variant="subheading">
              Next Actions
            </Typography>
            <Markdown source={assessment.nextSteps} />
          </Grid>
        )}
      </Grid>
    );
  }

  handleChange = (name, numeric) => (event) => {
    const { target } = event;
    const { value } = target;

    if (numeric === true) {
      if (validNumber(value)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  async answerQuestion(value) {
    const { answerQuestion, survey } = this.props;
    const { answersIdx } = this.state;
    const question = survey.assessment.questions[answersIdx];

    this.setState({ answerPending: true });

    await answerQuestion({
      questionID: question.id,
      value,
      surveyID: survey.id,
    });

    const completed = answersIdx + 1 === survey.questionsCount;

    this.setState({
      answerPending: false,
      answersIdx: answersIdx + 1,
      completed,
    });
  }

  updatePersonality(inquiry, answer) {
    const { survey } = this.props;
    const { personalities } = this.state;
    const _personalities = { ...personalities };

    _personalities[inquiry.id] = {
      surveyID: survey.id,
      inquiryID: inquiry.id,
      answer: JSON.stringify(answer),
    };

    this.setState({ personalities: _personalities });
  }

  async createPersonalities() {
    const { createPersonalitie } = this.props;
    const { personalities } = this.state;
    this.setState({ loading: true });
    const proms = [];
    for (const key in personalities) {
      if (Object.hasOwnProperty.call(personalities, key)) {
        const personality = personalities[key];
        proms.push(createPersonalitie(personality));
      }
    }

    await Promise.all(proms);
    this.setState({ inquiried: true, loading: false });
  }

  render() {
    const {
      assessment,
      survey,
      loading,
      classes,
      history,
      fake,
      width,
    } = this.props;
    const inquiries = [];
    let hasInquiry = survey?.assessment?.inquiries?.length || false;
    if (hasInquiry) {
      for (const key in survey.assessment.inquiries) {
        if (survey.assessment.inquiries.hasOwnProperty(key)) {
          const inquiry = survey.assessment.inquiries[key];
          const data = inquiry.data ? JSON.parse(inquiry.data) : [];
          switch (inquiry.kind) {
            case "text":
              inquiries.push(
                <TextField
                  fullWidth
                  label={inquiry.question}
                  onChange={(e) =>
                    this.updatePersonality(inquiry, e.target.value)
                  }
                />
              );
              break;
            case "select":
              inquiries.push(
                <TextField
                  label={inquiry.question}
                  fullWidth
                  select
                  onChange={(e) =>
                    this.updatePersonality(inquiry, e.target.value)
                  }
                >
                  {data.map((d) => (
                    <MenuItem value={d}>{d}</MenuItem>
                  ))}
                </TextField>
              );
              break;
            case "checkbox":
              inquiries.push(
                <PersonalityCheckboxes
                  inquiry={inquiry}
                  data={data}
                  color={assessment.color}
                  updatePersonality={this.updatePersonality.bind(this)}
                />
              );
              break;
            case "radio":
              inquiries.push(
                <FormControl component="fieldset">
                  <Typography>{inquiry.question}</Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    style={{ marginLeft: 16 }}
                    onChange={(e) =>
                      this.updatePersonality(inquiry, e.target.value)
                    }
                  >
                    {data.map((d) => (
                      <FormControlLabel
                        value={d}
                        control={<Radio style={{ color: assessment.color }} />}
                        label={d}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
              break;
            default:
              break;
          }
        }
      }
    }

    const {
      answersIdx,
      answerPending,
      completed,
      inquiried,
      drawerOpen,
      pendingLoading,
    } = this.state;

    let currentQuestion;
    if (assessment && assessment.questions && assessment.questions.length) {
      currentQuestion = assessment.questions[answersIdx];
    }

    if (answersIdx === 0 && hasInquiry && !inquiried) {
      return (
        <Page
          helmet="Survey"
          loadingMessage={"Loading your Assessment"}
          loading={loading}
          noPadding
          header={
            assessment && assessment.id ? (
              <Grid
                container
                style={{
                  background: assessment.color,
                  color: "white",
                  padding: 10,
                }}
                justify="space-between"
              >
                <Grid item>
                  <Chip
                    label={"Back"}
                    className={classes.chip}
                    onClick={() => history.push(SURVEYS)}
                    variant="outlined"
                    classes={{
                      avatar: classes.avatar,
                      icon: classes.icon,
                    }}
                    avatar={
                      <Avatar>
                        <Back style={{ color: "white" }} />
                      </Avatar>
                    }
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item>
                    <Typography display="block" color="inherit">
                      {survey.userFullName}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item>
                    <Typography display="block" color="inherit">
                      {moment(survey.dateTaken).calendar()}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
            ) : (
              []
            )
          }
        >
          <Grid
            container
            style={{
              display: "flex",
              color: "white",
              overflow: "scroll",
              padding: 20,
            }}
            spacing={2}
          >
            <Grow in>
              <Grid item style={{ textAlign: "center" }} xs={12}>
                <img
                  src={assessment.icon}
                  alt="assessment"
                  style={{ height: 150 }}
                />
                <Typography display="block" variant="h4" color="textPrimary">
                  Before you Start the{" "}
                  <b style={{ color: assessment.color }}>
                    {survey.assessmentName}
                  </b>{" "}
                  Assessment...
                </Typography>
                <Typography display="block" color="textSecondary">
                  Please, Answer the Following Question
                  {inquiries.length > 1 ? "s" : ""}:
                </Typography>
              </Grid>
            </Grow>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                style={{ maxWidth: 600, margin: "auto" }}
              >
                {inquiries.map((i) => (
                  <Grid item xs={12}>
                    {i}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item style={{ textAlign: "center" }} xs={12}>
              <Button
                variant="contained"
                disabled={
                  Object.keys(this.state.personalities).length <
                  survey.assessment?.inquiries?.length
                }
                style={{
                  background: assessment.color,
                  color: "white",
                  opacity:
                    Object.keys(this.state.personalities).length <
                    survey.assessment?.inquiries?.length
                      ? 0.3
                      : 1,
                }}
                onClick={this.createPersonalities.bind(this)}
              >
                Start
              </Button>
            </Grid>
          </Grid>
          <Backdrop
            open={this.state.loading}
            style={{
              zIndex: 9999,
              background: assessment.color,
              opacity: 0.6,
            }}
          >
            <div style={{ color: "white", textAlign: "center" }}>
              <CircularProgress style={{ color: "white" }} />
              <Typography style={{ color: "white" }}>Loading...</Typography>
            </div>
          </Backdrop>
        </Page>
      );
    }

    return (
      <Page
        helmet="Survey"
        loadingMessage={"Loading your Assessment"}
        loading={loading}
        fab={
          <Hidden smUp>
            <Fab
              color="primary"
              aria-label="Add"
              onClick={() => this.setState({ drawerOpen: true })}
            >
              <Question fontSize="small" />
            </Fab>
          </Hidden>
        }
        header={
          assessment && assessment.id ? (
            <Grid
              container
              style={{
                background: assessment.color,
                color: "white",
                padding: 10,
              }}
              justify="space-between"
            >
              <Grid item>
                <Chip
                  label={"Back"}
                  className={classes.chip}
                  onClick={() => history.push(SURVEYS)}
                  variant="outlined"
                  classes={{
                    avatar: classes.avatar,
                    icon: classes.icon,
                  }}
                  avatar={
                    <Avatar>
                      <Back style={{ color: "white" }} />
                    </Avatar>
                  }
                />
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  {completed ? (
                    <Grid item style={{ padding: "0px 8px 0px 8px" }}>
                      <Typography display="block" color="inherit">
                        <Button
                          color="inherit"
                          variant="outlined"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            this.setState({ pendingLoading: true });
                            fetch(
                              `${REACT_APP_API}/harmony/surveys/${survey.id}/profile`,
                              {
                                credentials: "include",
                                method: "GET",
                              }
                            )
                              .then((r) => r.blob())
                              .then((f) => {
                                this.setState({ pendingLoading: false });
                                fileDownload(
                                  f,
                                  `${survey.assessmentName}_${survey.userFullName}_${survey.dateTaken}.pdf`
                                );
                              });
                          }}
                        >
                          {pendingLoading ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "white", marginRight: 8 }}
                            />
                          ) : (
                            <CloudDownload style={{ marginRight: 8 }} />
                          )}{" "}
                          {pendingLoading
                            ? "Download Will Begin Shortly"
                            : "Download Profile"}
                        </Button>
                      </Typography>
                    </Grid>
                  ) : (
                    []
                  )}
                  <Hidden smDown>
                    <Grid item style={{ padding: "0px 8px 0px 8px" }}>
                      <Typography display="block" color="inherit">
                        {survey.userFullName}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid item style={{ padding: "0px 8px 0px 8px" }}>
                      <Typography display="block" color="inherit">
                        {moment(survey.dateTaken).calendar()}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid item style={{ padding: "0px 8px 0px 8px" }}>
                    <Typography
                      display="block"
                      variant="inherit"
                      style={{ color: "white" }}
                    >
                      {Math.floor(
                        (answersIdx / assessment.questions.length) * 100
                      )}
                      % <span style={{ fontSize: 10 }}>Completed</span>
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(answersIdx / assessment.questions.length) * 100}
                      classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )
        }
        noPadding
      >
        <div
          style={{
            marginRight: !(width === "xs" || width === "sm") ? 320 : undefined,
            height: "100%",
            display: "flex",
          }}
        >
          {assessment && assessment.id ? (
            <Grow in>
              {!completed ? (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    background: assessment.color,
                    color: "white",
                    overflow: "hidden",
                  }}
                  direction="column"
                >
                  <Grid
                    item
                    style={{ padding: 16, opacity: answerPending ? 0.5 : 1 }}
                  >
                    <Typography
                      display="block"
                      color="inherit"
                      variant="subheading"
                      style={{ opacity: 0.8 }}
                    >
                      Question {answersIdx + 1}/{assessment.questions.length}
                    </Typography>
                    <Divider
                      style={{ background: "rgba(255, 255, 255, 0.3)" }}
                    />
                    <Typography display="block" color="inherit" variant="h3">
                      {currentQuestion.name}
                    </Typography>
                    <Speak text={currentQuestion.name} />
                  </Grid>
                  <Grid
                    item
                    style={{ padding: 16, color: assessment.color, zIndex: 99 }}
                  >
                    <Grid
                      container
                      justify="center"
                      spacing={2}
                      direction="column"
                    >
                      <Grid item style={{ textAlign: "center" }}>
                        <Button
                          color="inherit"
                          variant="contained"
                          disabled={answerPending}
                          className={classes.button}
                          onClick={() => this.answerQuestion(4)}
                        >
                          Very true for me or always
                        </Button>
                      </Grid>
                      <Grid item style={{ textAlign: "center" }}>
                        <Button
                          color="inherit"
                          variant="contained"
                          disabled={answerPending}
                          className={classes.button}
                          onClick={() => this.answerQuestion(3)}
                        >
                          Somewhat true for me or often
                        </Button>
                      </Grid>
                      <Grid item style={{ textAlign: "center" }}>
                        <Button
                          color="inherit"
                          variant="contained"
                          className={classes.button}
                          disabled={answerPending}
                          onClick={() => this.answerQuestion(2)}
                        >
                          Unsure or sometimes
                        </Button>
                      </Grid>
                      <Grid item style={{ textAlign: "center" }}>
                        <Button
                          color="inherit"
                          variant="contained"
                          className={classes.button}
                          disabled={answerPending}
                          onClick={() => this.answerQuestion(1)}
                        >
                          Somewhat untrue for me or rarely
                        </Button>
                      </Grid>
                      <Grid item style={{ textAlign: "center", zIndex: 99 }}>
                        <Button
                          color="inherit"
                          variant="contained"
                          className={classes.button}
                          disabled={answerPending}
                          onClick={() => this.answerQuestion(0)}
                        >
                          Very untrue for me or never
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <img
                    src={QUESTION_BACKGROUND_LOGO}
                    alt="logo"
                    style={{
                      position: "absolute",
                      bottom: 20,
                      left: 20,
                      opacity: 0.08,
                      zIndex: 0,
                      pointerEvents: "none",
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    background: assessment.color,
                    color: "white",
                    overflow: "hidden",
                  }}
                  direction="column"
                >
                  <Grid
                    xs={12}
                    item
                    style={{ width: "100%", position: "relative" }}
                  >
                    <PDF
                      src={
                        fake
                          ? "https://storage.googleapis.com/college101-images/profile.pdf"
                          : `${REACT_APP_API}/harmony/surveys/${survey.id}/profile`
                      }
                    />
                  </Grid>
                  <img
                    src={QUESTION_BACKGROUND_LOGO}
                    alt="logo"
                    style={{
                      position: "absolute",
                      bottom: 20,
                      left: 20,
                      opacity: 0.08,
                    }}
                  />
                </Grid>
              )}
            </Grow>
          ) : (
            []
          )}
        </div>
        <Drawer
          open={drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          anchor="right"
          variant={width === "xs" || width === "sm" ? "" : "persistent"}
          classes={{
            paper:
              width === "xs" || width === "sm"
                ? classes.mobileDrawer
                : classes.drawer,
          }}
        >
          {assessment && assessment.id ? this.getLeftDrawer() : []}
        </Drawer>
      </Page>
    );
  }
}
export default withWidth()(
  withStyles(styles)(withMobileDialog()(WrapperSurveyPage))
);
