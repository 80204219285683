import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./styles";

class Heading extends Component {
  static propTypes = {
    level: PropTypes.number,
    children: PropTypes.node,
    classes: PropTypes.object,
    scrollTo: PropTypes.func,
    setVisible: PropTypes.func,
  };

  onChange(anchor, isVisible) {
    const { setVisible } = this.props;
    if (isVisible && setVisible) {
      setVisible(anchor);
    }
  }

  render() {
    const { level, children, classes } = this.props;
    const text = children[0] ? children[0].props.children : "";
    const anchor = `${text}`.toLowerCase().replace(/ /g, "-");

    return (
      <Grid
        container
        alignItems="center"
        id={anchor}
        style={{ position: "relative" }}
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Typography
            display="block"
            gutterBottom
            variant={`h${level}`}
            classes={{
              h1: classes.h1,
              h2: classes.h2,
              h3: classes.h3,
              h4: classes.h4,
              h5: classes.h5,
              h6: classes.h6,
            }}
          >
            {children}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Heading);
