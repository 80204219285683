export const routeNames = [
  "Default",
  "Signin",
  "MyAccount",
  "Enrollments",
  "Enrollment",
  "Cart",
  "Checkout",
  "Payment",
  "Statement",
  "Invoice",
  "Surveys",
  "Survey",
  "Assessments",
  "Assessment",
  "Market",
  "Teams",
  "Team",
  "Students",
  "Credits",
  "Schedule",
  "Events",
  "Student",
];
