import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "harmony";
const inquiries = `/${service}/inquiries`;

export function getInquiries() {
  const url = `${inquiries}`;
  return {
    method: GET,
    url,
  };
}

export function getInquiryById(id) {
  const url = `${inquiries}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInquiry(id, body) {
  const url = `${inquiries}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInquiry(id) {
  const url = `${inquiries}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInquiry(body) {
  const url = `${inquiries}`;
  return {
    method: POST,
    url,
    body,
  };
}
