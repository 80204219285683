import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const personalities = `/${service}/personalities`;

export function getPersonalities() {
  const url = `${personalities}`;
  return {
    method: GET,
    url,
  };
}

export function getPersonalitieById(id) {
  const url = `${personalities}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePersonalitie(id, body) {
  const url = `${personalities}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePersonalitie(id) {
  const url = `${personalities}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPersonalitie(body) {
  const url = `${personalities}`;
  return {
    method: POST,
    url,
    body,
  };
}
