export default (theme) => ({
  container: {
    padding: 5,
  },
  shadow: {
    height: 10,
    width: "100%",
    position: "absolute",
    bottom: 0,
    background:
      "linear-gradient(to top, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);",
  },
  frame: {
    width: "100%",
    maxHeight: 120,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      WebkitAppearance: "none",
    },
    "&::-webkit-scrollbar:vertical": {
      width: 11,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      border:
        "2px solid white" /* should match background, can't be transparent */,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
});
