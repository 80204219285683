export default (theme) => ({
  container: {
    height: "calc(100% - 20px)",
    position: "relative",
    padding: 10,
  },
  back: {
    color: "rgba(127,127,127,0.5)",
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  circularContainer: {
    position: "relative",
    width: 40,
  },
  value: {
    position: "absolute",
    top: 10,
    textAlign: "center",
    width: "100%",
  },
  darkMode: {
    background:
      theme.palette.type === "dark" ? "rgba(255,255,255,1)" : undefined,
    borderRadius: 4,
    padding: 4,
  },
});
