import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
// reactor
import Page from "components/Page";
// custom
import SurveysList from "components/SurveysList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperSurveyPage extends React.Component {
  static propTypes = {
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    surveys: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { surveys, history } = this.props;

    switch (index) {
      case 0:
        return (
          <SurveysList
            surveys={surveys}
            history={history}
            // onSelect={s => history.push(`${SURVEY}?id=${s.id}`)}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Surveys"
        loading={loading}
        loadingMessage={"Fetching all Assessments"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSurveyPage);
