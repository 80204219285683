export default (theme) => ({
  appGrid: {
    height: 47,
  },
  AppBarGreetings: {
    color: "white",
    padding: 12,
  },
  white: {
    color: "white",
  },
  appBar: {
    background:
      theme.palette.type === "dark" ? "#424242" : theme.palette.secondary[800],
  },
  badge: {
    background: "#f44336",
    color: "white",
    top: "50%",
    right: -3,
    border: `2px solid ${
      theme.palette.type === "light" ? "#37474f" : "#424242"
    }`,
  },
});
