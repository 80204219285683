// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAssessmentPage from "../component/WrapperAssessmentPage";

const mapStateToProps = (state) => ({
  surveys: state.surveys,
  profiles: state.profiles,
  assessments: state.assessments,
  app: state.app,
  user: state.user,
  inquiries: state.inquiries,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessmentById: (...args) => dispatch(Actions.getAssessmentById(...args)),
  getSurveys: (...args) => dispatch(Actions.getSurveys(...args)),
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  getProfiles: (...args) => dispatch(Actions.getProfiles(...args)),
  updateProfile: (...args) => dispatch(Actions.updateProfile(...args)),
  createProfile: (...args) => dispatch(Actions.createProfile(...args)),
  deleteProfile: (...args) => dispatch(Actions.deleteProfile(...args)),
  updateInquiry: (...args) => dispatch(Actions.updateInquiry(...args)),
  createQuestion: (...args) => dispatch(Actions.createQuestion(...args)),
  deleteQuestion: (...args) => dispatch(Actions.deleteQuestion(...args)),
  updateQuestion: (...args) => dispatch(Actions.updateQuestion(...args)),
  createFactor: (...args) => dispatch(Actions.createFactor(...args)),
  deleteFactor: (...args) => dispatch(Actions.deleteFactor(...args)),
  updateFactor: (...args) => dispatch(Actions.updateFactor(...args)),
  createCategory: (...args) => dispatch(Actions.createCategory(...args)),
  deleteCategory: (...args) => dispatch(Actions.deleteCategory(...args)),
  updateCategory: (...args) => dispatch(Actions.updateCategory(...args)),
  updateAssessment: (...args) => dispatch(Actions.updateAssessment(...args)),
  deleteAssessment: (...args) => dispatch(Actions.deleteAssessment(...args)),
  getInquiries: (...args) => dispatch(Actions.getInquiries(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  createAssessmentInquiry: (...args) =>
    dispatch(Actions.createAssessmentInquiry(...args)),
  deleteAssessmentInquiry: (...args) =>
    dispatch(Actions.deleteAssessmentInquiry(...args)),
});
class AssessmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    assessments: PropTypes.array,
    getAssessmentById: PropTypes.func,
    updateAssessment: PropTypes.func,
    createQuestion: PropTypes.func,
    deleteQuestion: PropTypes.func,
    updateQuestion: PropTypes.func,
    createFactor: PropTypes.func,
    deleteFactor: PropTypes.func,
    updateFactor: PropTypes.func,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    updateInquiry: PropTypes.func,
    deleteAssessment: PropTypes.func,
    profiles: PropTypes.array,
    getProfiles: PropTypes.func,
    updateProfile: PropTypes.func,
    createProfile: PropTypes.func,
    deleteProfile: PropTypes.func,
    getSurveys: PropTypes.func,
    surveys: PropTypes.array,
    inquiries: PropTypes.array,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
    createAssessmentInquiry: PropTypes.func,
    deleteAssessmentInquiry: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getSurveys,
      getProfiles,
      getAssessments,
      getInquiries,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    getProfiles();
    this.state = {
      urlParams,
      assessmentID: urlParams.id,
      loading: true,
    };
    getAssessments();
    getInquiries();
    getSurveys([
      { name: "surveys.assessmentID", comparison: "eq", value: urlParams.id },
    ]);
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { assessmentID } = this.state;
    const { getAssessmentById } = this.props;
    console.log("--- refresh ---");
    const resp = await getAssessmentById(assessmentID);

    this.setState({
      assessment: resp.payload,
      loading: false,
      refreshKey: Date.now(),
    });
  }

  render() {
    const {
      location,
      history,
      updateAssessment,
      createQuestion,
      deleteQuestion,
      updateQuestion,
      createFactor,
      deleteFactor,
      updateFactor,
      createCategory,
      deleteCategory,
      updateCategory,
      updateInquiry,
      surveys,
      getAssessmentById,
      deleteAssessment,
      profiles,
      getProfiles,
      updateProfile,
      createProfile,
      deleteProfile,
      assessments,
      getSurveys,
      app,
      user,
      searchUsers,
      createAssessmentInquiry,
      deleteAssessmentInquiry,
      inquiries,
    } = this.props;

    const { urlParams, loading, assessment, refreshKey } = this.state;

    return (
      <WrapperAssessmentPage
        key={assessment?.locked}
        history={history}
        assessments={assessments}
        location={location}
        urlParams={urlParams}
        getSurveys={getSurveys}
        loading={loading}
        assessment={assessment}
        inquiries={inquiries}
        refreshKey={refreshKey}
        refresh={this.refresh.bind(this)}
        createAssessmentInquiry={createAssessmentInquiry}
        deleteAssessmentInquiry={deleteAssessmentInquiry}
        updateAssessment={updateAssessment}
        deleteAssessment={deleteAssessment}
        createQuestion={createQuestion}
        deleteQuestion={deleteQuestion}
        updateQuestion={updateQuestion}
        createFactor={createFactor}
        deleteFactor={deleteFactor}
        updateFactor={updateFactor}
        createCategory={createCategory}
        deleteCategory={deleteCategory}
        updateCategory={updateCategory}
        updateInquiry={updateInquiry}
        getAssessmentById={getAssessmentById}
        profiles={profiles}
        getProfiles={getProfiles}
        updateProfile={updateProfile}
        createProfile={createProfile}
        deleteProfile={deleteProfile}
        surveys={surveys}
        app={app}
        user={user}
        searchUsers={searchUsers}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPage);
