export default (theme) => ({
  container: {
    padding: 10,
    transition: "border-width 0.1s linear",
  },
  inputHover: {
    background: theme.palette.background.default,
    border: "solid 1px rgba(155,155,155, 0.4)",
    padding: 6,
    borderRadius: 4,
    margin: "6px 0px 6px 0px",
    width: "calc(100% - 20px)",
  },
  input: {
    background: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.background.paper}`,
    borderRadius: 4,
    padding: 6,
    margin: "6px 0px 6px 0px",
    width: "calc(100% - 20px)",
  },
  darkModeFix: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
});
