import { Breadcrumbs, Chip, CircularProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import CloudDownload from "@material-ui/icons/CloudDownload";
// components
import Page from "components/Page";
import Table from "components/Table";
import { REACT_APP_API } from "config";
import { STUDENTS } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperStudentPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    surveys: PropTypes.array,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;

    switch (index) {
      case 0:
        return <Typography variant="title">Item One</Typography>;
      case 1:
        return <Typography variant="title">Item Two</Typography>;
      default:
        return (
          <Typography variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading, user, surveys } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Student"
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }} justify="space-between">
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"All Students"}
                  size="small"
                  onClick={() => history.push(STUDENTS)}
                  variant="outlined"
                  icon={<Back />}
                />
              </Breadcrumbs>
            </Grid>
            {user ? (
              <Grid item>
                <Typography variant="h6">
                  {user.firstName} {user.lastName}
                </Typography>
              </Grid>
            ) : (
              []
            )}
            <Grid item></Grid>
          </Grid>
        }
      >
        <Table
          data={surveys}
          noToolbar
          meta={[
            {
              path: "assessmentIcon",
              title: "",
              render: (t) => (
                <img
                  alt="assessment icon"
                  className={classes.darkMode}
                  src={t}
                  style={{
                    height: 40,
                  }}
                />
              ),
            },
            {
              path: "assessmentName",
              title: "Name",
            },
            {
              path: "userFullName",
              title: "Student",
            },
            {
              path: "date",
              title: "Date",
              render: (t, d) => (
                <div>
                  {(d.answersIdx / d.questionsCount) * 100 > 0 ? (
                    <Typography display="block" variant="body2">
                      Started {moment(d.dateTaken).calendar()}
                    </Typography>
                  ) : (
                    <Typography display="block" variant="body2">
                      Not Started Yet
                    </Typography>
                  )}
                </div>
              ),
            },
            {
              path: "address",
              title: "Progression",
              render: (t, d) => (
                <div style={{ color: d.assessmentColor }}>
                  <div className={classes.circularContainer}>
                    <CircularProgress
                      className={classes.back}
                      variant="static"
                      value={100}
                    />
                    <CircularProgress
                      className={classes.fore}
                      variant="static"
                      color="inherit"
                      value={(d.answersIdx / d.questionsCount) * 100}
                    />
                    <Typography
                      display="block"
                      variant="body2"
                      color="inherit"
                      className={classes.value}
                    >
                      {Math.round((d.answersIdx / d.questionsCount) * 100)}
                      <span style={{ fontSize: 10 }}>%</span>
                    </Typography>
                  </div>
                </div>
              ),
            },
            {
              path: "id",
              title: "Download",
              render: (t, d) => (
                <Chip
                  label="PDF Profile"
                  disabled={!(d.answersIdx === d.questionsCount)}
                  icon={<CloudDownload />}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_API}/harmony/surveys/${t}/profile`,
                      "_blank"
                    )
                  }
                />
              ),
            },
          ]}
          title={"Survey"}
          // onRowSelect={this.onSelect.bind(this)}
          // onRowDoubleSelect={this.onDoubleSelect.bind(this)}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStudentPage);
