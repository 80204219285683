import { GET_PROFILES } from "constants/profiles";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_PROFILES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
