import { Grid } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
// components
import CreditsList from "components/CreditsList";
// reactor
import Page from "components/Page";
import TimeAssetList from "components/TimeAssetList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCreditPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    credits: PropTypes.array,
    createCredit: PropTypes.func,
    deleteCredit: PropTypes.func,
    updateCredit: PropTypes.func,
    eventTypes: PropTypes.array,
    events: PropTypes.array,
    timeAssets: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCredit: undefined,
      formCreditOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { credits, history, eventTypes, events, timeAssets } = this.props;

    switch (index) {
      case 0:
        return (
          <Grid
            container
            style={{ maxWidth: 1200, marginLeft: "auto", marginRight: "auto" }}
          >
            <Grid item xs={12}>
              <TimeAssetList
                timeAssets={timeAssets}
                events={events}
                eventTypes={eventTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditsList
                history={history}
                credits={credits}
                onSelect={(selectedCredit) =>
                  this.setState({
                    selectedCredit,
                    formCreditOpen: true,
                  })
                }
              />
            </Grid>
          </Grid>
        );
      default:
    }

    return [];
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Credits"
        loadingMessage={"Fetching all Courses."}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCreditPage);
