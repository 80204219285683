import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperInvoicePage from "../component/WrapperInvoicePage";

const mapStateToProps = (state) => ({
  cards: state.cards,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceById: (...args) => dispatch(Actions.getInvoiceById(...args)),
  getBazaarCustomerById: (...args) =>
    dispatch(Actions.getBazaarCustomerById(...args)),
  getCards: (...args) => dispatch(Actions.getCards(...args)),
  createCard: (...args) => dispatch(Actions.createCard(...args)),
  payInvoice: (...args) => dispatch(Actions.payInvoice(...args)),
});
class InvoicePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getInvoiceById: PropTypes.func,
    getBazaarCustomerById: PropTypes.func,
    updateInvoice: PropTypes.func,
    getCards: PropTypes.func,
    createCard: PropTypes.func,
    cards: PropTypes.array,
    payInvoice: PropTypes.func,
    app: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      invoiceID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { invoiceID } = this.state;
    const { getInvoiceById, getBazaarCustomerById, getCards } = this.props;

    const resp = await getInvoiceById(invoiceID);
    await getCards();
    const cust = await getBazaarCustomerById(resp.payload.customerID);
    this.setState({
      invoice: resp.payload,
      loading: false,
      customer: cust.payload,
    });
  }

  render() {
    const {
      location,
      history,
      cards,
      createCard,
      app,
      payInvoice,
    } = this.props;

    const { urlParams, loading, invoice, customer } = this.state;

    return (
      <WrapperInvoicePage
        history={history}
        location={location}
        urlParams={urlParams}
        invoice={invoice}
        customer={customer}
        loading={loading}
        cards={cards}
        createCard={createCard}
        payInvoice={payInvoice}
        app={app}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
