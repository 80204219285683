import { MARKET } from "constants/routes";
import { checkAccess } from "helpers/auth";
import MarketPage from "./container/MarketPage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: MARKET,
    withSidebar: true,
    withAppBar: true,
    component: MarketPage,
    public: true,
  };
}
