import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const lectures = `/${service}/lectures`;

export function getLectures() {
  const url = `${lectures}`;
  return {
    method: GET,
    url,
  };
}

export function getLectureById(id) {
  const url = `${lectures}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLecture(id, body) {
  const url = `${lectures}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLecture(id) {
  const url = `${lectures}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLecture(body) {
  const url = `${lectures}`;
  return {
    method: POST,
    url,
    body,
  };
}
