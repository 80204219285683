export default (theme) => ({
  container: {
    padding: 5,
    top: 0,
    right: 0,
  },
  transition: {
    transition: "top 0.5s, right 0.5s",
  },
  darkMode: {
    background:
      theme.palette.type === "dark" ? "rgba(255,255,255,1)" : undefined,
    borderRadius: 4,
    padding: 4,
  },
});
