import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Money from "@material-ui/icons/AttachMoney";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableStatus from "components/TableStatus";
import TableTotal from "components/TableTotal";
// constants
import { INVOICE } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class PaymentsList extends Component {
  static propTypes = {
    history: PropTypes.object,
    payments: PropTypes.array,
    onSelect: PropTypes.func,
    simple: PropTypes.bool,
  };

  onSelect(selectedPayment) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedPayment);
    }
  }

  getMeta() {
    return [
      {
        path: "total",
        title: "Total",
        numeric: false,
        component: TableTotal,
      },
      {
        path: "paymentDate",
        title: "Payment Date",
        numeric: false,
        transform: (t) => moment(t).parseZone().fromNow(),
        render: (t, d) => (
          <React.Fragment>
            <Typography display="block" variant="body2">
              {t}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {moment(d.paymentDate).calendar()}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        path: "paid",
        title: "Status",
        numeric: false,
        component: TableStatus,
      },
      // {
      //   path: 'transactionID',
      //   title: 'Transaction ID',
      //   numeric: false,
      // },
      {
        path: "message",
        title: "Message",
        numeric: false,
        transform: (t) => t || "",
      },
    ];
  }

  getSimpleMeta() {
    return [
      {
        path: "paid",
        title: "Status",
        numeric: false,
        component: TableStatus,
      },
      {
        path: "transactionID",
        title: "Transaction ID",
        numeric: false,
      },
    ];
  }

  render() {
    const { payments, history, simple } = this.props;

    if (payments.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <Money style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No Payments
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              View all payment transactions.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={payments}
        meta={simple ? this.getSimpleMeta() : this.getMeta()}
        title={"Payments"}
        onRowSelect={(p) => history.push(`${INVOICE}?id=${p.invoiceID}`)}
      />
    );
  }
}

export default withStyles(styles)(PaymentsList);
