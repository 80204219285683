import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { PLACEHOLDER } from "constants/app";
import { formattedPrice } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Good extends Component {
  static propTypes = {
    classes: PropTypes.object,
    good: PropTypes.object,
  };

  async addToCart(pricing) {
    const { updateCart, getCurrentCart, cart, good } = this.props;

    if (cart.goods && cart.goods.length) {
      cart.goods.push({ id: good.id, quantity: 1, pricingID: pricing.id });
    } else {
      cart.goods = [{ id: good.id, quantity: 1, pricingID: pricing.id }];
    }

    await updateCart(cart.id, cart);
    getCurrentCart();
  }

  render() {
    const { good } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item style={{ width: 92 }}>
          <img
            style={{ width: 75 }}
            alt="assessment"
            // className={classes.darkMode}
            src={
              good.pictures && good.pictures.length
                ? good.pictures[0].url
                : PLACEHOLDER
            }
          />
        </Grid>
        <Grid
          item
          style={{
            width: "calc(100% - 108px)",
          }}
        >
          <Typography display="block" variant="body2">
            {good.title}
          </Typography>
          <Typography display="block" variant="caption" color="textSecondary">
            {good.description && good.description.length > 100
              ? `${good.description.substring(0, 100)}...`
              : good.description}
          </Typography>
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                onClick={() => this.addToCart(good.pricing)}
                label={`${formattedPrice(good.pricing.price)} Buy Now`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Good);
