import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import { PLACEHOLDER } from "constants/app";
// helpers
import { formattedPrice } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CartGood extends Component {
  static propTypes = {
    classes: PropTypes.object,
    cartGood: PropTypes.object,
    updateCart: PropTypes.func,
    refresh: PropTypes.func,
    price: PropTypes.bool,
    divider: PropTypes.bool,
    index: PropTypes.number,
    cart: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { cartGood } = this.props;
    this.state = {
      quantity: cartGood.quantity,
    };
  }

  getQuantity() {
    const JSX = [];
    for (let index = 1; index <= 10; index++) {
      JSX.push(
        <MenuItem key={index} value={index}>
          {index}
        </MenuItem>
      );
    }

    return JSX;
  }

  async changeQty(e) {
    const { updateCart, refresh, cart, index } = this.props;

    const { goods } = cart;
    goods[index].quantity = Number(e.target.value);

    await updateCart(cart.id, cart);
    refresh();
  }

  async deleteCartGood() {
    const { updateCart, cart, index, refresh } = this.props;
    let { goods } = cart;
    goods.splice(index, 1);
    cart.goods = goods.filter((g) => !g.bundled);

    await updateCart(cart.id, cart);
    refresh();
  }

  render() {
    const { classes, cartGood, price, divider, updateCart } = this.props;

    const { NotificationCenter } = this.context;

    const { quantity } = this.state;

    const pricing = cartGood.pricing;

    if (pricing) {
      return (
        <div>
          <Grid
            container
            className={classes.container}
            alignItems="center"
            justify="space-between"
            spacing={2}
          >
            <Grid item>
              <Grid container>
                <Grid item>
                  <div className={classes.image}>
                    <img
                      style={{ height: 45 }}
                      alt="assessment"
                      src={
                        cartGood.pictures && cartGood.pictures.length
                          ? cartGood.pictures[0].url
                          : PLACEHOLDER
                      }
                    />
                  </div>
                </Grid>
                <Grid item style={{ flexGrow: 1, paddingLeft: 10 }}>
                  <Typography
                    display="block"
                    variant="h6"
                    style={{ fontSize: 18, lineHeight: 1 }}
                  >
                    {cartGood.title}
                  </Typography>
                  <Typography display="block" variant="caption">
                    Unit Price: {formattedPrice(cartGood.pricing.price)}
                  </Typography>
                  {updateCart && !cartGood.bundled ? (
                    <React.Fragment>
                      <Link
                        variant="caption"
                        color="textSecondary"
                        style={{ cursor: "pointer", color: "#f44336" }}
                        onClick={() => {
                          NotificationCenter.sweetAlert(
                            {
                              title:
                                "Are you sure you want to remove this item from your cart?",
                              timestamp: new Date().getTime(),
                              error: true,
                            },
                            {
                              cancel: {
                                label: "Cancel",
                                level: "default",
                                callback: () => console.log("Callback"),
                              },
                              confirm: {
                                label: "I am sure",
                                level: "error",
                                callback: this.deleteCartGood.bind(this),
                              },
                            }
                          );
                        }}
                        // onClick={this.deleteCartGood.bind(this)}
                      >
                        Remove from Cart
                      </Link>
                    </React.Fragment>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="qty">Quantity</InputLabel>
                    <Select
                      inputProps={{
                        name: "qty",
                        id: "qty",
                      }}
                      disabled={updateCart === undefined || cartGood.bundled}
                      value={quantity}
                      onChange={this.changeQty.bind(this)}
                    >
                      {this.getQuantity()}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {divider ? <Divider /> : []}
        </div>
      );
    }

    return <div></div>;
  }
}

export default withStyles(styles)(CartGood);
