import { networkAction } from "helpers/network/networkAction";

import {
  GET_FACTORS,
  GET_FACTOR_BY_ID,
  DELETE_FACTOR,
  UPDATE_FACTOR,
  CREATE_FACTOR,
} from "constants/factors";

import * as Api from "api";

export const getFactors = () => async (dispatch) =>
  networkAction(dispatch, GET_FACTORS, Api.getFactors, []);

export const getFactorById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_FACTOR_BY_ID, Api.getFactorById, [id]);

export const deleteFactor = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_FACTOR, Api.deleteFactor, [id]);

export const createFactor = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_FACTOR, Api.createFactor, [body]);

export const updateFactor = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_FACTOR, Api.updateFactor, [id, body]);
