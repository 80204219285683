import { ButtonGroup, Hidden } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import AudioPlayer from "react-h5-audio-player";
// material-ui
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import Previous from "@material-ui/icons/NavigateBefore";
import Next from "@material-ui/icons/NavigateNext";
import { withStyles } from "@material-ui/styles";
import Audio from "components/Audio";
import Markdown from "components/Markdown";
// reactor
import Page from "components/Page";
// config
import { REACT_APP_API } from "config";
import { MARKET } from "constants/routes";
import EnrollmentProgression from "hooks/EnrollmentProgression";
import LectureProgression from "hooks/LectureProgression";
import TableOfContent from "hooks/TableOfContent";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactPlayer from "react-player";
// styles
import styles from "./styles";

class WrapperEnrollmentPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    width: PropTypes.string,
    setEnrollmentProgress: PropTypes.func,
    refreshProgress: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    enrollment: PropTypes.object,
    loading: PropTypes.bool,
    progress: PropTypes.array,
    app: PropTypes.object,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, width } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      drawerOpen: width !== "xs" && width !== "sm",
      refreshCount: 0,
      mode: true,
    };
    this.ReactVideoPlayer = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width) {
      const newWidth = nextProps.width;
      if (newWidth !== "xs" && newWidth !== "sm") {
        this.setState({ drawerOpen: true });
      } else {
        this.setState({ drawerOpen: false });
      }
    }
  }

  onAudioProgress(percentage) {
    const { enrollment, setEnrollmentProgress, refreshProgress } = this.props;

    if (percentage) {
      setEnrollmentProgress(enrollment.id, {
        lectureID: enrollment.lecture.id,
        percentage,
      });

      const { refreshCount } = this.state;

      if (refreshCount === 5) refreshProgress();

      this.setState({
        refreshCount: refreshCount === 5 ? 0 : refreshCount + 1,
      });
    }
  }

  onVideoProgress(played) {
    const {
      progress,
      enrollment,
      setEnrollmentProgress,
      refreshProgress,
    } = this.props;

    const { refreshCount } = this.state;

    if (this.ReactVideoPlayer && played) {
      if (!this.state[`video${enrollment.lecture.id}`]) {
        const prog = progress.find(
          (p) => p.lectureID === enrollment.lecture.id
        );
        if (prog) {
          this.ReactVideoPlayer.current.seekTo(prog.percentage / 100);
        }
        this.setState({ [`video${enrollment.lecture.id}`]: true });
        return;
      }
    }

    const percentage =
      (played.playedSeconds / this.ReactVideoPlayer.current.getDuration()) *
      100;

    if (percentage) {
      setEnrollmentProgress(enrollment.id, {
        lectureID: enrollment.lecture.id,
        percentage,
      });

      if (refreshCount === 5) refreshProgress();

      this.setState({
        refreshCount: refreshCount === 5 ? 0 : refreshCount + 1,
      });
    }
  }

  closeFullscreen() {
    if (document && document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      enrollment,
      setEnrollmentProgress,
    } = this.props;

    const { expirationDate } = enrollment;

    let expired = false;
    if (expirationDate && moment().diff(moment(expirationDate), "days") >= 0) {
      expired = true;
    }

    const { mode } = this.state;

    const { lecture } = enrollment;
    let other = 0;
    let audio = 0;
    let media = [];
    if (lecture && lecture.media) {
      media = lecture.media;
      for (const k in media) {
        if (media.hasOwnProperty(k)) {
          const medium = media[k];
          if (medium.type.indexOf("audio") !== -1) {
            audio += 1;
          } else {
            other += 1;
          }
        }
      }
    }

    if (expired) {
      return (
        <Page helmet={"Expired"} loading={loading}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ flex: 1 }}
          >
            <Grid item style={{ textAlign: "center" }}>
              <Avatar
                style={{
                  width: 150,
                  height: 150,
                  border: "solid 5px white",
                  margin: "auto",
                  boxShadow:
                    "0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
                }}
                src={enrollment.courseBackground}
              />
              <br />
              <Typography
                display="block"
                variant="h5"
                style={{ fontWeight: 600 }}
              >
                The Course Has Expired
              </Typography>
              <Typography display="block" color="textSecondary">
                Your Access to the course has expired.
              </Typography>
              <br />
              <Chip
                label={"Renew Course"}
                color="primary"
                style={{ background: "#2196f3" }}
                onClick={() => {
                  history.push(`${MARKET}?id=1&index=1`);
                }}
              />
            </Grid>
          </Grid>
        </Page>
      );
    }

    return (
      <Page
        helmet={enrollment ? enrollment.courseName : "Loading"}
        loadingMessage={"Loading Enrollment"}
        loading={loading}
        noPadding
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              backgroundImage: `linear-gradient(0deg, rgba(55,71,79, 0.8), rgba(38,50,56, 0.9)), url(${enrollment.courseBackground})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              zIndex: 0,
              height: "100%",
              position: "absolute",
              top: 0,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 0,
              height: "100%",
              width: "100%",
              backdropFilter: "blur(10px)",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              zIndex: 9999999,
              background: "#607d8b",
            }}
          >
            <TableOfContent
              history={history}
              enrollment={enrollment}
              classes={classes}
              progress={this.props.progress}
            />
            <Hidden xsDown>
              <ButtonGroup
                color="primary"
                size="small"
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "white",
                }}
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() => this.setState({ mode: true })}
                  style={{
                    textTransform: "none",
                    color: "white",
                    borderColor: "white",
                    background: mode ? "#ff9800" : undefined,
                  }}
                >
                  Modules
                </Button>
                <Button
                  onClick={() => this.setState({ mode: false })}
                  style={{
                    textTransform: "none",
                    color: "white",
                    borderColor: "white",
                    background: !mode ? "#607d8b" : undefined,
                  }}
                >
                  Lectures
                </Button>
              </ButtonGroup>
            </Hidden>
            <div style={{ padding: 30 }}>
              <Typography
                variant="h5"
                style={{
                  fontFamily: "merriweather",
                  fontWeight: 800,
                  color: "white",
                }}
              >
                {enrollment.courseName}
              </Typography>
            </div>
            <div style={{ padding: 30, top: 20, position: "absolute" }}>
              <Typography
                variant="h5"
                style={{
                  fontFamily: "merriweather",
                  fontWeight: 800,
                  color: "white",
                  textShadow: "0px 4px 16px rgba(0,0,0,0.5)",
                }}
              >
                {enrollment.courseName}
              </Typography>
            </div>
            <Hidden xsDown>
              <div
                style={{
                  textAlign: "center",
                  background: "#607d8b",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!mode ? (
                  <LectureProgression
                    history={history}
                    enrollment={enrollment}
                  />
                ) : (
                  <EnrollmentProgression
                    history={history}
                    enrollment={enrollment}
                  />
                )}
              </div>
            </Hidden>
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <Grid container justify="space-between">
            <Grid item xs={12}></Grid>
            {enrollment && enrollment.previousLecture ? (
              <Grid
                item
                xs={6}
                style={{
                  margin: "10px 0px 10px 00px",
                  textAlign: "left",
                }}
              >
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: 12,
                    textAlign: "left",
                  }}
                  size="small"
                  onClick={() => {
                    history.push({
                      search: `?id=${enrollment.id}&moduleID=${enrollment.previousLecture.moduleID}&lectureID=${enrollment.previousLecture.id}`,
                    });
                  }}
                >
                  <Previous /> Previous: {enrollment.previousLecture.name}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={6} />
            )}
            {enrollment && enrollment.nextLecture ? (
              <Grid
                item
                style={{
                  margin: "10px 0px 10px 00px",
                  textAlign: "right",
                }}
                xs={6}
              >
                <Button
                  style={{
                    textTransform: "none",
                    fontSize: 12,
                    textAlign: "right",
                  }}
                  size="small"
                  onClick={() => {
                    setEnrollmentProgress(enrollment.id, {
                      lectureID: enrollment.lecture.id,
                      percentage: 100,
                    }).then(this.refreshProgress);
                    history.push({
                      search: `?id=${enrollment.id}&moduleID=${enrollment.nextLecture.moduleID}&lectureID=${enrollment.nextLecture.id}`,
                    });
                  }}
                >
                  Continue to: {enrollment.nextLecture.name} <Next />
                </Button>
              </Grid>
            ) : (
              <Grid item xs={6} />
            )}
          </Grid>
        </div>
        <div
          style={{
            maxWidth: 820,
            marginLeft: "auto",
            marginRight: "auto",
            padding: "0px 20px 20px 20px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", paddingBottom: 20 }}
            >
              <Typography
                variant="h5"
                style={{ fontFamily: "merriweather", fontWeight: 600 }}
              >
                {lecture?.name}
              </Typography>
            </Grid>
            {lecture && lecture.file ? (
              <Grid item xs={12}>
                <div
                  style={{
                    background: "#FFFFFF",
                    borderRadius: 4,

                    boxShadow:
                      "0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3)",
                  }}
                >
                  <ReactPlayer
                    onProgress={this.onVideoProgress.bind(this)}
                    ref={this.ReactVideoPlayer}
                    url={lecture.file}
                    controls
                    width="100%"
                    onEnded={() => {
                      this.closeFullscreen();
                    }}
                  />
                </div>
              </Grid>
            ) : (
              ""
            )}
            {audio ? (
              <Grid item xs={12}>
                <Grid container spacine={16}>
                  {media.map((medium) => {
                    if (medium.type.indexOf("audio") !== -1) {
                      return (
                        <Grid item xs={12} key={medium.id}>
                          <Audio
                            src={`${REACT_APP_API}/aptitude/media/${medium.id}/download`}
                            onProgress={this.onAudioProgress.bind(this)}
                          />
                        </Grid>
                      );
                    }
                    return [];
                  })}
                </Grid>
                {/* </Paper> */}
              </Grid>
            ) : (
              <div />
            )}
            {lecture && lecture.description ? (
              <Grid item xs={12} style={{ margin: "20px 0px 10px 0px" }}>
                <Paper
                  style={{
                    padding: 16,
                    background: "#ff9800",
                    color: "white",
                    boxShadow:
                      "0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3)",
                  }}
                >
                  <Typography display="block" variant="body2" color="inherit">
                    <Markdown darkMode source={lecture.description} />
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              []
            )}
            {lecture && lecture.actions ? (
              <Grid item xs={12} style={{ margin: "20px 0px 10px 0px" }}>
                <Paper
                  style={{
                    padding: 16,
                    background: "#3C474D",
                    color: "white",
                    boxShadow:
                      "0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3)",
                  }}
                >
                  <Typography display="block" variant="body2" color="inherit">
                    <Markdown darkMode source={lecture.actions} />
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withWidth()(withStyles(styles)(WrapperEnrollmentPage));
