import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CurriculumItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    module: PropTypes.object,
    moduleID: PropTypes.number,
    lectureID: PropTypes.number,
    enrollmentID: PropTypes.number,
    progress: PropTypes.array,
    closeDrawer: PropTypes.func,
    handleClose: PropTypes.func,
  };

  state = {
    open: false,
  };

  render() {
    const {
      classes,
      module,
      moduleID,
      enrollmentID,
      lectureID,
      history,
      progress,
      closeDrawer,
      handleClose,
    } = this.props;
    const { open } = this.state;

    const selectedModule = module.id === moduleID;

    return (
      <div>
        <ListItem
          button
          divider
          selected={selectedModule}
          onClick={() => this.setState({ open: !open })}
          classes={{
            root: classes.sticky,
            selected: classes.selectedModule,
          }}
        >
          <ListItemText
            primary={module.name}
            secondary={`${module.lectures.length} Lecture${
              module.lectures.length > 1 ? "s" : ""
            }`}
          />
          {open ? (
            <ExpandLess className={classes.darkMode} />
          ) : (
            <ExpandMore className={classes.darkMode} />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {module.lectures.map((l) => {
              const prog = progress.find((p) => p.lectureID === l.id);
              const selectedLecture = l.id === lectureID;
              return (
                <ListItem
                  button
                  onClick={() => {
                    handleClose();
                    history.push({
                      search: `?id=${enrollmentID}&moduleID=${module.id}&lectureID=${l.id}`,
                    });
                    if (closeDrawer) closeDrawer();
                  }}
                  selected={module.id === moduleID && l.id === lectureID}
                  classes={{
                    root: classes.nested,
                    selected: classes.selectedLecture,
                  }}
                  style={{
                    borderLeft: "solid 4px #ff9800",
                  }}
                  key={l.id}
                >
                  <ListItemIcon>
                    {prog && prog.percentage > 98 ? (
                      <CheckCircle color="primary" />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <CircularProgress
                          className={classes.back}
                          variant="static"
                          value={100}
                          size={20}
                        />
                        <CircularProgress
                          size={20}
                          variant="static"
                          value={prog ? prog.percentage : 0}
                          className={classes.fore}
                        />
                      </div>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={l.name}
                    classes={{
                      primary: selectedLecture
                        ? classes.selectedPrimary
                        : classes.primary,
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(CurriculumItem);
