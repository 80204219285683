import { networkAction } from "helpers/network/networkAction";

import {
  GET_TEAM_MEMBERS,
  GET_TEAM_MEMBER_BY_ID,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  CREATE_TEAM_MEMBER,
} from "constants/teamMembers";

import * as Api from "api";

export const getTeamMembers = () => async (dispatch) =>
  networkAction(dispatch, GET_TEAM_MEMBERS, Api.getTeamMembers, []);

export const getTeamMemberById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TEAM_MEMBER_BY_ID, Api.getTeamMemberById, [id]);

export const deleteTeamMember = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TEAM_MEMBER, Api.deleteTeamMember, [id]);

export const createTeamMember = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TEAM_MEMBER, Api.createTeamMember, [body]);

export const updateTeamMember = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TEAM_MEMBER, Api.updateTeamMember, [id, body]);
