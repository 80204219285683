export default (theme) => ({
  container: {
    padding: 5,
  },
  listHeader: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    padding: 16,
  },
});
