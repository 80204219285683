import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";

// mui
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";

// styles
import styles from "./styles";

class CheckoutForm extends Component {
  static propTypes = {
    classes: PropTypes.object,
    submitContainer: PropTypes.object,
    stripe: PropTypes.object,
    onComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      name: "",
      // address_line1: '',
      // address_line2: '',
      // address_city: '',
      // address_state: '',
      // address_zip: '',
      // address_country: 'US',
      currency: "usd",
      card: "",
      complete: false,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCardElementChange = (change) => {
    this.setState({ complete: change.complete });
  };

  async submit() {
    const { createCard, onClose, refresh } = this.props;
    const {
      name,
      // address_line1,
      // address_line2,
      // address_city,
      // address_state,
      // address_country,
      currency,
    } = this.state;

    this.setState({ loading: true });

    const resp0 = await this.props.stripe.createToken({
      name,
      // address_line1,
      // address_line2,
      // address_city,
      // address_state,
      // address_country,
      currency,
    });

    await createCard({
      token: resp0.token.id,
      name,
    });

    refresh();
    onClose();
    this.cardElement.clear();

    this.setState(this.getInitialState());
  }

  render() {
    const { classes } = this.props;
    const {
      name,
      // address_line1,
      // address_line2,
      // address_city,
      // address_state,
      // address_country,
      complete,
    } = this.state;

    return (
      <div>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: 20,
          }}
        >
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Cardholder name *"
              fullWidth
              className={classes.textField}
              value={name}
              onChange={this.handleChange("name")}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              id="line1"
              label="Address Line 1"
              fullWidth
              className={classes.textField}
              value={address_line1}
              onChange={this.handleChange('address_line1')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="line2"
              label="Address Line 2"
              fullWidth
              className={classes.textField}
              value={address_line2}
              onChange={this.handleChange('address_line2')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              label="City"
              className={classes.textField}
              fullWidth
              value={address_city}
              onChange={this.handleChange('address_city')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              className={classes.textField}
              fullWidth
              value={address_state}
              onChange={this.handleChange('address_state')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              className={classes.textField}
              fullWidth
              value={address_country}
              onChange={this.handleChange('address_country')}
            />
          </Grid> */}
        </Grid>
        <CardElement
          onChange={this.handleCardElementChange.bind(this)}
          onReady={(ref) => (this.cardElement = ref)}
        />
        <br />
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.submit}
              style={{
                color: "white",
              }}
              disabled={!complete || name.length === 0}
            >
              Add Card
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
