import { Chip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { REACT_APP_API } from "config";
import AsyncAutocomplete from "hooks/AsyncAutocomplete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormSurvey extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    organizationID: PropTypes.object,

    close: PropTypes.func,
    open: PropTypes.bool,
    users: PropTypes.array,

    survey: PropTypes.object,

    assessments: PropTypes.array,
    createSurvey: PropTypes.func,
    updateSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      assessmentID: "",
      users: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.survey);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(survey) {
    this.setState({
      loading: false,
      assessmentID: survey ? survey.assessmentID : "",
      userUUID: survey ? survey.userUUID : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this survey, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteSurvey, close, refresh, survey } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteSurvey(survey.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Survey has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateSurvey, createSurvey, survey, refresh, close } = this.props;

    const { assessmentID, users } = this.state;
    const { NotificationCenter } = this.context;
    const usersUUID = [];
    for (const key in users) {
      if (users.hasOwnProperty(key)) {
        const user = users[key];
        if (user.id === 0) {
          usersUUID.push(user.tempUUID);
        } else {
          usersUUID.push(user.uuid);
        }
      }
    }

    let resp;
    this.setState({ loading: true });

    resp = await createSurvey({
      assessmentID: Number(assessmentID),
      usersUUID,
    });

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: survey ? "Survey has been updated." : "Survey has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      survey,
      assessments,
      close,
      organizationID,
    } = this.props;

    const { assessmentID, users, loading } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        classes={{
          paperScrollPaper: classes.paper,
        }}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ minWidth: 400 }}>
            <DialogTitle id="responsive-dialog-title">
              {survey ? `${survey.name}` : "New Survey"}
            </DialogTitle>
            <DialogContent className={classes.paper}>
              <DialogContentText>
                {survey
                  ? "To update the survey, fill the form below :"
                  : "Add an assessment to users"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">
                      Select the assessment
                    </InputLabel>
                    <Select
                      fullWidth
                      value={assessmentID}
                      onChange={this.handleChange("assessmentID")}
                    >
                      {assessments &&
                        assessments.map((c) => (
                          <MenuItem key={`c_${c.id}`} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {users &&
                  users.map((u, i) => (
                    <Grid item>
                      <Chip
                        label={`${u.email}`}
                        onDelete={() => {
                          const _u = [...this.state.users];
                          _u.splice(Number(i), 1);
                          this.setState({
                            users: _u,
                          });
                        }}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <AsyncAutocomplete
                    label={"Select the User"}
                    searchEndpoint={`${REACT_APP_API}/account/organizations/${organizationID}/users/search`}
                    bodyTransform={(searchText) => ({ text: searchText })}
                    selectedLabelText={(o) => {
                      if (o) {
                        return `${o.firstName} ${o.lastName}`;
                      }

                      return "";
                    }}
                    onSelect={(o) => {
                      if (o) {
                        const _u = [...this.state.users];
                        _u.push(o);
                        this.setState({
                          users: _u,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {survey && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={this.save.bind(this)}
                disabled={users.length === 0 || !this.state.assessmentID}
              >
                <SaveIcon className={classes.leftIcon} />
                {survey ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormSurvey));
