import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
import Store from "@material-ui/icons/Store";
import { withStyles } from "@material-ui/styles";
import CartGood from "components/CartGood";
// reactor
import Page from "components/Page";
// constants
import { CHECKOUT, MARKET } from "constants/routes";
// helpers
import { formattedPrice } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCartPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    updateCart: PropTypes.func,
    deleteCartCoupon: PropTypes.func,
    emptyCart: PropTypes.func,
    updateCartGood: PropTypes.func,
    deleteCartGood: PropTypes.func,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    loading: PropTypes.bool,
    cart: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = { token: "" };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async applyCoupon(token) {
    const { updateCart, cart, refresh } = this.props;
    const _cart = JSON.parse(JSON.stringify(cart));
    const { coupons } = _cart;

    coupons.push({
      token,
    });

    await updateCart(cart.id, _cart);
    refresh();
    this.setState({
      token: "",
    });
  }

  async deleteCoupon(index) {
    const { updateCart, cart, refresh } = this.props;
    const { coupons } = cart;

    coupons.splice(index, 1);

    await updateCart(cart.id, cart);
    refresh();
  }

  render() {
    const {
      classes,
      cart,
      history,
      refresh,
      refreshKey,
      updateCart,
      emptyCart,
    } = this.props;

    const { token, loading } = this.state;
    const { NotificationCenter } = this.context;

    const goods = cart && cart.goods ? cart.goods : [];

    return (
      <Page
        helmet="Cart"
        loading={cart.id === undefined || loading}
        loadingMessage={"Loading Cart"}
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            direction="row-reverse"
            spacing={2}
            style={{ maxWidth: 1200, margin: "auto" }}
          >
            <Grid item xs={12} md={4}>
              <Paper className={classes.paper}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography display="block" variant="h6">
                      Subtotal ({goods.length} item{goods.length > 1 ? "s" : ""}
                      ):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="h6" color="primary">
                      {formattedPrice(cart.subTotal)}
                    </Typography>
                  </Grid>
                </Grid>
                <ExpansionPanel style={{ boxShadow: "none" }}>
                  <ExpansionPanelSummary
                    style={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography display="block" variant="body2">
                      Coupons
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <InputBase
                      fullWidth
                      className={classes.inputBase}
                      value={token}
                      placeholder="Enter Coupon Code"
                      onChange={this.handleChange("token")}
                    />
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{ color: "white" }}
                      disabled={token.length === 0}
                      onClick={() => this.applyCoupon(this.state.token)}
                    >
                      Apply
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
                <Grid container spacing={2}>
                  {cart &&
                    cart.coupons &&
                    cart.coupons.map((coupon, index) => (
                      <Grid item key={coupon.token}>
                        <Chip
                          onDelete={() => this.deleteCoupon(Number(index))}
                          label={`${coupon.token} - ${coupon.percent}%`}
                        />
                      </Grid>
                    ))}
                </Grid>
                {cart.total !== cart.subTotal ? (
                  <Grid
                    container
                    justify="space-between"
                    style={{ marginTop: 10 }}
                  >
                    <Grid item>
                      <Typography display="block" variant="subheading">
                        Discount:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        variant="subheading"
                        color="primary"
                      >
                        {formattedPrice(cart.subTotal - cart.total)}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item>
                    <Typography display="block" variant="subheading">
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      variant="subheading"
                      color="primary"
                    >
                      {formattedPrice(cart.total)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => history.push(MARKET)}
                    >
                      Continue Shopping
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={goods.length === 0}
                      onClick={() => history.push(CHECKOUT)}
                    >
                      Checkout
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className={classes.cartContent}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography display="block" variant="h6">
                    <ShoppingCart /> Shopping Cart
                  </Typography>
                  <div>
                    <Typography
                      variant="caption"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        NotificationCenter.sweetAlert(
                          {
                            title:
                              "Are you sure you want to remove all items from your cart?",
                            timestamp: new Date().getTime(),
                            error: true,
                          },
                          {
                            cancel: {
                              label: "Cancel",
                              level: "default",
                              callback: () => console.log("Callback"),
                            },
                            confirm: {
                              label: "I am sure",
                              level: "error",
                              callback: () => emptyCart(),
                            },
                          }
                        );
                      }}
                    >
                      Remove all
                    </Typography>
                  </div>
                </div>
                <Divider />
                {goods.length === 0 ? (
                  <div>
                    <Typography
                      display="block"
                      variant="h6"
                      color="textSecondary"
                    >
                      Your Shopping Cart is empty.
                    </Typography>
                    <Typography
                      display="block"
                      variant="body"
                      color="textSecondary"
                    >
                      Visit our Market to See our Products
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 20 }}
                      onClick={() => history.push(MARKET)}
                    >
                      <Store /> Market
                    </Button>
                  </div>
                ) : (
                  <div key={refreshKey}>
                    {goods.map((cartGood, i) => (
                      <CartGood
                        key={cartGood.id}
                        cartGood={cartGood}
                        cart={cart}
                        index={Number(i)}
                        price
                        divider
                        refresh={refresh}
                        updateCart={updateCart}
                      />
                    ))}
                    <br />
                    <div>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        <b>Refund Policy</b>
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        30-Day, 100% Money-Back guarantee if you are not
                        satisfied our products (books sold through this Site,
                        assessments, and online courses).
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCartPage);
