export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const ENROLLMENTS = "/enrollments";
export const ENROLLMENT = "/enrollment";
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const PAYMENT = "/payment";
export const STATEMENT = "/statement";
export const INVOICE = "/invoice";
export const SURVEYS = "/surveys";
export const SURVEY = "/survey";
export const ASSESSMENTS = "/assessments";
export const ASSESSMENT = "/assessment";
export const MARKET = "/market";
export const TEAMS = "/teams";
export const TEAM = "/team";
export const STUDENTS = "/students";
export const CREDITS = "/credits";
export const SCHEDULE = "/schedule";
export const EVENTS = "/events";
export const STUDENT = "/student";
