import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Check from "@material-ui/icons/Check";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableStatus extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;
    let message = "";
    let background = "";
    let Icon = [];
    if (datum.paid) {
      message = "Paid";
      background = "#4caf50";
      Icon = <Check style={{ color: "white" }} />;
    }

    if (!datum.paid) {
      message = "Rejected";
      background = "#f44336";
      Icon = <Warning style={{ color: "white" }} />;
    }

    if (datum.refunded) {
      message = "Refunded";
      background = "#2196f3";
      Icon = <SettingsBackupRestore style={{ color: "white" }} />;
    }

    return (
      <Chip
        label={message}
        avatar={<Avatar style={{ background: "none" }}>{Icon}</Avatar>}
        style={{
          color: "white",
          background,
        }}
      />
    );
  }
}

export default withStyles(styles)(TableStatus);
