export default (theme) => ({
  background: {
    background: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  addCard: {
    background: theme.palette.background.paper,
    minWidth: 400,
    padding: 10,
  },
  paper: {
    padding: 10,
    background: theme.palette.background.paper,
  },
  sticky: {
    padding: 10,
    background: theme.palette.background.paper,
    position: "sticky",
    top: 0,
  },
  cartContent: {
    padding: 10,
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  button: {
    margin: theme.spacing.unit,
  },
  stepper: {
    background: theme.palette.background.default,
  },
});
