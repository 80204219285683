import React, { Component } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Check";

// styles
import styles from "./styles";

class FormProfile extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    profile: PropTypes.object,

    createProfile: PropTypes.func,
    updateProfile: PropTypes.func,
    deleteProfile: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      tagLine: "",
      logo: "",
      coverImage: "",
      primaryColor: "",
      secondaryColor: "",
      secondaryTextColor: "",
      primaryTextColor: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        loading: false,
        ...nextProps.profile,
      });
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleColorChange = (name) => (color) => {
    this.setState({ [name]: color.hex });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this profile, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteProfile, profile, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteProfile(profile.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Profile has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateProfile,
      createProfile,
      profile,
      refresh,
      close,
    } = this.props;

    const {
      name,
      primaryColor,
      secondaryColor,
      loading,
      coverImage,
      secondaryTextColor,
      primaryTextColor,
      tagLine,
      logo,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (profile) {
      resp = await updateProfile(profile.id, {
        name,
        primaryColor,
        secondaryColor,
        secondaryTextColor,
        primaryTextColor,
        coverImage,
        tagLine,
        logo,
      });
    } else {
      resp = await createProfile({
        name,
        primaryColor,
        secondaryColor,
        secondaryTextColor,
        primaryTextColor,
        coverImage,
        tagLine,
        logo,
      });
    }
    refresh();
    close();
    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: profile
          ? "Profile has been updated."
          : "Profile has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, profile, close } = this.props;

    const {
      name,
      primaryColor,
      secondaryColor,
      loading,
      coverImage,
      tagLine,
      logo,
      secondaryTextColor,
      primaryTextColor,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {profile ? `${profile.name}` : "Let's Create a New Profile"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {profile
                  ? "To update the profile, fill the form below :"
                  : "To create a new profile, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="tagLine"
                    label="Tag Line"
                    className={classes.textField}
                    value={tagLine}
                    onChange={this.handleChange("tagLine")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="logo"
                    label="Logo"
                    className={classes.textField}
                    value={logo}
                    onChange={this.handleChange("logo")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="coverImage"
                    label="Cover Image"
                    className={classes.textField}
                    value={coverImage}
                    onChange={this.handleChange("coverImage")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" variant="body2">
                    Primary Color:
                  </Typography>
                  <SketchPicker
                    color={primaryColor || ""}
                    disableAlpha
                    onChange={this.handleColorChange("primaryColor")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" variant="body2">
                    Secondary Color:
                  </Typography>
                  <SketchPicker
                    color={secondaryColor || ""}
                    disableAlpha
                    onChange={this.handleColorChange("secondaryColor")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" variant="body2">
                    Primary Text Color:
                  </Typography>
                  <SketchPicker
                    color={primaryTextColor || ""}
                    disableAlpha
                    onChange={this.handleColorChange("primaryTextColor")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" variant="body2">
                    Secondary Text Color:
                  </Typography>
                  <SketchPicker
                    color={secondaryTextColor || ""}
                    disableAlpha
                    onChange={this.handleColorChange("secondaryTextColor")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {profile && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {profile ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormProfile));
