import { Hidden } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Launch from "@material-ui/icons/Launch";
import { withStyles } from "@material-ui/styles";
// custom
import Banners from "components/Banners";
// reactor
import Page from "components/Page";
import Shelves from "components/Shelves";
import { MARKET_META } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperMarketPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    market: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    goods: PropTypes.array,
    cart: PropTypes.array,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      history,
      loading,
      refresh,
      market,
      refreshKey,
      goods,
      updateCart,
      getCurrentCart,
      cart,
    } = this.props;

    const { index } = this.state;

    const aisle = market && market.aisles[index];

    return (
      <Page
        helmet="Market"
        loadingMessage={"Loading Market"}
        loading={loading}
        header={
          <Grid container justify="space-between" style={{ padding: 8 }}>
            {MARKET_META && MARKET_META.marketName ? (
              <Grid item>
                <Typography display="block" variant="h6">
                  {MARKET_META.marketName}
                </Typography>
              </Grid>
            ) : (
              []
            )}
            {MARKET_META &&
            MARKET_META.linkMapping &&
            MARKET_META.linkMapping[index] ? (
              <Grid item>
                <Chip
                  size="small"
                  deleteIcon={<Launch />}
                  label={`View ${
                    (market &&
                      market.aisles[index] &&
                      market.aisles[index].name) ||
                    "Products"
                  } on Website`}
                  onDelete={() =>
                    window.open(MARKET_META.linkMapping[index], "_blank")
                  }
                  onClick={() =>
                    window.location.replace(MARKET_META.linkMapping[index])
                  }
                />
              </Grid>
            ) : (
              []
            )}
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?id=${market.id}&index=${v}`);
            }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            {market &&
              market.aisles &&
              market.aisles.map((a) => (
                <Tab
                  label={
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <img
                          src={a.icon}
                          alt="category"
                          style={{ height: 20, width: 20 }}
                          className={classes.icon}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="block" variant="body2">
                          {a.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
          </Tabs>
        }
      >
        <div
          key={`${refreshKey}_${aisle ? aisle.id : 0}`}
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "calc(100% - 10px)",
            width: "calc(100% - 20px)",
            padding: "10px 10px 0px 10px",
          }}
        >
          {aisle ? (
            <Hidden xsDown>
              <Grid container>
                <Grid item xs={12}>
                  <Banners banners={aisle.banners} />
                </Grid>
              </Grid>
            </Hidden>
          ) : (
            []
          )}
          {aisle ? (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <Shelves
                  shelves={aisle.shelves}
                  refresh={refresh}
                  history={history}
                  aisleID={aisle.id}
                  goods={goods}
                  updateCart={updateCart}
                  getCurrentCart={getCurrentCart}
                  cart={cart}
                />
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMarketPage);
