import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperSchedulePage from "../component/WrapperSchedulePage";

const mapStateToProps = (state) => ({
  user: state.user,
  student: state.myStudent,
  timezones: state.timezones,
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypeById: (...args) => dispatch(Actions.getEventTypeById(...args)),
  getCoachById: (...args) => dispatch(Actions.getCoachById(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
  createEvent: (...args) => dispatch(Actions.createEvent(...args)),
  getCurrentStudent: (...args) => dispatch(Actions.getCurrentStudent(...args)),
  getTimezones: (...args) => dispatch(Actions.getTimezones(...args)),
  getDailyAvailabilities: (...args) =>
    dispatch(Actions.getDailyAvailabilities(...args)),
  getEventTypesPublicAvailabilities: (...args) =>
    dispatch(Actions.getEventTypesPublicAvailabilities(...args)),
});
class SchedulePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getEventTypeById: PropTypes.func,
    getCurrentStudent: PropTypes.func,
    createEvent: PropTypes.func,
    getAvailabilities: PropTypes.func,
    getEventTypesPublicAvailabilities: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    student: PropTypes.object,
    user: PropTypes.object,
    getTimezones: PropTypes.func,
    timezones: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, getCurrentStudent, getTimezones } = this.props;
    const urlParams = getJsonFromUrl(location);
    getCurrentStudent();
    getTimezones();
    this.state = {
      urlParams,
      eventTypeID: Number(urlParams.eventTypeID),
      coachID: Number(urlParams.coachID),
      teacherID: Number(urlParams.teacherID),
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { eventTypeID, coachID, teacherID } = this.state;
    const { getEventTypeById, getCoachById, getAvailabilities } = this.props;

    const resps = await Promise.all([
      getEventTypeById(eventTypeID),
      getCoachById(coachID),
      getAvailabilities(
        `[{"name":"eventTypeID","comparison":"eq","value":"${eventTypeID}"}, {"name":"coachID","comparison":"eq","value":"${coachID}"}]`
      ),
    ]);

    let resp;
    if (teacherID) {
      resp = await getCoachById(teacherID);
    }

    this.setState({
      eventType: resps[0].payload,
      coach: resps[1].payload,
      teacher: resp && resp.payload,
      availabilities: resps[2].payload,
      refreshKey: new Date().getTime(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      user,
      student,
      createEvent,
      getEventTypesPublicAvailabilities,
      getDailyAvailabilities,
      timezones,
    } = this.props;

    const {
      urlParams,
      eventType,
      coach,
      teacher,
      loading,
      availabilities,
      coachID,
      teacherID,
      refreshKey,
    } = this.state;

    return (
      <WrapperSchedulePage
        history={history}
        location={location}
        urlParams={urlParams}
        eventType={eventType}
        availabilities={
          availabilities &&
          availabilities.filter((a) => {
            if (teacher) {
              return a.coachID === coachID || a.coachID === teacherID;
            }
            return a.coachID === coachID;
          })
        }
        getDailyAvailabilities={getDailyAvailabilities}
        getEventTypesPublicAvailabilities={getEventTypesPublicAvailabilities}
        createEvent={createEvent}
        student={student}
        refreshKey={refreshKey}
        refresh={this.refresh.bind(this)}
        coach={coach}
        teacher={teacher}
        user={user}
        timezones={timezones}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);
