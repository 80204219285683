import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBackIos";
import Category from "@material-ui/icons/Category";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CategoryEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    category: PropTypes.object,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    refresh: PropTypes.func,
    back: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { category } = this.props;
    this.state = {
      ...category,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateCategory, refresh, category } = this.props;

    if (category[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateCategory(category.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCategory, refresh, category, back } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteCategory(category.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Category has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      back();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, category, back, disabled } = this.props;

    const {
      name,
      description,
      highQualifierName,
      lowQualifierName,
      lowExplanation,
      midExplanation,
      highExplanation,
      loading,
    } = this.state;

    return (
      <Grid container alignItems="center" className={classes.container}>
        <Grid item>
          <IconButton size="small" onClick={back}>
            <Back fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography display="block" variant="h6">
            {name.length > 20 ? `${name.substring(0, 20)}...` : name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Category />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Name"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("name");
            }}
            fullWidth
            value={name}
            disabled={disabled}
            onBlur={() => this.save("name")}
            onChange={this.handleChange("name")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("description");
            }}
            fullWidth
            multiline
            disabled={disabled}
            value={description}
            onBlur={() => this.save("description")}
            onChange={this.handleChange("description")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="+ High Qualifier"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("highQualifierName");
            }}
            fullWidth
            disabled={disabled}
            onBlur={() => this.save("highQualifierName")}
            value={highQualifierName}
            onChange={this.handleChange("highQualifierName")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="- Low Qualifier"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("lowQualifierName");
            }}
            disabled={disabled}
            fullWidth
            onBlur={() => this.save("lowQualifierName")}
            value={lowQualifierName}
            onChange={this.handleChange("lowQualifierName")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Low Explanation"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("lowExplanation");
            }}
            disabled={disabled}
            fullWidth
            onBlur={() => this.save("lowExplanation")}
            value={lowExplanation}
            onChange={this.handleChange("lowExplanation")}
            margin="normal"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Mid Explanation"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("midExplanation");
            }}
            disabled={disabled}
            fullWidth
            onBlur={() => this.save("midExplanation")}
            value={midExplanation}
            onChange={this.handleChange("midExplanation")}
            margin="normal"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="High Explanation"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("highExplanation");
            }}
            disabled={disabled}
            fullWidth
            onBlur={() => this.save("highExplanation")}
            value={highExplanation}
            onChange={this.handleChange("highExplanation")}
            margin="normal"
            multiline
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{
              background: "#f44336",
              color: "white",
            }}
            disabled={disabled}
            onClick={this.delete.bind(this)}
          >
            Delete Category
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CategoryEdit);
