import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FactorIcon from "@material-ui/icons/FindReplace";
import arrayMove from "array-move";
import CategoryEdit from "components/CategoryEdit";
import Factor from "components/Factor";
// custom
import FactorEdit from "components/FactorEdit";
import FormCategory from "components/FormCategory";
import FormFactor from "components/FormFactor";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// styles
import styles from "./styles";

class Factors extends Component {
  static propTypes = {
    classes: PropTypes.object,
    filterValue: PropTypes.object,
    assessment: PropTypes.object,
    createFactor: PropTypes.func,
    deleteFactor: PropTypes.func,
    updateFactor: PropTypes.func,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    select: PropTypes.bool,
    filter: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    this.state = {
      selectedCategory: undefined,
      selectedFactor: undefined,
      formFactorOpen: false,
      formCategoryOpen: false,
      reorder: false,
      ...assessment,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({ ...nextProps.assessment });
    }
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { updateFactor, refresh } = this.props;
    const factorToUpdate = this.state.factors[oldIndex];
    updateFactor(factorToUpdate.id, { order: newIndex }).then(refresh);
    this.setState(({ factors }) => ({
      factors: arrayMove(factors, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    console.log(e.target.tagName);
    if (e.target.tagName === "P") {
      return true; // Return true to cancel sorting
    }
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const {
      classes,
      assessment,
      createFactor,
      deleteFactor,
      updateFactor,
      createCategory,
      deleteCategory,
      updateCategory,
      refresh,
      refreshKey,
      select,
      filter,
      filterValue,
    } = this.props;

    const {
      selectedFactor,
      selectedCategory,
      formFactorOpen,
      formCategoryOpen,
      pendingFactor,
      reorder,
      factors,
    } = this.state;

    if (selectedFactor && !select) {
      return (
        <Grow in>
          <Grid container className={classes.container}>
            <Grid item>
              <FactorEdit
                refresh={refresh}
                disabled={assessment.locked}
                factor={selectedFactor}
                deleteFactor={deleteFactor}
                updateFactor={updateFactor}
                back={() => this.setState({ selectedFactor: undefined })}
              />
            </Grid>
          </Grid>
        </Grow>
      );
    }

    if (selectedCategory && !select) {
      return (
        <Grow in>
          <Grid container className={classes.container}>
            <Grid item>
              <CategoryEdit
                refresh={refresh}
                disabled={assessment.locked}
                category={selectedCategory}
                deleteCategory={deleteCategory}
                updateCategory={updateCategory}
                back={() => this.setState({ selectedCategory: undefined })}
              />
            </Grid>
          </Grid>
        </Grow>
      );
    }

    const SortableItem = SortableElement((g) => (
      <div style={{ zIndex: 99999 }}>
        <Factor
          filterValue={filterValue}
          refresh={refresh}
          filter={filter}
          factor={g.value}
          disabled={g.disabled || assessment.locked}
          select={select}
          reorder={reorder && !select}
          updateCategory={updateCategory}
          onCategorieselect={(c) => this.setState({ selectedCategory: c })}
          onFactorSelect={(f) => this.setState({ selectedFactor: f })}
          key={`factor_${g.value.id}_${refreshKey}`}
          onNewCategory={(f) =>
            this.setState({ formCategoryOpen: true, pendingFactor: f })
          }
        />
      </div>
    ));

    const SortableList = SortableContainer(({ items, disabled }) => (
      <div>
        {items &&
          items.map((value, index) => (
            <SortableItem
              key={value.id}
              index={index}
              value={value}
              disabled={!reorder || disabled}
            />
          ))}
      </div>
    ));

    return (
      <Grow in>
        <Grid container className={classes.container}>
          <Grid item style={{ padding: 20 }} xs={12}>
            <Typography
              display="block"
              variant="h6"
              style={{ fontWeight: 600, fontSize: 24 }}
            >
              Factors & Categories
            </Typography>
            {!select ? (
              <Chip
                label={reorder ? "Done Reordering" : "Reorder"}
                disabled={assessment.locked}
                color={reorder ? "primary" : undefined}
                onClick={() =>
                  this.setState((prevState) => ({
                    reorder: !prevState.reorder,
                  }))
                }
              />
            ) : (
              []
            )}
          </Grid>
          <Grid item xs={12}>
            {!select && !reorder ? (
              <Chip
                label="New Factor"
                disabled={assessment.locked}
                variant="outlined"
                style={{
                  margin: "6px 0px 6px 10px",
                  color: "#2196f3",
                  borderColor: "#2196f3",
                }}
                onClick={() => this.setState({ formFactorOpen: true })}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <FactorIcon style={{ color: "#2196f3" }} />
                  </Avatar>
                }
              />
            ) : (
              []
            )}
            <div style={{ position: "relative" }}>
              <MenuList style={{ width: "100%" }}>
                <SortableList
                  axis="y"
                  items={factors || []}
                  disabled={assessment.locked}
                  onSortEnd={this.onSortEnd}
                />
              </MenuList>
            </div>
          </Grid>
          <FormFactor
            open={formFactorOpen}
            close={() => {
              this.setState({
                formFactorOpen: false,
              });
            }}
            assessment={assessment}
            createFactor={createFactor}
            refresh={refresh}
          />
          <FormCategory
            open={formCategoryOpen}
            close={() => {
              this.setState({
                formCategoryOpen: false,
                pendingFactor: undefined,
              });
            }}
            createCategory={createCategory}
            factor={pendingFactor}
            refresh={refresh}
          />
        </Grid>
      </Grow>
    );
  }
}

export default withStyles(styles)(Factors);
