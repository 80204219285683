import Table from "@material-ui/core/Table";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class RendTable extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
  };

  constructor(...args) {
    super(...args);
    this.tableContainer = React.createRef();
    this.state = {
      init: false,
      shadow: false,
    };
  }

  componentDidMount() {
    const { init } = this.state;

    if (!init) {
      this.state.shadow =
        this.tableContainer.current.clientWidth <
        this.tableContainer.current.firstChild.clientWidth; //eslint-disable-line
      this.state.init = true; //eslint-disable-line
      this.forceUpdate();
    }
  }

  render() {
    const { children } = this.props;
    const { shadow } = this.state;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <div
          style={{ width: "100%", overflowX: "scroll" }}
          ref={this.tableContainer}
        >
          <Table size="small">{children}</Table>
        </div>
        {shadow ? (
          <div
            style={{
              background:
                "linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%)",
              position: "absolute",
              right: 0,
              height: "100%",
              width: 20,
              zIndex: 9999,
              top: 0,
            }}
          />
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(RendTable);
