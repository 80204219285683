import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const enrollments = `/${service}/enrollments`;

export function getEnrollments() {
  const url = `${enrollments}`;
  return {
    method: GET,
    url,
  };
}

export function getEnrollmentById(id) {
  const url = `${enrollments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getEnrollmentProgress(id) {
  const url = `${enrollments}/${id}/progress`;
  return {
    method: GET,
    url,
  };
}

export function setEnrollmentProgress(id, body) {
  const url = `${enrollments}/${id}/lectureProgress`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateEnrollment(id, body) {
  const url = `${enrollments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEnrollment(id) {
  const url = `${enrollments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEnrollment(body) {
  const url = `${enrollments}`;
  return {
    method: POST,
    url,
    body,
  };
}
