import { IconButton, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Back from "@material-ui/icons/ArrowBack";
import Help from "@material-ui/icons/Help";
import AssessmentBuilder from "components/AssessmentBuilder";
import AssessmentInfo from "components/AssessmentInfo";
import AssessmentMarketing from "components/AssessmentMarketing";
import FormQuestion from "components/FormQuestion";
// reactor
import Page from "components/Page";
import PreviewProfile from "components/PreviewProfile";
import SurveysList from "components/SurveysList";
import { REACT_APP_STUDENT } from "config";
// constants
import { ASSESSMENTS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAssessmentPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    assessment: PropTypes.object,
    assessments: PropTypes.array,
    updateAssessment: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    createQuestion: PropTypes.func,
    updateInquiry: PropTypes.func,
    deleteAssessment: PropTypes.func,
    deleteQuestion: PropTypes.func,
    updateQuestion: PropTypes.func,
    getSurveys: PropTypes.func,
    createFactor: PropTypes.func,
    deleteFactor: PropTypes.func,
    updateFactor: PropTypes.func,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    getAssessmentById: PropTypes.func,
    profiles: PropTypes.array,
    getProfiles: PropTypes.func,
    updateProfile: PropTypes.func,
    createProfile: PropTypes.func,
    deleteProfile: PropTypes.func,
    createAssessmentInquiry: PropTypes.func,
    deleteAssessmentInquiry: PropTypes.func,
    surveys: PropTypes.array,
    app: PropTypes.object,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formQuestionOpen: false,
    };
  }

  getStepContent() {
    const {
      assessment,
      updateAssessment,
      deleteAssessment,
      updateInquiry,
      createQuestion,
      deleteQuestion,
      updateQuestion,
      createFactor,
      deleteFactor,
      updateFactor,
      createCategory,
      deleteCategory,
      updateCategory,
      refreshKey,
      refresh,
      assessments,
      surveys,
      getSurveys,
      history,
      getAssessmentById,
      profiles,
      getProfiles,
      updateProfile,
      createProfile,
      deleteProfile,
      app,
      user,
      searchUsers,
      deleteAssessmentInquiry,
      createAssessmentInquiry,
      inquiries,
    } = this.props;
    const { index } = this.state;

    switch (index) {
      case 1:
        return (
          <AssessmentInfo
            app={app}
            refreshKey={refreshKey}
            refresh={refresh}
            assessment={assessment}
            history={history}
            updateAssessment={updateAssessment}
            deleteAssessment={deleteAssessment}
            getAssessmentById={getAssessmentById}
            updateInquiry={updateInquiry}
          />
        );
      case 0:
        return (
          <AssessmentMarketing
            app={app}
            refreshKey={refreshKey}
            refresh={refresh}
            assessment={assessment}
            history={history}
            updateAssessment={updateAssessment}
            deleteAssessment={deleteAssessment}
            getAssessmentById={getAssessmentById}
            updateInquiry={updateInquiry}
            createAssessmentInquiry={createAssessmentInquiry}
            deleteAssessmentInquiry={deleteAssessmentInquiry}
            inquiries={inquiries}
          />
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Chip
                label="New Question"
                variant="outlined"
                style={{
                  margin: "6px 0px 6px 10px",
                  color: "#2196f3",
                  borderColor: "#2196f3",
                }}
                disabled={assessment?.locked}
                onClick={() => this.setState({ formQuestionOpen: true })}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Help style={{ color: "#2196f3" }} />
                  </Avatar>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <AssessmentBuilder
                refreshKey={refreshKey}
                refresh={refresh}
                assessment={assessment}
                createQuestion={createQuestion}
                deleteQuestion={deleteQuestion}
                updateQuestion={updateQuestion}
                createFactor={createFactor}
                deleteFactor={deleteFactor}
                updateFactor={updateFactor}
                createCategory={createCategory}
                deleteCategory={deleteCategory}
                updateCategory={updateCategory}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <PreviewProfile
            assessment={assessment}
            profiles={profiles}
            getProfiles={getProfiles}
            updateProfile={updateProfile}
            createProfile={createProfile}
            deleteProfile={deleteProfile}
            updateAssessment={updateAssessment}
            refresh={refresh}
          />
        );
      case 4:
        return (
          <SurveysList
            user={user}
            searchUsers={searchUsers}
            history={history}
            getSurveys={getSurveys}
            surveys={surveys}
            assessments={assessments}
            onSelect={
              (selectedSurvey) => console.log("Test")
              // window.open(
              //   `${REACT_APP_STUDENT}/survey?id=${selectedSurvey.id}`,
              //   "_blank"
              // )
            }
          />
        );
      default:
        return (
          <Typography display="block" variant="h6">
            Coming Soon
          </Typography>
        );
    }
  }

  getFab() {
    const { index } = this.state;
    switch (index) {
      case 1:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formQuestionOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      default:
        break;
    }
  }

  handleLockChange() {
    const { updateAssessment, assessment, refresh } = this.props;
    updateAssessment(assessment.id, { locked: !assessment.locked }).then(
      refresh
    );
  }

  render() {
    const {
      classes,
      history,
      assessment,
      createQuestion,
      loading,
      refresh,
    } = this.props;

    const { index, formQuestionOpen } = this.state;

    return (
      <Page
        helmet="Assessment"
        loadingMessage={"Loading Assessment"}
        loading={loading}
        noPadding={index === 3}
        header={
          <Grid
            container
            style={{ padding: "10px 10px 0px 10px" }}
            justify="space-between"
          >
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"All Assessments"}
                  className={classes.backChip}
                  onClick={() => history.push(ASSESSMENTS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={assessment ? assessment.name : "..."}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Help className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
            {assessment?.locked !== undefined ? (
              <Grid item>
                <Tooltip
                  title={`Click to ${assessment?.locked ? "Unlock" : "Lock"}`}
                >
                  <IconButton onClick={this.handleLockChange.bind(this)}>
                    {!assessment?.locked ? (
                      <span className="fad fa-lock-open-alt" />
                    ) : (
                      <span className="fad fa-lock-alt" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?id=${assessment.id}&index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="General"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Description Texts"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              style={{ display: "none" }}
              label="Builder"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Preview PDF"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Profiles"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormQuestion
          open={formQuestionOpen}
          close={() => {
            this.setState({
              formQuestionOpen: false,
            });
          }}
          createQuestion={createQuestion}
          assessment={assessment}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperAssessmentPage);
