import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeOff from "@material-ui/icons/VolumeOff";
import Button from "@material-ui/core/Button";
import Speech from "speak-tts";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class Speak extends Component {
  static propTypes = {
    classes: PropTypes.object,
    text: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    this.speech = new Speech();
    this.state = {
      firstSpeak: false,
      init: false,
      mute: true,
    };
    this.speech
      .init({
        volume: 0.3,
        lang: "en-US",
        rate: 1,
        pitch: 1,
        listeners: {
          onvoiceschanged: (voices) => {
            console.log("Voices changed", voices);
          },
        },
      })
      .then((data) => {
        console.log("Speech is ready", data);
        this.setState({ init: true });
      })
      .catch((e) => {
        console.error("An error occured while initializing : ", e);
      });
  }

  componentWillReceiveProps(nextProps) {
    const { firstSpeak, init } = this.state;
    if (init && (nextProps.text !== this.props.text || !firstSpeak)) {
      this.setState({ firstSpeak: true });
      this.speak(nextProps.text);
    }
  }

  speak(text) {
    const { mute } = this.state;
    if (!mute) {
      this.speech.speak({
        text,
        queue: false,
        listeners: {
          onstart: () => {
            console.log("Start utterance");
          },
          onend: () => {
            console.log("End utterance");
          },
          onresume: () => {
            console.log("Resume utterance");
          },
          onboundary: (event) => {
            console.log(
              event.name +
                " boundary reached after " +
                event.elapsedTime +
                " milliseconds."
            );
          },
        },
      });
    }
  }

  render() {
    const { text } = this.props;
    const { mute } = this.state;

    return (
      <div style={{ color: "white" }}>
        <Button
          color="inherit"
          onClick={() => {
            if (mute) {
              this.setState({ mute: false }, () => this.speak(text));
            } else {
              this.speech.cancel();
              this.setState({ mute: true });
            }
          }}
        >
          {mute ? (
            <VolumeUp color="inherit" fontSize="small" />
          ) : (
            <VolumeOff color="inherit" />
          )}
          {mute ? (
            <Typography display="block" color="inherit">
              Click to Speak
            </Typography>
          ) : (
            <Typography display="block" color="inherit">
              Click to Mute
            </Typography>
          )}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(Speak);
