import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withStyles } from "@material-ui/styles";
// constants
import { PLACEHOLDER } from "constants/app";
import { CART } from "constants/routes";
import { formattedPrice } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Good extends Component {
  static propTypes = {
    good: PropTypes.object,
    cart: PropTypes.array,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.good = React.createRef();
    this.copyContainer = React.createRef();
    this.state = {
      copy: [],
    };
  }

  async addToCart() {
    // this.animate();
    const { updateCart, cart, good, getCurrentCart, history } = this.props;

    const { goods } = cart;
    goods.push({ id: good.id, quantity: 1 });
    await updateCart(cart.id, cart);
    await getCurrentCart();
    history.push(CART);
  }

  // animate() {
  //   const copy = this.good.current.cloneNode(true);
  //   const good = this.good.current.getBoundingClientRect();
  //   const width = window.innerWidth;
  //   const goodX = good.x;
  //   const goodY = good.y;
  //   copy.style.position = 'fixed';
  //   copy.style.transition = 'top 1s, right 1s, opacity 1s';
  //   copy.style.top = `${goodY}px`;
  //   copy.style.right = `${width - goodX - good.width}px`;
  //   copy.style.opacity = '0.8';
  //   copy.style.width = `${this.good.current.clientWidth}px`;
  //   setTimeout(() => {
  //     copy.style.top = '-50px';
  //     copy.style.right = '-100px';
  //     copy.style.opacity = '0';
  //   }, 10);
  //   this.copyContainer.current.appendChild(copy);
  // }

  render() {
    const { good, classes } = this.props;

    return (
      <div>
        <div ref={this.copyContainer} />
        <div ref={this.good} className={classes.transition}>
          <Grid container spacing={1}>
            <Grid item style={{ width: 92 }}>
              <img
                style={{ width: 75 }}
                alt="assessment"
                className={classes.darkMode}
                src={
                  good.pictures && good.pictures.length
                    ? good.pictures[0].url
                    : PLACEHOLDER
                }
              />
            </Grid>
            <Grid
              item
              style={{
                width: "calc(100% - 108px)",
              }}
            >
              <Typography display="block" variant="body2">
                {good.title}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {good.description && good.description.length > 100
                  ? `${good.description.substring(0, 100)}...`
                  : good.description}
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  {
                    good.externalURL
                      ? (
                        <Chip
                    label={good.buttonLabel || `${
                      good.pricing.price > 0
                        ? formattedPrice(good.pricing.price)
                        : ""
                    } Buy Now`}
                    size="small"
                    style={{ background: '#ff9800', color: 'white'}}
                    icon={<OpenInNewIcon style={{ color: 'white'}} />}
                    onClick={() => window.open(good.externalURL, "_blank")}
                  />
                      ) : (
                        <Chip
                    label={good.buttonLabel || `${
                      good.pricing.price > 0
                        ? formattedPrice(good.pricing.price)
                        : ""
                    } Buy Now`}
                    onClick={() => this.addToCart()}
                  />
                      )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Good);
