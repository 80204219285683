import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Class from "@material-ui/icons/ClassTwoTone";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Store from "@material-ui/icons/StoreTwoTone";

// custom
import EnrollmentCard from "components/EnrollmentCard";

// constants
import { MARKET } from "constants/routes";

// styles
import styles from "./styles";

class EnrollmentsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    enrollments: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedEnrollment) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedEnrollment);
    }
  }

  render() {
    const { enrollments, history, classes } = this.props;

    if (enrollments.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Grid item>
            <Class style={{ fontSize: 70 }} className={classes.darkModeFix} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No Courses.
            </Typography>
            <Typography display="block" variant="body" color="secondary">
              Visit the Market to Purchase Courses.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: 20,
              }}
              onClick={() => history.push(`${MARKET}?index=1`)}
            >
              <Store style={{ paddingRight: 10 }} /> Market
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <Grid container spacing={2} alignItems="stretch">
          {enrollments &&
            enrollments.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <EnrollmentCard key={e.id} enrollment={e} history={history} />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EnrollmentsList);
