export default (theme) => ({
  container: {
    height: "calc(100% - 20px)",
    position: "relative",
    padding: 10,
  },
  back: {
    color: "rgba(127,127,127,0.5)",
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  circularContainer: {
    position: "relative",
  },
  value: {
    position: "absolute",
    top: 10,
    textAlign: "center",
    width: "100%",
  },
});
