import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperCheckoutPage from "../component/WrapperCheckoutPage";

const mapStateToProps = (state) => ({
  cart: state.cart,
  cards: state.cards,
  app: state.app,
  customer: state.customers,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateCartGood: (...args) => dispatch(Actions.updateCartGood(...args)),
  deleteCartGood: (...args) => dispatch(Actions.deleteCartGood(...args)),
  getCurrentCart: (...args) => dispatch(Actions.getCurrentCart(...args)),
  updateCart: (...args) => dispatch(Actions.updateCart(...args)),
  updateCard: (...args) => dispatch(Actions.updateCard(...args)),
  deleteCard: (...args) => dispatch(Actions.deleteCard(...args)),
  createCard: (...args) => dispatch(Actions.createCard(...args)),
  deleteCartCoupon: (...args) => dispatch(Actions.deleteCartCoupon(...args)),
  getBazaarCurrentCustomer: (...args) =>
    dispatch(Actions.getBazaarCurrentCustomer(...args)),
  setCardAsFavorite: (...args) => dispatch(Actions.setCardAsFavorite(...args)),
  getCards: (...args) => dispatch(Actions.getCards(...args)),
  payCart: (...args) => dispatch(Actions.payCart(...args)),
});
class CheckoutPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    updateCartGood: PropTypes.func,
    deleteCartGood: PropTypes.func,
    getCurrentCart: PropTypes.func,
    updateCart: PropTypes.func,
    getBazaarCurrentCustomer: PropTypes.func,
    deleteCard: PropTypes.func,
    createCard: PropTypes.func,
    updateCard: PropTypes.func,
    deleteCartCoupon: PropTypes.func,
    setCardAsFavorite: PropTypes.func,
    payCart: PropTypes.func,
    cart: PropTypes.object,
    cards: PropTypes.object,
    app: PropTypes.object,
    customer: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
    this.refresh();
  }

  async refresh() {
    const { getCurrentCart, getCards, getBazaarCurrentCustomer } = this.props;
    await Promise.all([
      getCurrentCart(),
      getCards(),
      getBazaarCurrentCustomer(),
    ]);
    this.setState({
      loading: false,
      refreshKey: Date.now(),
    });
  }

  render() {
    const {
      location,
      history,
      updateCartGood,
      deleteCartGood,
      cart,
      updateCart,
      cards,
      updateCard,
      deleteCard,
      createCard,
      setCardAsFavorite,
      payCart,
      deleteCartCoupon,
      app,
      customer,
      user,
    } = this.props;

    const { urlParams, refreshKey, loading } = this.state;

    return (
      <WrapperCheckoutPage
        history={history}
        customer={customer}
        location={location}
        urlParams={urlParams}
        updateCartGood={updateCartGood}
        deleteCartGood={deleteCartGood}
        updateCart={updateCart}
        updateCard={updateCard}
        deleteCard={deleteCard}
        createCard={createCard}
        setCardAsFavorite={setCardAsFavorite}
        payCart={payCart}
        deleteCartCoupon={deleteCartCoupon}
        refreshKey={refreshKey}
        loading={loading}
        app={app}
        refresh={this.refresh.bind(this)}
        cart={cart}
        cards={cards}
        user={user}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
