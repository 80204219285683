import { networkAction } from "helpers/network/networkAction";

import {
  GET_TEAMS,
  GET_TEAM_BY_ID,
  DELETE_TEAM,
  UPDATE_TEAM,
  CREATE_TEAM,
  GET_TEAM_USERS,
} from "constants/teams";

import * as Api from "api";

export const getTeams = () => async (dispatch) =>
  networkAction(dispatch, GET_TEAMS, Api.getTeams, []);

export const getTeamUsers = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TEAM_USERS, Api.getTeamUsers, [id]);

export const getTeamById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TEAM_BY_ID, Api.getTeamById, [id]);

export const deleteTeam = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TEAM, Api.deleteTeam, [id]);

export const createTeam = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TEAM, Api.createTeam, [body]);

export const updateTeam = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TEAM, Api.updateTeam, [id, body]);
