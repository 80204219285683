import { Chip, CircularProgress, List } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
// import AudioPlayer from "react-h5-audio-player";
// material-ui
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CurriculumItem } from "components";
import React from "react";

export default function ResponsiveDialog({
  enrollment,
  classes,
  progress,
  history,
}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getContent() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <List dense component="nav">
              {enrollment &&
                enrollment.modules &&
                enrollment.modules.map((m) => (
                  <CurriculumItem
                    module={m}
                    key={`module_${m.id}`}
                    moduleID={
                      enrollment.module
                        ? enrollment.module.id
                        : enrollment.moduleID
                    }
                    lectureID={
                      enrollment.lecture
                        ? enrollment.lecture.id
                        : enrollment.lectureID
                    }
                    enrollmentID={enrollment.id}
                    progress={progress}
                    history={history}
                    handleClose={handleClose}
                    width={"100%"}
                  />
                ))}
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <Chip
        size="small"
        onClick={handleClickOpen}
        label={
          <span>
            <i class="fad fa-bars" style={{ paddingRight: 8 }}></i>Table of
            Content
          </span>
        }
        style={{ position: "absolute", top: 10, left: 10, background: "white" }}
      />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              width: "100%",
              height: 150,
              backgroundSize: "cover",
              backgroundImage: `url(${enrollment.courseBackground})`,
            }}
          />
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.sticky}
          >
            <Grid item>
              <Typography display="block" variant="h6">
                {enrollment.courseName}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Your Global Progression:
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.circularContainer}>
                <CircularProgress
                  className={classes.back}
                  variant="static"
                  value={100}
                />
                <CircularProgress
                  className={classes.fore}
                  variant="static"
                  value={enrollment.percentage}
                />
                <Typography
                  display="block"
                  color="primary"
                  className={classes.value}
                >
                  {enrollment.percentage}
                  <span style={{ fontSize: 10 }}>%</span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{getContent()}</DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            fullWidth
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
