import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withCookies } from "react-cookie";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// config
import { MARKET_ID } from "config";

// helpers
import { getCookie, setCookie } from "helpers/cookie";

// component
import WrapperMarketPage from "../component/WrapperMarketPage";

const mapStateToProps = (state) => ({
  goods: state.goods,
  cart: state.cart,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getMarketById: (...args) => dispatch(Actions.getMarketById(...args)),
  updateCart: (...args) => dispatch(Actions.updateCart(...args)),
  getCurrentCart: (...args) => dispatch(Actions.getCurrentCart(...args)),
  validateCart: (...args) => dispatch(Actions.validateCart(...args)),
});
class MarketPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    cookies: PropTypes.object,
    location: PropTypes.object,
    getMarketById: PropTypes.func,
    validateCart: PropTypes.func,
    goods: PropTypes.array,
    user: PropTypes.object,
    cart: PropTypes.array,
    updateCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, user } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
    };
    if (user && user.uuid === undefined && getCookie("cart") === "") {
      this.initCookieCart();
    }
    this.getCurrentCart()();
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    //eslint-disable-line
    const { getMarketById } = this.props;

    const resp = await getMarketById(MARKET_ID);
    if (resp.success) {
      const market = resp.payload;
      market.aisles.forEach((a) =>
        a.shelves.forEach((s) => {
          s.loading = true;
        })
      );
      this.setState({
        market,
        loading: false,
        refreshKey: new Date().getTime(),
      });
    }
  }

  initCookieCart() {
    const { cookies } = this.props;
    setCookie(cookies, "cart", JSON.stringify({ goods: [], coupons: [] }));
  }

  getCurrentCart() {
    const { user, getCurrentCart, validateCart } = this.props;
    if (user && user.uuid) {
      return getCurrentCart;
    }

    return () => {
      let cart;
      try {
        cart = JSON.parse(getCookie("cart"));
      } catch (error) {
        this.initCookieCart();
        cart = JSON.parse(getCookie("cart"));
      }
      return validateCart(cart);
    };
  }

  updateCart() {
    const { user, updateCart, cookies } = this.props;
    if (user && user.uuid) return updateCart;

    return (cartID, cart) => {
      const reducedCart = {
        goods: [],
        coupons: cart.coupons,
      };

      cart.goods.forEach((g) =>
        reducedCart.goods.push({
          pricingID: g.pricingID,
          id: g.id,
          quantity: g.quantity,
        })
      );

      setCookie(cookies, "cart", JSON.stringify(reducedCart));
    };
  }

  render() {
    const { location, history, goods, cart, user } = this.props;

    const { urlParams, loading, market, refreshKey } = this.state;

    console.log("-----------");
    console.log(cart);

    return (
      <WrapperMarketPage
        history={history}
        location={location}
        urlParams={urlParams}
        market={market}
        loading={loading}
        refreshKey={refreshKey}
        goods={goods}
        updateCart={this.updateCart()}
        getCurrentCart={this.getCurrentCart()}
        cart={cart}
        refresh={this.refresh.bind(this)}
        anonymous={user.uuid === undefined}
      />
    );
  }
}
export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(MarketPage)
);
