export default (theme) => ({
  box: {
    border: "solid 1px #E1E1E1",
    width: "calc(100% - 8px)",
    marginTop: -1,
    padding: 4,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    height: "calc(100% - 9px)",
  },
  flex: {
    width: `${100 / 7}%`,
    justifyContent: "stretch",
  },
  hours: {
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 10,
    fontSize: 10,
    background: "rgba(155,155,155,0.1)",
    padding: "0px 2px",
    textAlign: "center",
    marginBottom: 4,
  },
});
