export default (theme) => ({
  container: {
    padding: 5,
  },
  bottomMessage: {
    padding: 20,
    background: "#2196f3",
    color: "white",
  },
});
