import Fab from "@material-ui/core/Fab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
import FormTeam from "components/FormTeam";
// reactor
import Page from "components/Page";
// custom
import TeamsList from "components/TeamsList";
// routes
import { TEAM } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperTeamPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    teams: PropTypes.array,
    createTeam: PropTypes.func,
    deleteTeam: PropTypes.func,
    updateTeam: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedTeam: undefined,
      formTeamOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { teams, history } = this.props;

    switch (index) {
      case 0:
        return (
          <TeamsList
            teams={teams}
            onSelect={(selectedTeam) =>
              history.push(`${TEAM}?id=${selectedTeam.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createTeam,
      deleteTeam,
      updateTeam,
      refresh,
    } = this.props;

    const { index, selectedTeam, formTeamOpen } = this.state;

    return (
      <Page
        helmet="Teams"
        loading={loading}
        loadingMessage={"Fetching all Teams"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div>
          <Fab
            color="primary"
            size="small"
            variant="extended"
            aria-label="Add"
            style={{ marginBottom: 10, textTransform: "none" }}
            onClick={() => this.setState({ formTeamOpen: true })}
          >
            <AddIcon fontSize="small" /> Team
          </Fab>
        </div>
        {this.getStepContent()}
        <FormTeam
          open={formTeamOpen}
          team={selectedTeam}
          close={() => {
            this.setState({
              formTeamOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedTeam: undefined,
                }),
              200
            );
          }}
          createTeam={createTeam}
          deleteTeam={deleteTeam}
          updateTeam={updateTeam}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTeamPage);
